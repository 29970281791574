// i18nOwl-ignore [app-cohort-form.description]
import type {App, TriggerIntegration} from '@cohort/merchants/apps';
import translationEn from '@cohort/merchants/apps/cohort-form/locales/en.json';
import translationFr from '@cohort/merchants/apps/cohort-form/locales/fr.json';
import {CohortFormPerkIntegration} from '@cohort/merchants/apps/cohort-form/perkIntegrations/fillForm/PerkIntegration';
import {CohortFormCheckboxTriggerIntegration} from '@cohort/merchants/apps/cohort-form/triggerIntegrations/checkbox/TriggerIntegration';
import {CohortFormDateTriggerIntegration} from '@cohort/merchants/apps/cohort-form/triggerIntegrations/date/TriggerIntegration';
import {CohortFormEmailTriggerIntegration} from '@cohort/merchants/apps/cohort-form/triggerIntegrations/email/TriggerIntegration';
import {CohortFillFormTriggerIntegration} from '@cohort/merchants/apps/cohort-form/triggerIntegrations/fillForm/TriggerIntegration';
import {CohortFormLongTextTriggerIntegration} from '@cohort/merchants/apps/cohort-form/triggerIntegrations/longText/TriggerIntegration';
import {CohortFormMediaTriggerIntegration} from '@cohort/merchants/apps/cohort-form/triggerIntegrations/media/TriggerIntegration';
import {CohortFormNumberTriggerIntegration} from '@cohort/merchants/apps/cohort-form/triggerIntegrations/number/TriggerIntegration';
import {CohortFormPictureChoiceTriggerIntegration} from '@cohort/merchants/apps/cohort-form/triggerIntegrations/pictureChoice/TriggerIntegration';
import {CohortFormScoreTriggerIntegration} from '@cohort/merchants/apps/cohort-form/triggerIntegrations/score/TriggerIntegration';
import {CohortFormSelectTriggerIntegration} from '@cohort/merchants/apps/cohort-form/triggerIntegrations/select/TriggerIntegration';
import {CohortSwipeCardsTriggerIntegration} from '@cohort/merchants/apps/cohort-form/triggerIntegrations/swipeCards/TriggerIntegration';
import {CohortFormTextTriggerIntegration} from '@cohort/merchants/apps/cohort-form/triggerIntegrations/text/TriggerIntegration';
import type {CohortFormAppStruct} from '@cohort/shared/apps/cohort-form';
import {CohortFormAppSpec} from '@cohort/shared/apps/cohort-form';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';

const CohortFormApp: App<CohortFormAppStruct> = {
  spec: CohortFormAppSpec,
  ConnectionEditComponent: null,
  locales: {
    en: translationEn,
    fr: translationFr,
  },
  logo: <SvgAppIcon name="cohort-form" height={40} width={40} />,
  medias: [],
  notificationIntegrations: [],
  perkIntegrations: [CohortFormPerkIntegration],
  triggerIntegrations: [
    CohortFillFormTriggerIntegration,
    CohortFormCheckboxTriggerIntegration,
    CohortFormDateTriggerIntegration,
    CohortFormEmailTriggerIntegration,
    CohortFormLongTextTriggerIntegration,
    CohortFormMediaTriggerIntegration,
    CohortFormNumberTriggerIntegration,
    CohortFormPictureChoiceTriggerIntegration,
    CohortFormScoreTriggerIntegration,
    CohortFormSelectTriggerIntegration,
    CohortFormTextTriggerIntegration,
    CohortSwipeCardsTriggerIntegration,
  ] as Array<TriggerIntegration>,
  userEvents: [],
};

export default CohortFormApp;
