import Button from '@cohort/merchants/components/buttons/Button';
import type {FieldWrapperProps, WrappedField} from '@cohort/merchants/components/form/FieldWrapper';
import FieldWrapper from '@cohort/merchants/components/form/FieldWrapper';
import useFileInputContext from '@cohort/merchants/components/form/input/fileInput/hooks/useFileInputContext';
import {isValidFile} from '@cohort/merchants/components/form/input/fileInput/utils';
import {SlideOverCropImage} from '@cohort/merchants/components/form/SlideOverCropImage';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {FormField} from '@cohort/merchants/lib/form/utils';
import {isThumbnailUrl} from '@cohort/merchants/lib/form/utils';
import {bytesToKb} from '@cohort/merchants/lib/Utils';
import type {AdminAssetKind} from '@cohort/shared/schema/common/assets';
import {AllowedAssetMimeTypes, ImageMimeTypeSchema} from '@cohort/shared/schema/common/assets';
import type {AssetMinDimension, SizedAssetKind} from '@cohort/shared/utils/fileUploads';
import {ASSETS_MIN_DIMENSIONS} from '@cohort/shared/utils/fileUploads';
import {getImageUrl, getVideoUrl, isRoundedVisual, Sizes} from '@cohort/shared/utils/media';
import {isImageFile} from '@cohort/shared/utils/mimeTypes';
import VideoPlayer from '@cohort/shared-frontend/components/VideoPlayer';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {getFileType, getFileTypeFromMimeType} from '@cohort/shared-frontend/utils/fileUploads';
import {isEmptyFileList, isFile, isFileList} from '@cohort/shared-frontend/utils/isFile';
import {FilePdf} from '@phosphor-icons/react';
import {
  ArrowsClockwise,
  CheckCircle,
  Image,
  Trash,
  UploadSimple,
  VideoCamera,
} from '@phosphor-icons/react';
import React, {createContext, Fragment, useMemo, useState} from 'react';
import type {FieldValues} from 'react-hook-form';
import {useController, useWatch} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {isDefined, isString} from 'remeda';
import {match} from 'ts-pattern';

type ThumbnailOptions = {
  name: string;
  assetKind: AdminAssetKind;
};

export type VideoPreviewSize = 'xs' | 'sm' | 'default';

type VideoFileConfig = {
  thumbnailOptions?: ThumbnailOptions;
  // Control the width of the preview for video files.
  previewSize?: VideoPreviewSize;
};

type BaseFileInputContextData = {
  acceptHint?: string;
  assetKind: AdminAssetKind;
  disabled?: boolean;
  videoFileConfig?: VideoFileConfig;
};

export type FileInputContextType<T extends FieldValues = FieldValues> = FormField<T> &
  WrappedField &
  BaseFileInputContextData & {
    hasCroppedImage: boolean;
    minFileDimensions?: AssetMinDimension;
    setHasCroppedImage: (hasCroppedImage: boolean) => void;
  };

export const FileInputContext = createContext<FileInputContextType | undefined>(undefined);

type FileInputProps<T extends FieldValues> = FormField<T> &
  BaseFileInputContextData & {
    children: React.ReactNode;
    fieldWrapper?: Omit<FieldWrapperProps, 'children' | 'name'>;
  };

const FileInput = <T extends FieldValues>(props: FileInputProps<T>): JSX.Element => {
  const [hasCroppedImage, setHasCroppedImage] = useState(false);
  const {
    acceptHint,
    assetKind,
    control,
    children,
    disabled,
    name,
    register,
    videoFileConfig,
    fieldWrapper,
  } = props;

  const minFileDimensions = Object.keys(ASSETS_MIN_DIMENSIONS).includes(assetKind)
    ? ASSETS_MIN_DIMENSIONS[assetKind as SizedAssetKind]
    : undefined;
  const {fieldState} = useController({
    control,
    name,
  });

  const contextValue: FileInputContextType<T> = {
    acceptHint,
    assetKind,
    control,
    disabled,
    hasCroppedImage,
    minFileDimensions,
    name,
    register,
    setHasCroppedImage,
    videoFileConfig,
  };

  const fieldWrapperProps: FieldWrapperProps | undefined = fieldWrapper
    ? {
        children,
        className: fieldWrapper.className,
        description: fieldWrapper.description,
        error: fieldState.error?.message,
        label: fieldWrapper.label,
        labelPosition: fieldWrapper.labelPosition,
        name,
        optional: fieldWrapper.optional,
      }
    : undefined;

  return (
    <FileInputContext.Provider value={contextValue as FileInputContextType<FieldValues>}>
      {fieldWrapperProps ? <FieldWrapper {...fieldWrapperProps} /> : children}
    </FileInputContext.Provider>
  );
};

const FileInputPreviewFileData: React.FC<{children: React.ReactNode}> = ({children}) => (
  <div className="flex justify-center gap-x-4">{children}</div>
);

export type AspectRatio = 'aspect-video' | 'aspect-auto';

/**
 * Handles the file input and preview logic, displaying a button if no file is selected.
 */
const FileInputUploader: React.FC<{aspectRatio?: AspectRatio}> = ({aspectRatio}) => {
  const {assetKind, control, name} = useFileInputContext();
  const fieldValue = useWatch({
    control,
    name,
  });

  if (!isDefined(fieldValue) || isEmptyFileList(fieldValue)) {
    return <FileInputUploadButton />;
  }

  const fileType = getFileType(fieldValue);

  return match(fileType)
    .with('document', () => (
      <FileInputPreview>
        <FileInputPreviewFileData>
          <FileInputPreviewDocument />
          <FileInputPreviewFileMetadata />
          <FileInputPreviewUploadedFileMessage />
        </FileInputPreviewFileData>
        <FileInputDeleteButton />
      </FileInputPreview>
    ))
    .with('image', () => (
      <FileInputPreview>
        <FileInputPreviewFileData>
          <FileInputPreviewImage />
          <FileInputPreviewFileMetadata />
          <FileInputPreviewUploadedFileMessage />
        </FileInputPreviewFileData>
        <FileInputDeleteButton />
      </FileInputPreview>
    ))
    .with('video', () => (
      <FileInputPreview>
        <FileInputVideoPreview
          assetKind={assetKind}
          fileInputValue={fieldValue}
          aspectRatio={aspectRatio}
        />
      </FileInputPreview>
    ))
    .with(undefined, () => null)
    .exhaustive();
};

type FileInputUploadProps = {
  assetKind: AdminAssetKind;
  name: string;
  accept: string;
};

/**
 * Input element for uploading files.
 */
const FileInputUpload: React.FC<FileInputUploadProps> = ({name, assetKind, accept}) => {
  const {control, disabled, minFileDimensions, register, rules} = useFileInputContext();
  const {t} = useTranslation('components', {keyPrefix: 'form.input.fileInput'});
  const {field} = useController({control, name});

  // i18nOwl-ignore [fileInputThumbnailUploadButton.errorFileTooLarge, fileInputThumbnailUploadButton.errorImageTooSmall]
  // i18nOwl-ignore [fileInputThumbnailUploadButton.errorInvalidFileType, fileInputThumbnailUploadButton.errorVideoNotSquared]
  return (
    <input
      key={name}
      className={cn(
        'absolute left-0 top-0 h-full w-full opacity-0 ring-opacity-100',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer'
      )}
      data-testid={name}
      {...register(name, rules)}
      accept={accept}
      type="file"
      onChange={async e => {
        e.stopPropagation();
        if (e.target.files && e.target.files.length > 0) {
          const file = e.target.files[0] as File;

          if (await isValidFile(assetKind, file, minFileDimensions, t)) {
            field.onChange(file);
          }
        }
      }}
      disabled={disabled}
      value={undefined}
    />
  );
};

const UPLOAD_ICON = {
  image: <Image size={48} />,
  video: <VideoCamera size={48} />,
  document: <FilePdf size={48} />,
};

/**
 * Displays the file upload button and handles new file selections.
 */
const FileInputUploadButton: React.FC = () => {
  const {acceptHint, assetKind, disabled, name} = useFileInputContext();
  const {t} = useTranslation('components', {keyPrefix: 'form.input.fileInput'});
  const acceptedFileTypes = AllowedAssetMimeTypes[assetKind as AdminAssetKind].options;
  const fileType = getFileTypeFromMimeType(acceptedFileTypes);

  if (!fileType) {
    return null;
  }

  return (
    <div
      id="fileUploadInput"
      className={cn(
        'border-slate relative flex justify-center rounded-md border-2 border-dashed text-center text-slate-400',
        disabled && 'bg-slate-100'
      )}
    >
      <div className="flex w-full flex-col items-center">
        <div className="relative flex w-full flex-col items-center space-y-1 rounded-md p-5 ring-primary">
          <FileInputUpload
            key={name}
            assetKind={assetKind}
            name={name}
            accept={acceptedFileTypes.join(',')}
          />
          {UPLOAD_ICON[fileType]}
          <div className="flex text-sm font-medium text-slate-700">
            <span className="text-primary-darker">{t('buttonUpload')}</span>
            <span>&nbsp;{t('labelDragDrop')}</span>
          </div>
          {acceptHint !== undefined && <p className="text-slate-80 text-xs">{acceptHint}</p>}
        </div>
      </div>
    </div>
  );
};

type FileInputVideoPreviewProps = {
  aspectRatio?: 'aspect-video' | 'aspect-auto';
  assetKind: AdminAssetKind;
  fileInputValue: string | File;
};

/**
 * Displays the video preview with play/pause functionality.
 */
const FileInputVideoPreview: React.FC<FileInputVideoPreviewProps> = ({
  aspectRatio,
  assetKind,
  fileInputValue,
}) => {
  const {control, name, videoFileConfig} = useFileInputContext();
  const fieldValue = useWatch({
    control,
    name,
  });
  const thumbnailName = useWatch({
    control,
    name: videoFileConfig?.thumbnailOptions?.name ?? '',
  });
  const thumbnailValue = isDefined(videoFileConfig?.thumbnailOptions?.name)
    ? thumbnailName
    : undefined;
  const video16x9AssetKinds: Array<AdminAssetKind> = ['contentVideo'];

  const videoSrc = isFile(fileInputValue)
    ? URL.createObjectURL(fileInputValue)
    : getVideoUrl(import.meta.env.COHORT_ENV, fileInputValue, {
        h: Sizes.M,
        w: Sizes.M,
      });

  const thumbnailSrc = useMemo(() => {
    if (!thumbnailValue) return undefined;

    if (isFile(thumbnailValue)) {
      return URL.createObjectURL(thumbnailValue);
    }

    if (isFileList(thumbnailValue) && isDefined(thumbnailValue[0])) {
      return URL.createObjectURL(thumbnailValue[0]);
    }

    if (isString(thumbnailValue)) {
      return getImageUrl(import.meta.env.COHORT_ENV, thumbnailValue, {
        h: Sizes.M,
        w: Sizes.M,
      });
    }
  }, [thumbnailValue]);

  return (
    <Fragment>
      <VideoPlayer
        aspectRatio={
          aspectRatio ?? (video16x9AssetKinds.includes(assetKind) ? 'aspect-video' : 'aspect-auto')
        }
        thumbnailSrc={thumbnailSrc}
        videoSrc={videoSrc}
        style={{width: `${Sizes.L}px`}}
      />
      <div className="absolute right-0 top-0 m-4 flex gap-6">
        {isDefined(fieldValue) && isDefined(videoFileConfig?.thumbnailOptions) && (
          <FileInputThumbnailUploadButton
            thumbnailName={videoFileConfig.thumbnailOptions.name}
            thumbnailAssetKind={videoFileConfig.thumbnailOptions.assetKind}
          />
        )}
        <FileInputDeleteButton />
      </div>
    </Fragment>
  );
};

/** Component to display image preview */
type FileInputPreviewImageProps = {
  size?: number;
};
const FileInputPreviewImage: React.FC<FileInputPreviewImageProps> = ({size = 48}) => {
  const {assetKind, control, name} = useFileInputContext();
  const fieldValue = useWatch({
    control,
    name,
  });

  const imageSrc = isFile(fieldValue)
    ? URL.createObjectURL(fieldValue)
    : getImageUrl(import.meta.env.COHORT_ENV, fieldValue, {h: Sizes.S, w: Sizes.S});

  return (
    <img
      src={imageSrc}
      style={{width: size, height: size}}
      className={cn(
        'aspect-square rounded-md object-cover',
        isRoundedVisual(assetKind) && 'rounded-full'
      )}
    />
  );
};

/** Component to display document preview */
const FileInputPreviewDocument: React.FC = () => (
  <div className="rounded-md bg-slate-100 p-2">
    <FilePdf size={24} className="text-slate-400" />
  </div>
);

/** Component to display file metadata (name and size) */
const FileInputPreviewFileMetadata: React.FC = () => {
  const {control, name} = useFileInputContext();
  const fieldValue = useWatch({
    control,
    name,
  });

  if (!isFile(fieldValue)) {
    return null;
  }

  return (
    <div className="flex flex-col justify-center gap-y-[3px]">
      <div className="text-sm font-medium text-slate-700">{fieldValue.name}</div>
      <div className="text-xs font-medium text-slate-500">
        {bytesToKb(fieldValue.size).toFixed(2)} KB
      </div>
    </div>
  );
};

/** Component to display uploaded status message */
const FileInputPreviewUploadedFileMessage: React.FC = () => {
  const {t} = useTranslation('components', {keyPrefix: 'form.input.fileInput'});
  const {control, name} = useFileInputContext();
  const currentFile = useWatch({
    control,
    name,
  });
  const fileType = getFileType(currentFile);

  if (isFile(currentFile)) {
    return null;
  }

  const computeText = (): string => {
    if (fileType === 'image') {
      return isThumbnailUrl(String(currentFile))
        ? t('fileInputPreviewUploadedFileMessage.uploadedVideo')
        : t('fileInputPreviewUploadedFileMessage.uploadedImage');
    }
    return t('fileInputPreviewUploadedFileMessage.uploadedDocument');
  };

  return (
    <div className="flex items-center gap-x-1.5">
      <CheckCircle size={20} className="text-green-600" />
      <div className="text-sm font-medium text-slate-700">{computeText()}</div>
    </div>
  );
};

/**
 * Displays the preview of the uploaded document, image or video file.
 */
const FileInputPreview: React.FC<{children: React.ReactNode}> = ({children}) => {
  const {control, videoFileConfig, name} = useFileInputContext();
  const fieldValue = useWatch({
    control,
    name,
  });
  const fileType = getFileType(fieldValue);
  const customVideoWidthClass = match(videoFileConfig?.previewSize)
    .with('xs', () => 'w-1/4')
    .with('sm', () => 'w-1/2')
    .with('default', undefined, () => undefined)
    .exhaustive();

  return (
    <div
      className={cn(
        'relative',
        fileType === 'video'
          ? customVideoWidthClass
          : 'flex w-full justify-between rounded-lg border border-slate-200 p-4'
      )}
    >
      {children}
    </div>
  );
};

type FileInputThumbnailUploadButtonProps = {
  thumbnailName: string;
  thumbnailAssetKind: AdminAssetKind;
};
/**
 * Displays a button to upload a new thumbnail for the video file.
 */
const FileInputThumbnailUploadButton: React.FC<FileInputThumbnailUploadButtonProps> = ({
  thumbnailName,
  thumbnailAssetKind,
}) => {
  const {control, disabled, name} = useFileInputContext();
  const thumbnailNameValue = useWatch({
    control,
    name: thumbnailName,
  });

  const thumbnailValue = isDefined(thumbnailName) ? thumbnailNameValue : undefined;

  // i18nOwl-ignore [fileInputThumbnailUploadButton.labelChange]
  // i18nOwl-ignore [fileInputThumbnailUploadButton.labelUpload]
  return (
    <Button
      type="button"
      variant="secondary"
      data-testid={`${name}-upload`}
      disabled={disabled}
      className="p-0"
    >
      <label className="relative inline-block h-full w-full px-4 py-2">
        <div className="flex gap-x-2">
          <Trans
            i18nKey={`form.input.fileInput.fileInputThumbnailUploadButton.${
              isDefined(thumbnailValue) ? 'labelChange' : 'labelUpload'
            }`}
            ns="components"
            components={{
              icon: isDefined(thumbnailValue) ? (
                <ArrowsClockwise size={20} />
              ) : (
                <UploadSimple size={20} />
              ),
            }}
          />
        </div>

        <FileInputUpload
          key={thumbnailName}
          assetKind={thumbnailAssetKind}
          name={thumbnailName}
          accept={ImageMimeTypeSchema.options.join(',')}
        />
      </label>
    </Button>
  );
};

/**
 * Deletes the currently uploaded media file from the form field state.
 */
const FileInputDeleteButton: React.FC = () => {
  const {name, control, setHasCroppedImage, videoFileConfig} = useFileInputContext();
  const {field} = useController({control, name});
  const {field: thumbnailField} = useController({
    control,
    name: videoFileConfig?.thumbnailOptions?.name ?? '',
  });

  const fieldValue = useWatch({
    control,
    name,
  });

  return (
    <div className="flex flex-col justify-center">
      <Button
        onClick={() => {
          field.onChange(null);
          if (isImageFile(fieldValue.type)) {
            setHasCroppedImage(false);
          }
          if (videoFileConfig?.thumbnailOptions?.name) {
            thumbnailField.onChange(null);
          }
        }}
        variant="secondary"
        data-testid={`${name}-delete`}
        className="gap-x-2 p-2 text-red-500 shadow-sm"
      >
        <Trash size={20} />
      </Button>
    </div>
  );
};

/**
 * Manages the file cropping logic and displays the cropper if applicable.
 */
const FileInputImageCropper: React.FC = () => {
  const {assetKind, control, hasCroppedImage, name, setHasCroppedImage} = useFileInputContext();
  const {setValue} = useCohortForm();
  const {field} = useController({control, name});
  const fieldValue = useWatch({
    control,
    name,
  });

  const showCropper = !hasCroppedImage && isFile(fieldValue) && isImageFile(fieldValue.type);

  if (!showCropper) {
    return null;
  }

  return (
    <SlideOverCropImage
      open
      assetKind={assetKind}
      imageToCrop={{name: fieldValue.name, src: URL.createObjectURL(fieldValue)}}
      onClose={() => {
        setValue(name, null);
        setHasCroppedImage(true);
      }}
      onSubmit={(croppedImage: File) => {
        field.onChange(croppedImage);
        setHasCroppedImage(true);
      }}
    />
  );
};

export {
  FileInput,
  FileInputUploader,
  FileInputImageCropper,
  FileInputUpload,
  FileInputPreviewImage,
};
