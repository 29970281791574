import {CohortASchema} from '@cohort/admin-schemas/cohort';
import {ContentASchema} from '@cohort/admin-schemas/content';
import {
  RewardConfigurationASchema,
  UpdateRewardConfigurationASchema,
} from '@cohort/admin-schemas/rewards';
import {REDEEM_CODES_MAX_QUANTITY} from '@cohort/shared/constants';
import {
  asArray,
  DatetimeSchema,
  EmailSchema,
  HexColorSchema,
  LocalizedRichTextSchema,
  LocalizedStringSchema,
  SlugSchema,
} from '@cohort/shared/schema/common';
import {
  CampaignAudienceSchema,
  CampaignPricingSchema,
  CampaignStatusSchema,
  CampaignTypeSchema,
  LocalizedFaqsSchema,
} from '@cohort/shared/schema/common/campaign';
import {CurrencySchema, PricesSchema} from '@cohort/shared/schema/common/currency';
import {PaginationParamsSchema} from '@cohort/shared/schema/common/pagination';
import {z} from 'zod';

export const CampaignStoreASchema = z.object({
  title: LocalizedStringSchema,
  imageFileKey: z.string().nullable(),
  animationFileKey: z.string().nullable(),
  accentColorCode: HexColorSchema.nullable(),
  backgroundColorCode: HexColorSchema.nullable(),
  description: LocalizedRichTextSchema,
  defaultCurrency: CurrencySchema.nullable(),
  prices: PricesSchema,
  pricing: CampaignPricingSchema,
  slug: SlugSchema.nullable(),
  shopUrl: z.string().url().nullable(),
});
export type CampaignStoreADto = z.infer<typeof CampaignStoreASchema>;

export const CreateCampaignStoreASchema = z
  .object({
    title: LocalizedStringSchema,
    imageFileKey: z.string().nullable(),
    animationFileKey: z.string().nullable(),
    accentColorCode: HexColorSchema,
    backgroundColorCode: HexColorSchema,
    description: LocalizedRichTextSchema.or(z.record(z.never())),
    defaultCurrency: CurrencySchema.nullable(),
    prices: PricesSchema,
    pricing: CampaignPricingSchema,
    slug: SlugSchema.nullable(),
  })
  .partial();
export type CreateCampaignStoreADto = z.infer<typeof CreateCampaignStoreASchema>;

const CampaignChallengeASchema = z.object({
  imageFileKey: z.string().nullable(),
  animationFileKey: z.string().nullable(),
  description: LocalizedRichTextSchema,
  title: LocalizedStringSchema,
  optInCta: LocalizedStringSchema,
  standaloneUrl: z.string().url().nullable(),
  slug: SlugSchema.nullable(),
});
export type CampaignChallengeADto = z.infer<typeof CampaignChallengeASchema>;

const CreateCampaignChallengeASchema = z
  .object({
    title: LocalizedStringSchema.or(z.record(z.never())),
    imageFileKey: z.string().nullable(),
    animationFileKey: z.string().nullable(),
    description: LocalizedRichTextSchema.or(z.record(z.never())),
    optInCta: LocalizedStringSchema.or(z.record(z.never())),
    slug: SlugSchema.nullable(),
  })
  .partial();
export type CreateCampaignChallengeADto = z.infer<typeof CreateCampaignChallengeASchema>;

export const CreateCampaignAirdropASchema = z.object({}).partial();
export type CreateCampaignAirdropADto = z.infer<typeof CreateCampaignAirdropASchema>;

const RedeemCodeStatusASchema = z.enum(['available', 'consumed']);
export type RedeemCodeStatusADto = z.infer<typeof RedeemCodeStatusASchema>;

export const RedeemCodeASchema = z.object({
  id: z.string().uuid(),
  campaignId: z.string().uuid(),
  code: z.string().uuid(),
  status: RedeemCodeStatusASchema,
});
export type RedeemCodeADto = z.infer<typeof RedeemCodeASchema>;

export const CampaignRevenueASchema = z.array(
  z.object({
    currency: CurrencySchema,
    amount: z.number().int().min(0),
  })
);
export type CampaignRevenueADto = z.infer<typeof CampaignRevenueASchema>;

export const ReferralConfigurationASchema = z.object({
  enabled: z.boolean(),
  numReferrals: z.number().int().min(1),
  rewardConfiguration: RewardConfigurationASchema,
});
export type ReferralConfigurationADto = z.infer<typeof ReferralConfigurationASchema>;

export const UpdateReferralConfigurationASchema = z.object({
  enabled: z.boolean(),
  numReferrals: z.number().int().min(1),
  rewardConfiguration: UpdateRewardConfigurationASchema,
});

export const CampaignASchema = z.object({
  id: z.string().uuid(),
  status: CampaignStatusSchema,
  faqs: LocalizedFaqsSchema,
  tosFileKey: z.string().nullable(),
  tosUrl: z.string().url().nullable(),
  internalName: z.string().min(1),
  cohortId: z.string().uuid().nullable(),
  cohort: CohortASchema.nullable(),
  contents: z.array(ContentASchema),
  type: CampaignTypeSchema.nullable(),
  audience: CampaignAudienceSchema.nullable(),
  store: CampaignStoreASchema.nullable(),
  challenge: CampaignChallengeASchema.nullable(),
  createdAt: DatetimeSchema,
  updatedAt: DatetimeSchema,
  rewardConfiguration: RewardConfigurationASchema,
  referralConfiguration: ReferralConfigurationASchema.nullable(),
  showMaxSupply: z.boolean(),
  socialLayerEnabled: z.boolean(),
});
export type CampaignADto = z.infer<typeof CampaignASchema>;

export const CreateCampaignASchema = z
  .object({
    audience: CampaignAudienceSchema.nullable(),
    cohortId: z.string().uuid().nullable(),
    faqs: LocalizedFaqsSchema,
    store: CreateCampaignStoreASchema.nullable(),
    challenge: CreateCampaignChallengeASchema.nullable(),
    contents: z.array(ContentASchema),
    airdrop: CreateCampaignAirdropASchema.nullable(),
    tosFileKey: z.string().nullable(),
    type: CampaignTypeSchema.nullable(),
    rewardConfiguration: UpdateRewardConfigurationASchema.optional(),
    referralConfiguration: UpdateReferralConfigurationASchema.nullable(),
    showMaxSupply: z.boolean().optional(),
    isOtpEnabled: z.boolean().optional(),
    socialLayerEnabled: z.boolean().optional(),
  })
  .partial()
  .extend({
    internalName: z.string().min(1),
  });
export type CreateCampaignADto = z.infer<typeof CreateCampaignASchema>;

export const PatchCampaignASchema = CreateCampaignASchema.partial();
export type PatchCampaignADto = z.infer<typeof PatchCampaignASchema>;

export const GetCampaignsPriceParamASchema = z.enum(['free', 'paid']);
export type GetCampaignsPriceParamADto = z.infer<typeof GetCampaignsPriceParamASchema>;

export const GetCampaignsSortParamASchema = z.enum(['updatedAt', '-updatedAt', 'internalName']);
export type GetCampaignsSortParamADto = z.infer<typeof GetCampaignsSortParamASchema>;

export const GetCampaignsParamsASchema = PaginationParamsSchema.extend({
  status: z.preprocess(asArray, z.array(CampaignStatusSchema).optional()),
  pricing: z.preprocess(asArray, z.array(CampaignPricingSchema).optional()),
  type: z.preprocess(asArray, z.array(CampaignTypeSchema).optional()),
  orderBy: GetCampaignsSortParamASchema.default('-updatedAt'),
  digitalAssetCollectionId: z.preprocess(asArray, z.array(z.string().uuid()).optional()),
  perkId: z.preprocess(asArray, z.array(z.string().uuid()).optional()),
  search: z.string().optional(),
});
export type GetCampaignsParamsADto = z.infer<typeof GetCampaignsParamsASchema>;

export const CreateRedeemCodesResponseASchema = z.array(RedeemCodeASchema);
export type CreateRedeemCodesResponseADto = z.infer<typeof CreateRedeemCodesResponseASchema>;

export const CreateRedeemCodesDataASchema = z.object({
  quantity: z.number().int().min(1).max(REDEEM_CODES_MAX_QUANTITY),
});
export type CreateRedeemCodesDataADto = z.infer<typeof CreateRedeemCodesDataASchema>;

export const ListReferralsParamsASchema = PaginationParamsSchema.extend({
  searchEmail: z.string().nullable().optional(),
});
export type ListReferralsParamsADto = z.infer<typeof ListReferralsParamsASchema>;

export const RefereeASchema = z.object({
  userId: z.string().uuid(),
  email: EmailSchema,
  isDeleted: z.boolean(),
  referralDate: DatetimeSchema,
});
export type RefereeADto = z.infer<typeof RefereeASchema>;

export const ReferralASchema = z.object({
  challengeParticipationId: z.string().uuid(),
  countReferrals: z.number().int(),
  completedAt: DatetimeSchema.nullable(),
  referrer: z.object({
    userId: z.string().uuid(),
    email: EmailSchema,
    isDeleted: z.boolean(),
  }),
  referees: z.array(RefereeASchema),
});
export type ReferralADto = z.infer<typeof ReferralASchema>;
