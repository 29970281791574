import InstagramEmbedPost from '@cohort/components-xps/components/apps/instagram/InstagramEmbedPost';
import type {TriggerComponentPreviewProps} from '@cohort/merchants/apps';
import type {InstagramLikeMediaTriggerStruct} from '@cohort/shared/apps/instagram/triggers/likeMedia';

const InstagramLikeMediaTriggerIntegrationComponentPreview: React.FC<
  TriggerComponentPreviewProps<InstagramLikeMediaTriggerStruct>
> = ({config}) => {
  if (config.url.length < 1) {
    return null;
  }
  return <InstagramEmbedPost postUrl={config.url} />;
};

export default InstagramLikeMediaTriggerIntegrationComponentPreview;
