import type {TriggerIntegration} from '@cohort/merchants/apps';
import CohortFormTriggerIntegrationActionCtaComponentPreview from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/ActionCtaComponentPreview';
import StepCompletionContextComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/StepCompletionContextComponent';
import StepCompletionsGridComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/StepCompletionsGridComponent';
import StepCompletionTitleComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/StepCompletionTitleComponent';
import useStepCompletionColumns from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/useStepCompletionColumns';
import CohortFormScoreTriggerConfigComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/score/ConfigComponent';
import CohortFormScoreTriggerIntegrationComponentPreview from '@cohort/merchants/apps/cohort-form/triggerIntegrations/score/TriggerComponentPreview';
import type {CohortFormScoreTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/score';
import {CohortFormScoreTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/score';

export const CohortFormScoreTriggerIntegration: TriggerIntegration<CohortFormScoreTriggerStruct> = {
  spec: CohortFormScoreTriggerIntegrationSpec,
  configComponent: CohortFormScoreTriggerConfigComponent,
  stepCompletionTitleComponent: StepCompletionTitleComponent,
  stepCompletionContextComponent: StepCompletionContextComponent,
  useStepCompletionColumns: useStepCompletionColumns,
  stepCompletionsGridComponent: StepCompletionsGridComponent,
  getTitle: t => t('triggerIntegrations.score.title', {ns: 'app-cohort-form'}),
  preview: {
    CtaComponent: CohortFormTriggerIntegrationActionCtaComponentPreview,
    TriggerComponent: CohortFormScoreTriggerIntegrationComponentPreview,
  },
};
