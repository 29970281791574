import Button from '@cohort/merchants/components/buttons/Button';
import Input from '@cohort/merchants/components/form/input/Input';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import {X} from '@phosphor-icons/react';
import {Fragment, useEffect, useRef, useState} from 'react';
import type {FieldValues} from 'react-hook-form';
import {useController} from 'react-hook-form';

type EmojiInputProps<T extends FieldValues> = React.ComponentProps<typeof Input<T>> & {
  children?: React.ReactNode;
};

export default function EmojiInput<T extends FieldValues>(props: EmojiInputProps<T>): JSX.Element {
  const {children, ...rest} = props;
  const [showEmojiKeyBoard, setShowEmojiKeyBoard] = useState(false);
  const keyboardRef = useRef<HTMLDivElement>(null);
  const {field} = useController({
    control: props.control,
    name: props.name,
  });

  function handleClickOutside(event: MouseEvent): void {
    if (keyboardRef.current && !keyboardRef.current.contains(event.target as Node)) {
      return setShowEmojiKeyBoard(false);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <Fragment>
      <div className="flex items-end gap-4">
        {field.value ? (
          <div className="flex items-end gap-2">
            <div>
              <Input {...rest} type="hidden" />
              <button
                type="button"
                className="h-[38px] cursor-pointer rounded-md border px-4 py-2 text-sm"
                onClick={() => setShowEmojiKeyBoard(!showEmojiKeyBoard)}
              >
                {field.value}
              </button>
            </div>
            <Button
              className="size-[38px] self-end text-red-500"
              size="icon"
              variant="secondary"
              onClick={() => field.onChange(null)}
            >
              <X size={16} />
            </Button>
          </div>
        ) : (
          <Button variant="link" onClick={() => setShowEmojiKeyBoard(!showEmojiKeyBoard)}>
            Add icon
          </Button>
        )}
        {children}
      </div>
      {showEmojiKeyBoard && (
        // Hacky way to create a zone next to the emoji keyboard to detect click outside
        <div className="grid [grid-template-columns:max-content_1fr]">
          <div ref={keyboardRef}>
            <Picker
              data={data}
              onEmojiSelect={(emoji: {native: string}) => {
                field.onChange(emoji.native);
                setShowEmojiKeyBoard(false);
              }}
              previewPosition="none"
              skinTonePosition="none"
            />
          </div>
          <div></div>
        </div>
      )}
    </Fragment>
  );
}
