import {SelectMediaSheet} from '@cohort/merchants/apps/common/triggers/SelectMedia/SelectMediaSheet';
import {
  SelectMediaSheetCards,
  SelectMediaSheetCardsGroup,
  SelectMediaSheetPanel,
} from '@cohort/merchants/apps/common/triggers/SelectMedia/SelectMediaSheet';
import {groupMediasByMonth} from '@cohort/merchants/apps/common/triggers/utils';
import InstagramMediaCard from '@cohort/merchants/apps/instagram/triggerIntegrations/components/InstagramMediaCard';
import type {InstagramMedia} from '@cohort/shared/apps/instagram/common';
import {useTranslation} from 'react-i18next';

type SelectPostSheetProps = {
  medias: InstagramMedia[];
  onSelectMedia: (mediaId: string) => void;
};
const SelectPostSheet: React.FC<SelectPostSheetProps> = ({medias, onSelectMedia}) => {
  const {t} = useTranslation('app-instagram', {
    keyPrefix: 'triggerIntegrations.components.selectPostSheet',
  });

  const mediasByMonth = groupMediasByMonth(medias, media => media.createdAt);

  return (
    <SelectMediaSheet onSubmit={onSelectMedia} title={t('title')}>
      <SelectMediaSheetPanel isEmpty={medias.length === 0}>
        {Array.from(mediasByMonth).map(([month, medias]) => (
          <SelectMediaSheetCardsGroup label={month} key={month}>
            <SelectMediaSheetCards
              medias={medias}
              Card={InstagramMediaCard}
              className="grid-cols-3"
            />
          </SelectMediaSheetCardsGroup>
        ))}
      </SelectMediaSheetPanel>
    </SelectMediaSheet>
  );
};

export default SelectPostSheet;
