import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import DescriptionModeSelect from '@cohort/merchants/apps/common/DescriptionModeSelect';
import StepTitleInput from '@cohort/merchants/apps/common/StepTitleInput';
import type {Defaults} from '@cohort/merchants/apps/common/triggers/utils';
import {parseDefaults} from '@cohort/merchants/apps/common/triggers/utils';
import {getDescriptionMode} from '@cohort/merchants/apps/common/triggers/utils';
import ConnectionPicker from '@cohort/merchants/components/connections/ConnectionPicker';
import LanguageSelectorInput from '@cohort/merchants/components/form/LanguageSelectorInput';
import {useConnectionProfile} from '@cohort/merchants/hooks/api/Connections';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {SpotifyMerchantConnectorStruct} from '@cohort/shared/apps/spotify/merchantConnector';
import type {SpotifyFollowUserTriggerStruct} from '@cohort/shared/apps/spotify/triggers/followUser';
import type {Language} from '@cohort/shared/schema/common';
import {buildSpotifyUrl} from '@cohort/shared-frontend/common/apps/spotify';
import {Fragment, useEffect, useRef} from 'react';

const getDefaults = (definedLanguages: Language[]): Defaults => {
  const defaults = {
    title: {
      en: `Follow us on Spotify`,
      fr: `Suivez-nous sur Spotify`,
      es: `Sigue a nosotros en Spotify`,
    },
    description: {},
  };
  return parseDefaults(defaults, definedLanguages);
};

const SpotifyFollowUserTriggerConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps<SpotifyFollowUserTriggerStruct>
> = ({formReturn: {setValue, control, watch, reset, register}, trigger, step}) => {
  const init = useRef(false);
  const merchant = useCurrentMerchant();

  const [definedLanguages, connectionId] = watch(['definedLanguages', 'trigger.connectionId']);

  const {data: profile} = useConnectionProfile<SpotifyMerchantConnectorStruct>(
    merchant.id,
    connectionId ?? '',
    {enabled: !!connectionId && init.current}
  );

  useEffect(() => {
    if (profile) {
      const defaults = getDefaults(definedLanguages);
      setValue('defaultTitle', defaults.title);
      setValue('defaultDescription', defaults.description);
      setValue(
        'trigger.triggerIntegrationConfig.profileUrl',
        buildSpotifyUrl('user', profile.properties.name)
      );
    }
  }, [setValue, profile, definedLanguages]);

  useEffect(() => {
    if (init.current) {
      return;
    }
    const defaults = getDefaults(definedLanguages);
    reset(formValues => ({
      ...formValues,
      defaultTitle: defaults.title,
      defaultDescription: defaults.description,
      descriptionMode: getDescriptionMode(definedLanguages, defaults, step),
      title: step?.title ?? defaults.title,
      description: step?.description ?? defaults.description,
      icon: step?.icon !== undefined ? step.icon : '🎵',
      media: null,
      trigger: {
        id: trigger?.id ?? null,
        connectionId: trigger?.connectionId ?? null,
        triggerIntegrationId: 'spotify.follow-user',
        triggerIntegrationConfig: {
          profileUrl: trigger?.triggerIntegrationConfig.profileUrl ?? '',
        },
      },
    }));
    init.current = true;
  }, [definedLanguages, reset, step, trigger]);

  if (!init.current) {
    return null;
  }

  return (
    <Fragment>
      <div className="max-w-sm">
        <ConnectionPicker
          appId="spotify"
          control={control}
          register={register}
          connectionIdFieldName="trigger.connectionId"
        />
      </div>
      <DescriptionModeSelect />
      <LanguageSelectorInput />
      <StepTitleInput />
    </Fragment>
  );
};

export default SpotifyFollowUserTriggerConfigComponent;
