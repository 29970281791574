import EmojiInput from '@cohort/merchants/components/form/EmojiInput';
import LocalizedInput from '@cohort/merchants/components/form/input/LocalizedInput';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {CreateEditStepModalType} from '@cohort/merchants/pages/campaigns/campaign/edit/settings/challenge/utils';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {useTranslation} from 'react-i18next';

const StepTitleInput: React.FC = () => {
  const {t} = useTranslation('apps', {
    keyPrefix: 'common.stepTitleInput',
  });
  const merchant = useCurrentMerchant();
  const {register, control, watch} = useCohortForm<CreateEditStepModalType>();

  const [selectedLanguage, descriptionMode, defaultTitle] = watch([
    'selectedLanguage',
    'descriptionMode',
    'defaultTitle',
  ]);
  const isDefaultLanguageSelected = selectedLanguage === merchant.defaultLanguage;

  return (
    <EmojiInput label={t('labelIcon')} name="icon" register={register} control={control}>
      <div className={cn('w-full', descriptionMode === 'custom' && 'hidden')}>
        <LocalizedInput
          className={cn(descriptionMode === 'custom' && 'hidden')}
          type="text"
          label={t('labelTitle')}
          name="defaultTitle"
          placeholder={defaultTitle[selectedLanguage] ?? undefined}
          register={register}
          control={control}
          selectedLanguage={selectedLanguage}
          optional={!isDefaultLanguageSelected}
          disabled
        />
      </div>
      <div className={cn('w-full', descriptionMode !== 'custom' && 'hidden')}>
        <LocalizedInput
          type="text"
          label={t('labelTitle')}
          name="title"
          placeholder={defaultTitle[selectedLanguage] ?? undefined}
          register={register}
          control={control}
          selectedLanguage={selectedLanguage}
          optional={!isDefaultLanguageSelected}
        />
      </div>
    </EmojiInput>
  );
};
export default StepTitleInput;
