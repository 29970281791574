import type {ExecuteActionResponseADto} from '@cohort/admin-schemas/apps';
import {typeformAppKeys} from '@cohort/merchants/apps/typeform/api';
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {executeAction} from '@cohort/merchants/lib/api/Apps';
import type {ListPlaylistsActionStruct} from '@cohort/shared/apps/spotify/actions/listPlaylists';
import type {UseQueryResult} from '@tanstack/react-query';

export const spotifyAppKeys = {
  spotifyApp: ['spotifyApp'] as const,
  listPlaylists: (merchantId: string, merchantConnectionId: string) =>
    [...typeformAppKeys.typeformApp, merchantId, merchantConnectionId, 'list-playlists'] as const,
};

export const useSpotifyPlaylists = (
  merchantId: string,
  merchantConnectionId: string,
  search: string | undefined,
  options?: QueryOptions
): UseQueryResult<ExecuteActionResponseADto<ListPlaylistsActionStruct>, unknown> => {
  return useCohortQuery({
    queryKey: spotifyAppKeys.listPlaylists(merchantId, merchantConnectionId),
    queryFn: async () => {
      return executeAction<ListPlaylistsActionStruct>(merchantId, {
        appId: 'spotify',
        actionId: 'list-playlists',
        merchantConnectionId,
        input: {search},
      });
    },
    ...options,
  });
};
