import TiktokEmbedPost from '@cohort/components-xps/components/apps/tiktok/TiktokEmbedPost';
import type {TriggerComponentPreviewProps} from '@cohort/merchants/apps';
import type {TikTokLikeTiktokTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/likeTiktok';

const TiktokLikeTiktokTriggerIntegrationComponentPreview: React.FC<
  TriggerComponentPreviewProps<TikTokLikeTiktokTriggerStruct>
> = ({config, isMobile}) => {
  if (config.videoId.length < 1) {
    return null;
  }
  return <TiktokEmbedPost videoId={config.videoId} width={isMobile ? 350 : 400} />;
};

export default TiktokLikeTiktokTriggerIntegrationComponentPreview;
