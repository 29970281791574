import FacebookEmbed from '@cohort/components-xps/components/apps/facebook/FacebookEmbedPost';
import type {TriggerComponentPreviewProps} from '@cohort/merchants/apps';
import type {FacebookCommentPostTriggerStruct} from '@cohort/shared/apps/facebook/triggers/commentPost';

const FacebookCommentPostTriggerIntegrationComponentPreview: React.FC<
  TriggerComponentPreviewProps<FacebookCommentPostTriggerStruct>
> = ({config, isMobile}) => {
  if (config.postPermalink.length < 1) {
    return null;
  }
  return (
    <FacebookEmbed type="post" url={config.postPermalink} width={isMobile ? '350px' : '400px'} />
  );
};

export default FacebookCommentPostTriggerIntegrationComponentPreview;
