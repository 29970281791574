import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import DescriptionModeSelect from '@cohort/merchants/apps/common/DescriptionModeSelect';
import StepTitleInput from '@cohort/merchants/apps/common/StepTitleInput';
import type {Defaults} from '@cohort/merchants/apps/common/triggers/utils';
import {parseDefaults} from '@cohort/merchants/apps/common/triggers/utils';
import {getDescriptionMode} from '@cohort/merchants/apps/common/triggers/utils';
import BaseFacebookSelectPostTriggerIntegrationConfig from '@cohort/merchants/apps/facebook/triggers/BaseFacebookSelectPostTriggerIntegrationConfig';
import ConnectionPicker from '@cohort/merchants/components/connections/ConnectionPicker';
import LanguageSelectorInput from '@cohort/merchants/components/form/LanguageSelectorInput';
import type {FacebookPost} from '@cohort/shared/apps/facebook/common';
import type {FacebookLikePostTriggerStruct} from '@cohort/shared/apps/facebook/triggers/likePost';
import type {Language} from '@cohort/shared/schema/common';
import {useEffect, useRef} from 'react';
import {Fragment} from 'react/jsx-runtime';
import {useTranslation} from 'react-i18next';

const getDefaults = (definedLanguages: Language[]): Defaults => {
  const defaults = {
    title: {
      en: `Like our Facebook post`,
      fr: `Aimer notre publication Facebook`,
      es: `Dale like a nuestra publicación de Facebook`,
    },
    description: {},
  };
  return parseDefaults(defaults, definedLanguages);
};

const FacebookLikePostTriggerConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps<FacebookLikePostTriggerStruct>
> = ({formReturn: {watch, setValue, reset, control, register}, trigger, step}) => {
  const init = useRef(false);

  const {t} = useTranslation('app-facebook', {keyPrefix: 'triggers.like-post.configComponent'});

  const [definedLanguages, connectionId, postId] = watch([
    'definedLanguages',
    'trigger.connectionId',
    'trigger.triggerIntegrationConfig.postId',
  ]);

  const onSelectPost = (post: FacebookPost): void => {
    setValue('trigger.triggerIntegrationConfig.postId', post.id);
    setValue('trigger.triggerIntegrationConfig.postPermalink', post.permalinkUrl);
  };

  useEffect(() => {
    if (init.current) {
      const defaults = getDefaults(definedLanguages);
      setValue('defaultTitle', defaults.title);
      setValue('defaultDescription', defaults.description);
    }
  }, [definedLanguages, setValue]);

  useEffect(() => {
    if (init.current) {
      return;
    }
    const defaults = getDefaults(definedLanguages);
    reset(formValues => ({
      ...formValues,
      defaultTitle: defaults.title,
      defaultDescription: defaults.description,
      descriptionMode: getDescriptionMode(definedLanguages, defaults, step),
      title: step?.title ?? defaults.title,
      description: step?.description ?? defaults.description,
      icon: step?.icon !== undefined ? step.icon : '👍',
      media: null,
      trigger: {
        id: trigger?.id ?? null,
        connectionId: trigger?.connectionId ?? null,
        triggerIntegrationId: 'facebook.like-post',
        triggerIntegrationConfig: {
          postId: trigger?.triggerIntegrationConfig.postId ?? '',
          postPermalink: trigger?.triggerIntegrationConfig.postPermalink ?? '',
        },
      },
    }));
    init.current = true;
  }, [definedLanguages, reset, step, trigger]);

  if (!init.current) {
    return null;
  }

  return (
    <Fragment>
      <div className="max-w-sm">
        <ConnectionPicker
          appId="facebook"
          control={control}
          register={register}
          connectionIdFieldName="trigger.connectionId"
        />
      </div>
      {connectionId && (
        <BaseFacebookSelectPostTriggerIntegrationConfig
          connectionId={connectionId}
          onSelectPost={onSelectPost}
          description={t('descriptionPostSelect')}
          title={t('titlePostSelect')}
          noPostsMessage={t('noPostsMessage')}
          selectedPostId={postId}
        />
      )}
      <DescriptionModeSelect />
      <LanguageSelectorInput />
      <StepTitleInput />
    </Fragment>
  );
};

export default FacebookLikePostTriggerConfigComponent;
