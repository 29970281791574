import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import DescriptionModeSelect from '@cohort/merchants/apps/common/DescriptionModeSelect';
import StepTitleInput from '@cohort/merchants/apps/common/StepTitleInput';
import type {Defaults} from '@cohort/merchants/apps/common/triggers/utils';
import {parseDefaults} from '@cohort/merchants/apps/common/triggers/utils';
import {getDescriptionMode} from '@cohort/merchants/apps/common/triggers/utils';
import {useTypeformForms} from '@cohort/merchants/apps/typeform/api';
import ConnectionPicker from '@cohort/merchants/components/connections/ConnectionPicker';
import ErrorState from '@cohort/merchants/components/ErrorState';
import LanguageSelectorInput from '@cohort/merchants/components/form/LanguageSelectorInput';
import SelectInput from '@cohort/merchants/components/form/select/SelectInput';
import type {SelectOption} from '@cohort/merchants/components/form/select/SelectPicker';
import HighlightText from '@cohort/merchants/components/HighlightText';
import Loader from '@cohort/merchants/components/Loader';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {TypeformFillFormTriggerStruct} from '@cohort/shared/apps/typeform/triggers/fillForm';
import type {Language} from '@cohort/shared/schema/common';
import {Fragment, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const getDefaults = (definedLanguages: Language[]): Defaults => {
  const defaults = {
    title: {
      en: 'Complete the form',
      fr: 'Répondre au questionnaire',
      es: 'Rellene el formulario',
    },
    description: {},
  };
  return parseDefaults(defaults, definedLanguages);
};

const TypeformFillFormTriggerConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps<TypeformFillFormTriggerStruct>
> = ({formReturn: {control, watch, reset, register, setValue}, trigger, step}) => {
  const init = useRef(false);
  const merchant = useCurrentMerchant();

  const {t} = useTranslation('app-typeform', {
    keyPrefix: 'triggerIntegrations.configComponent',
  });

  const [definedLanguages, connectionId] = watch(['definedLanguages', 'trigger.connectionId']);

  const {
    data: typeformFormsRes,
    isLoading: isTypeformFormsLoading,
    isFetched: isTypeformFormsFetched,
  } = useTypeformForms(merchant.id, connectionId ?? '', {enabled: !!connectionId && init.current});

  const typeformForms = typeformFormsRes?.output.forms ?? [];
  const sortedTypeformForms = typeformForms.sort(formA => (formA.settings.is_public ? -1 : 1));

  useEffect(() => {
    if (init.current) {
      const defaults = getDefaults(definedLanguages);
      setValue('defaultTitle', defaults.title);
      setValue('defaultDescription', defaults.description);
    }
  }, [definedLanguages, setValue]);

  useEffect(() => {
    if (init.current) {
      return;
    }
    const defaults = getDefaults(definedLanguages);
    reset(formValues => ({
      ...formValues,
      defaultTitle: defaults.title,
      defaultDescription: defaults.description,
      descriptionMode: getDescriptionMode(definedLanguages, defaults, step),
      title: step?.title ?? defaults.title,
      description: step?.description ?? defaults.description,
      icon: step?.icon !== undefined ? step.icon : '❓',
      media: null,
      trigger: {
        id: trigger?.id ?? null,
        connectionId: trigger?.connectionId ?? null,
        triggerIntegrationId: 'typeform.fill-form',
        triggerIntegrationConfig: {
          formId: trigger?.triggerIntegrationConfig.formId ?? '',
        },
      },
    }));
    init.current = true;
  }, [definedLanguages, reset, step, trigger]);

  if (!init.current) {
    return null;
  }

  const formSelector = match({
    connectionId,
    typeformForms,
    isTypeformFormsLoading,
    isTypeformFormsFetched,
  })
    .with({connectionId: null}, () => null)
    .with({isTypeformFormsLoading: true}, () => <Loader size={30} color="gray" />)
    .with({typeformForms: []}, () => <p>{t('formSelect.noFormsMessage')}</p>)
    .with({isTypeformFormsFetched: true}, () => (
      <div className="flex flex-col gap-6">
        <div className="max-w-sm">
          <SelectInput
            labelPosition="top"
            label={t('formSelect.labelFormSelect')}
            options={sortedTypeformForms.map(
              form =>
                ({
                  value: form.id,
                  label: form.settings.is_public
                    ? form.title
                    : t('formSelect.privateFormLabel', {title: form.title}),
                  isDisabled: !form.settings.is_public,
                }) satisfies SelectOption
            )}
            name="trigger.triggerIntegrationConfig.formId"
            register={register}
            control={control}
          />
        </div>
        <div className="mt-2">
          <HighlightText
            type="info"
            text={<label>{t('formSelect.formSelectInfoMessage')}</label>}
          />
        </div>
      </div>
    ))
    .otherwise(() => <ErrorState />);

  return (
    <Fragment>
      <div className="max-w-sm">
        <ConnectionPicker
          appId="typeform"
          control={control}
          register={register}
          connectionIdFieldName="trigger.connectionId"
        />
      </div>
      {formSelector}
      <DescriptionModeSelect />
      <LanguageSelectorInput />
      <StepTitleInput />
    </Fragment>
  );
};

export default TypeformFillFormTriggerConfigComponent;
