import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import StepDescriptionInput from '@cohort/merchants/apps/common/StepDescriptionInput';
import StepTitleInput from '@cohort/merchants/apps/common/StepTitleInput';
import type {Defaults} from '@cohort/merchants/apps/common/triggers/utils';
import {parseDefaults} from '@cohort/merchants/apps/common/triggers/utils';
import Button from '@cohort/merchants/components/buttons/Button';
import DraggableList from '@cohort/merchants/components/form/DraggableList';
import DraggableListItem from '@cohort/merchants/components/form/DraggableListItem';
import FieldLabel from '@cohort/merchants/components/form/FieldLabel';
import {
  FileInput,
  FileInputPreviewImage,
  FileInputUpload,
} from '@cohort/merchants/components/form/input/fileInput/FileInput';
import LocalizedInput from '@cohort/merchants/components/form/input/LocalizedInput';
import LanguageSelectorInput from '@cohort/merchants/components/form/LanguageSelectorInput';
import HighlightText from '@cohort/merchants/components/HighlightText';
import type {CreateEditStepModalTyped} from '@cohort/merchants/pages/campaigns/campaign/edit/settings/challenge/utils';
import type {CohortSwipeCardsTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/swipeCards';
import type {Language} from '@cohort/shared/schema/common';
import {isFile, isFileList} from '@cohort/shared-frontend/utils/isFile';
import {uuidv4} from '@firebase/util';
import {ImageSquare, PlusCircle} from '@phosphor-icons/react';
import {X} from '@phosphor-icons/react/dist/ssr';
import {Fragment, useEffect, useRef} from 'react';
import type {Control, UseFormRegister} from 'react-hook-form';
import {useController, useFieldArray} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

const getDefaults = (definedLanguages: Language[]): Defaults => {
  const defaults = {
    title: {},
    description: {},
  };
  return parseDefaults(defaults, definedLanguages);
};

type SwipeCard = {
  id: string;
  imageFileKey: string | null;
  title: Record<Language, string>;
};

type SwipeCardItemProps = {
  index: number;
  register: UseFormRegister<CreateEditStepModalTyped<CohortSwipeCardsTriggerStruct>>;
  control: Control<CreateEditStepModalTyped<CohortSwipeCardsTriggerStruct>>;
  selectedLanguage: Language;
  onRemove: () => void;
};

const SwipeCardItem: React.FC<SwipeCardItemProps> = ({
  index,
  register,
  control,
  selectedLanguage,
  onRemove,
}) => {
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'triggerIntegrations.swipe-cards.configComponent',
  });
  const {field, fieldState} = useController({
    name: `trigger.triggerIntegrationConfig.cards.${index}.imageFileKey`,
    control,
  });
  const hasImage =
    field.value !== null &&
    (isFile(field.value) ||
      (isFileList(field.value) && field.value.length > 0) ||
      typeof field.value === 'string');

  return (
    <Fragment>
      <DraggableListItem onRemove={onRemove}>
        <div className="flex items-center gap-4">
          <FileInput
            name={`trigger.triggerIntegrationConfig.cards.${index}.imageFileKey`}
            assetKind="swipeCardImage"
            videoFileConfig={{
              previewSize: 'sm',
            }}
            control={control}
            register={register}
          >
            {hasImage ? (
              <div className="group relative size-[40px] [&>img]:size-auto">
                <FileInputPreviewImage size={40} />
                <div
                  className="absolute left-0 top-0 flex h-full w-full items-center justify-center rounded-lg bg-black opacity-0 transition-opacity group-hover:opacity-70"
                  onClick={() => field.onChange(null)}
                >
                  <X weight="bold" size={20} className="text-white" />
                </div>
              </div>
            ) : (
              <div className="background-slate-50 relative flex size-[40px] cursor-pointer items-center justify-center rounded-lg border">
                <FileInputUpload
                  assetKind="swipeCardImage"
                  name={`trigger.triggerIntegrationConfig.cards.${index}.imageFileKey`}
                  accept="image/*"
                />
                <ImageSquare size={20} className="cursor-pointer text-slate-400" />
              </div>
            )}
          </FileInput>
          <div className="h-[40px] w-[1px] border" />
          <LocalizedInput
            type="text"
            name={`trigger.triggerIntegrationConfig.cards.${index}.title`}
            placeholder={t('cardsTitlePlaceholder')}
            register={register}
            control={control}
            selectedLanguage={selectedLanguage}
          />
        </div>
      </DraggableListItem>
      {fieldState.error?.message && (
        <p className="text-sm text-red-500">{t('cardsEmptyImageMessage')}</p>
      )}
    </Fragment>
  );
};

const CohortSwipeCardsTriggerConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps<CohortSwipeCardsTriggerStruct>
> = ({formReturn: {register, control, watch, reset, setFormAssets}, step, trigger}) => {
  const init = useRef(false);

  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'triggerIntegrations.swipe-cards.configComponent',
  });

  const [selectedLanguage, definedLanguages] = watch(['selectedLanguage', 'definedLanguages']);

  const {fields, append, remove, replace} = useFieldArray({
    name: 'trigger.triggerIntegrationConfig.cards',
    control,
    keyName: '_id',
  });

  const {fieldState} = useController({
    control,
    name: 'trigger.triggerIntegrationConfig.cards',
  });

  const allCards: Array<SwipeCard> = watch('trigger.triggerIntegrationConfig.cards');

  useEffect(() => {
    if (init.current) {
      return;
    }
    const defaults = getDefaults(definedLanguages);
    reset(formValues => ({
      ...formValues,
      defaultTitle: defaults.title,
      defaultDescription: defaults.description,
      descriptionMode: 'custom',
      title: step?.title ?? {},
      description: step?.description ?? {},
      icon: step?.icon !== undefined ? step.icon : '👆',
      media: null,
      trigger: {
        id: trigger?.id ?? null,
        connectionId: null,
        triggerIntegrationId: 'cohort-form.swipe-cards',
        triggerIntegrationConfig: {
          leftBtnLabel: trigger?.triggerIntegrationConfig.leftBtnLabel ?? {},
          rightBtnLabel: trigger?.triggerIntegrationConfig.rightBtnLabel ?? {},
          cards: trigger?.triggerIntegrationConfig.cards ?? [],
        },
      },
    }));
    setFormAssets([
      {
        name: 'trigger.triggerIntegrationConfig.cards',
        nestedName: 'imageFileKey',
        type: 'swipeCardImage',
      },
    ]);
    init.current = true;
  }, [definedLanguages, reset, setFormAssets, step, trigger]);

  if (!init.current) {
    return null;
  }

  return (
    <Fragment>
      <LanguageSelectorInput />
      <StepTitleInput />
      <StepDescriptionInput />
      <section>
        <FieldLabel label={t('cardsLabel')} />
        {fieldState.error?.message && (
          <p className="text-sm text-red-500">{t('cardsEmptyMessage')}</p>
        )}
        <DraggableList
          handleOnReorder={ids =>
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            replace(ids.map(id => allCards.find(field => field.id === id)!))
          }
          items={fields.map((field, index) => ({
            // @ts-expect-error - type inference is not working
            id: field.id,
            item: (
              <SwipeCardItem
                index={index}
                register={register}
                control={control}
                selectedLanguage={selectedLanguage}
                onRemove={() => remove(index)}
              />
            ),
          }))}
        />
        <Button
          variant="ghost"
          type="button"
          onClick={() =>
            append({
              id: uuidv4(),
              imageFileKey: null,
              title: {},
            })
          }
        >
          <PlusCircle className="-ml-1 mr-2 h-5 w-5" />
          {t('addCardBtn')}
        </Button>
      </section>
      <HighlightText type="info" text={t('swipeCardsInfo')} />
      <div className="flex items-center justify-between gap-4">
        <LocalizedInput
          label={t('leftBtnLabel')}
          type="text"
          name="trigger.triggerIntegrationConfig.leftBtnLabel"
          placeholder={t('leftBtnPlaceholder')}
          required
          register={register}
          control={control}
          selectedLanguage={selectedLanguage}
        />
        <LocalizedInput
          label={t('rightBtnLabel')}
          type="text"
          name="trigger.triggerIntegrationConfig.rightBtnLabel"
          placeholder={t('rightBtnPlaceholder')}
          required
          register={register}
          control={control}
          selectedLanguage={selectedLanguage}
        />
      </div>
    </Fragment>
  );
};

export default CohortSwipeCardsTriggerConfigComponent;
