import type {TriggerComponentPreviewProps} from '@cohort/merchants/apps';
import InputPreview from '@cohort/merchants/components/campaigns/preview/InputPreview';
import type {CohortFormTextTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/text';
import {getLocalizedContentOrThrow} from '@cohort/shared/utils/localization';

const placeholders = {
  en: 'Enter your answer here',
  fr: 'Entrez votre réponse ici',
  es: 'Escribe tu respuesta aquí',
};

const CohortFormTextTriggerIntegrationComponentPreview: React.FC<
  TriggerComponentPreviewProps<CohortFormTextTriggerStruct>
> = ({localizationConfig}) => {
  const placeholder = getLocalizedContentOrThrow(placeholders, localizationConfig);

  return <InputPreview width="w-[350px]" type="text" placeholder={placeholder} />;
};

export default CohortFormTextTriggerIntegrationComponentPreview;
