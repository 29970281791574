import type {
  ChallengeStepADto,
  ChallengeStepTriggerADto,
} from '@cohort/admin-schemas/challengeSteps';
import {CohortFormAnswer} from '@cohort/merchants/apps/cohort-form/components/CohortFormAnswer';
import {
  promptTypeFromIntegrationId,
  VerificationDataSchema,
} from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/utils';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {buildLocalizationConfig, getLocalizedContent} from '@cohort/shared/utils/localization';

const StepCompletionContextComponent: React.FC<{
  step: ChallengeStepADto;
  stepTrigger: ChallengeStepTriggerADto;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}> = ({data, step, stepTrigger}) => {
  const merchant = useCurrentMerchant();
  const localizationConfig = buildLocalizationConfig(
    merchant.defaultLanguage,
    merchant.supportedLanguages
  );

  const title = getLocalizedContent(step.title, localizationConfig) ?? '';
  const promptType = promptTypeFromIntegrationId(stepTrigger.triggerIntegrationId);
  const parsed = VerificationDataSchema.safeParse(data);

  if (!promptType || !parsed.success) {
    return null;
  }

  return (
    <ul className="flex flex-col space-y-4 rounded-md border border-border bg-slate-50">
      <li className="grid grid-cols-3 bg-slate-50 px-4 py-4 text-slate-700 last:rounded-b-md">
        <div className="col-span-1 pr-2 text-left text-slate-500">{title}</div>
        <div className="col-span-2 whitespace-pre text-left font-medium text-slate-700">
          <CohortFormAnswer
            promptType={promptType}
            response={parsed.data.answer}
            mediaViewMode="inline"
          />
        </div>
      </li>
    </ul>
  );
};

export default StepCompletionContextComponent;
