import RankBadge from '@cohort/merchants/components/badges/RankBadge';
import {
  FileInput,
  FileInputPreviewImage,
} from '@cohort/merchants/components/form/input/fileInput/FileInput';
import Input from '@cohort/merchants/components/form/input/Input';
import LocalizedInput from '@cohort/merchants/components/form/input/LocalizedInput';
import SelectInput from '@cohort/merchants/components/form/select/SelectInput';
import SwitchInput from '@cohort/merchants/components/form/SwitchInput';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {CohortFormPictureChoiceTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/pictureChoice';
import type {Language, LocalizedString} from '@cohort/shared/schema/common';
import {AllowedAssetMimeTypes} from '@cohort/shared/schema/common/assets';
import {PlusCircle, Trash} from '@phosphor-icons/react';
import type {FC} from 'react';
import React, {Fragment} from 'react';
import type {Control, UseFormRegister} from 'react-hook-form';
import {useFieldArray, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type PictureChoiceFormSectionInputProps = {
  control: Control<FormData>;
  register: UseFormRegister<FormData>;
  itemIndex: number;
  onDelete: () => void;
};

const PictureChoiceFormSectionInput: FC<PictureChoiceFormSectionInputProps> = ({
  itemIndex,
  control,
  register,
  onDelete,
}) => {
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'components.promptSheet.pictureChoiceFormSection',
  });

  const [selectedLanguage, showLabels] = useWatch({
    name: ['selectedLanguage', 'trigger.triggerIntegrationConfig.showLabels'],
    control,
  });

  return (
    <div className="h-28 w-full rounded-xl border border-slate-200 bg-slate-50">
      <div className="flex items-center justify-between space-x-4 p-4">
        <FileInput
          name={`trigger.triggerIntegrationConfig.pictureChoiceOptions.${itemIndex}.imageFileKey`}
          assetKind="pictureChoicePromptImage"
          control={control}
          register={register}
        >
          <div className="relative aspect-square size-20 rounded-xl border border-slate-200">
            <FileInputPreviewImage size={80} />
          </div>
        </FileInput>
        <div className="flex grow flex-col gap-y-4">
          <div className="flex w-full justify-between">
            <RankBadge rank={itemIndex + 1} size={20} />
            <Trash size={20} className="cursor-pointer text-red-500" onClick={onDelete} />
          </div>
          {showLabels && (
            <LocalizedInput
              type="text"
              name={`trigger.triggerIntegrationConfig.pictureChoiceOptions.${itemIndex}.label`}
              register={register}
              control={control}
              selectedLanguage={selectedLanguage}
              placeholder={t('pictureChoiceInputPlaceholder', {index: itemIndex + 1})}
            />
          )}
        </div>
      </div>
    </div>
  );
};

type FormData = {
  selectedLanguage: Language;
  trigger: {
    triggerIntegrationConfig: CohortFormPictureChoiceTriggerStruct['Config'] & {
      maxMultipleChoicesType: 'exact-number' | 'unlimited';
      pictureChoiceOptions: {imageFileKey: File | string; label?: LocalizedString}[];
    };
  };
};

const PictureChoiceFormSection: React.FC = () => {
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'components.promptSheet.pictureChoiceFormSection',
  });
  const {
    register,
    control,
    watch,
    formState: {errors},
    setValue,
  } = useCohortForm<FormData>();
  const optionsError = errors.trigger?.triggerIntegrationConfig?.pictureChoiceOptions;

  const pictureChoiceOptions = useFieldArray({
    name: 'trigger.triggerIntegrationConfig.pictureChoiceOptions',
    control,
  });

  const [config] = watch(['trigger.triggerIntegrationConfig']);

  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  const handleAddPictureClick = (): void => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0];
    if (file) {
      pictureChoiceOptions.append({imageFileKey: file});
    }
  };

  return (
    <div className="relative flex flex-col space-y-2">
      <label className="block text-sm font-medium text-slate-700">{t('pictureChoiceLabel')}</label>

      <div className="grid grid-cols-2 gap-2">
        {pictureChoiceOptions.fields.map((_, index) => (
          <PictureChoiceFormSectionInput
            key={index}
            control={control}
            register={register}
            itemIndex={index}
            onDelete={() => pictureChoiceOptions.remove(index)}
          />
        ))}
        <div
          className="flex h-28 w-full cursor-pointer items-center justify-center rounded-xl bg-slate-50"
          onClick={handleAddPictureClick}
        >
          <PlusCircle size={24} className="text-primary" />
        </div>
        <input
          type="file"
          accept={AllowedAssetMimeTypes.pictureChoicePromptImage.options.join(',')}
          ref={fileInputRef}
          className="hidden"
          onChange={handleFileChange}
        />
      </div>

      {optionsError !== undefined && (
        <span className="text-sm font-normal text-red-500">{t('errorNoOptions')}</span>
      )}

      <div className="flex flex-col gap-6 pt-6">
        <div className="flex w-full justify-between">
          <div className="w-full text-sm font-medium text-slate-700">{t('showLabels')}</div>
          <div className="w-10">
            <SwitchInput
              name="trigger.triggerIntegrationConfig.showLabels"
              register={register}
              control={control}
            />
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <div className="flex w-full justify-between">
            <div className="w-full text-sm font-medium text-slate-700">
              {t('multipleSelection')}
            </div>
            <div className="w-10">
              <SwitchInput
                name="trigger.triggerIntegrationConfig.multipleChoice"
                register={register}
                control={control}
                onCheckedChange={checked => {
                  if (!checked) {
                    setValue('trigger.triggerIntegrationConfig.maxMultipleChoices', null);
                    setValue(
                      'trigger.triggerIntegrationConfig.maxMultipleChoicesType',
                      'unlimited'
                    );
                  }
                  setValue('trigger.triggerIntegrationConfig.userPropertyId', null);
                }}
              />
            </div>
          </div>

          {config.multipleChoice && (
            <Fragment>
              <div className="grid w-full grid-cols-6 gap-x-3">
                <div className="col-span-2">
                  <SelectInput
                    name="trigger.triggerIntegrationConfig.maxMultipleChoicesType"
                    register={register}
                    control={control}
                    required
                    options={[
                      {label: t('optionUnlimited'), value: 'unlimited'},
                      {label: t('optionMaxNumber'), value: 'exact-number'},
                    ]}
                    onChange={option => {
                      if (option?.value === 'unlimited') {
                        setValue('trigger.triggerIntegrationConfig.maxMultipleChoices', null);
                      }
                    }}
                  />
                </div>
                {config.maxMultipleChoicesType === 'exact-number' && (
                  <Input
                    className="col-span-1"
                    type="number"
                    name="trigger.triggerIntegrationConfig.maxMultipleChoices"
                    register={register}
                    control={control}
                    min={1}
                    defaultValue={1}
                  />
                )}
              </div>
              {config.maxMultipleChoices &&
                config.maxMultipleChoices > pictureChoiceOptions.fields.length && (
                  <span className="text-sm font-normal text-red-500">
                    {t('maxMultipleChoicesTooHighWarning')}
                  </span>
                )}
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default PictureChoiceFormSection;
