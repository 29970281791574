import InstagramEmbedPost from '@cohort/components-xps/components/apps/instagram/InstagramEmbedPost';
import type {TriggerComponentPreviewProps} from '@cohort/merchants/apps';
import type {InstagramCommentMediaTriggerStruct} from '@cohort/shared/apps/instagram/triggers/commentMedia';

const InstagramCommentMediaTriggerIntegrationComponentPreview: React.FC<
  TriggerComponentPreviewProps<InstagramCommentMediaTriggerStruct>
> = ({config}) => {
  if (config.mediaPermalink.length < 1) {
    return null;
  }
  return <InstagramEmbedPost postUrl={config.mediaPermalink} />;
};

export default InstagramCommentMediaTriggerIntegrationComponentPreview;
