import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import StepContentInput from '@cohort/merchants/apps/common/StepContentInput';
import StepDescriptionInput from '@cohort/merchants/apps/common/StepDescriptionInput';
import StepTitleInput from '@cohort/merchants/apps/common/StepTitleInput';
import type {Defaults} from '@cohort/merchants/apps/common/triggers/utils';
import {defaultMedia, parseDefaults} from '@cohort/merchants/apps/common/triggers/utils';
import {
  FileInput,
  FileInputUploader,
} from '@cohort/merchants/components/form/input/fileInput/FileInput';
import LocalizedInput from '@cohort/merchants/components/form/input/LocalizedInput';
import LanguageSelectorInput from '@cohort/merchants/components/form/LanguageSelectorInput';
import HighlightText from '@cohort/merchants/components/HighlightText';
import Separator from '@cohort/merchants/components/Separator';
import type {CohortScanQrCodeTriggerStruct} from '@cohort/shared/apps/cohort/triggers/scanQrCode';
import type {Language} from '@cohort/shared/schema/common';
import {Fragment, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';

const getDefaults = (definedLanguages: Language[]): Defaults => {
  const defaults = {
    title: {
      en: `Scan the QR Code`,
      fr: `Scanner le QR Code`,
      es: `Escanear el QR Code`,
    },
    description: {
      en: `You must scan the QR Code to complete the step`,
      fr: `Vous devez scanner le QR Code pour compléter la tâche`,
      es: `Debes escanear el QR Code para completar la tarea`,
    },
  };
  return parseDefaults(defaults, definedLanguages);
};

const CohortScanQrCodeTriggerConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps<CohortScanQrCodeTriggerStruct>
> = ({formReturn: {control, register, watch, reset, setFormAssets, setValue}, step, trigger}) => {
  const init = useRef(false);

  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'triggerIntegrations.scan-qr-code.configComponent',
  });

  const [selectedLanguage, definedLanguages] = watch(['selectedLanguage', 'definedLanguages']);

  useEffect(() => {
    if (init.current) {
      const defaults = getDefaults(definedLanguages);
      setValue('defaultTitle', defaults.title);
      setValue('defaultDescription', defaults.description);
    }
  }, [definedLanguages, setValue]);

  useEffect(() => {
    if (init.current) {
      return;
    }
    const defaults = getDefaults(definedLanguages);
    reset(formValues => ({
      ...formValues,
      defaultTitle: defaults.title,
      defaultDescription: defaults.description,
      descriptionMode: 'custom',
      title: step?.title ?? {},
      description: step?.description ?? {},
      icon: step?.icon !== undefined ? step.icon : '🤳',
      media: step?.media ?? defaultMedia,
      trigger: {
        id: trigger?.id ?? null,
        connectionId: null,
        triggerIntegrationId: 'cohort.scan-qr-code',
        triggerIntegrationConfig: {
          successImageFileKey: trigger?.triggerIntegrationConfig.successImageFileKey ?? null,
          successMessage: trigger?.triggerIntegrationConfig.successMessage ?? {},
        },
      },
    }));
    setFormAssets([
      {
        name: 'trigger.triggerIntegrationConfig.successImageFileKey',
        type: 'qrCodeSuccessImage',
      },
    ]);
    init.current = true;
  }, [definedLanguages, reset, setFormAssets, step, trigger]);

  if (!init.current) {
    return null;
  }

  return (
    <Fragment>
      <HighlightText text={t('integrationHint')} type="info" />
      <LanguageSelectorInput />
      <StepTitleInput />
      <StepDescriptionInput />
      <FileInput
        fieldWrapper={{
          label: t('successImageLabel'),
          optional: true,
        }}
        name="trigger.triggerIntegrationConfig.successImageFileKey"
        acceptHint={t('successImageAcceptHint')}
        assetKind="qrCodeSuccessImage"
        control={control}
        register={register}
      >
        <FileInputUploader />
      </FileInput>
      <LocalizedInput
        label={t('successMessageLabel')}
        description={t('successMessageDescription')}
        type="text"
        name="trigger.triggerIntegrationConfig.successMessage"
        required
        register={register}
        control={control}
        selectedLanguage={selectedLanguage}
      />
      <Separator />
      <StepContentInput />
    </Fragment>
  );
};

export default CohortScanQrCodeTriggerConfigComponent;
