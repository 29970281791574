import SpotifyPlayer from '@cohort/components-xps/components/apps/spotify/SpotifyPlayer';
import type {TriggerComponentPreviewProps} from '@cohort/merchants/apps';
import type {SpotifyFollowPlaylistTriggerStruct} from '@cohort/shared/apps/spotify/triggers/followPlaylist';

const SpotifyFollowPlaylistTriggerIntegrationComponentPreview: React.FC<
  TriggerComponentPreviewProps<SpotifyFollowPlaylistTriggerStruct>
> = ({config, isMobile}) => {
  if (config.playlistId.length < 1) {
    return null;
  }
  return (
    <div className={isMobile ? 'w-[350px]' : 'w-full'}>
      <SpotifyPlayer resourceId={config.playlistId} resourceType="playlist" size="lg" />
    </div>
  );
};

export default SpotifyFollowPlaylistTriggerIntegrationComponentPreview;
