import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import DescriptionModeSelect from '@cohort/merchants/apps/common/DescriptionModeSelect';
import StepTitleInput from '@cohort/merchants/apps/common/StepTitleInput';
import type {Defaults} from '@cohort/merchants/apps/common/triggers/utils';
import {parseDefaults} from '@cohort/merchants/apps/common/triggers/utils';
import {getDescriptionMode} from '@cohort/merchants/apps/common/triggers/utils';
import {useYoutubeVideos} from '@cohort/merchants/apps/youtube/actions';
import YoutubeVideoSelector from '@cohort/merchants/apps/youtube/triggers/YoutubeVideoSelector';
import ConnectionPicker from '@cohort/merchants/components/connections/ConnectionPicker';
import ErrorState from '@cohort/merchants/components/ErrorState';
import LanguageSelectorInput from '@cohort/merchants/components/form/LanguageSelectorInput';
import Loader from '@cohort/merchants/components/Loader';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {YoutubeCommentTriggerStruct} from '@cohort/shared/apps/youtube/triggers/comment';
import type {Language} from '@cohort/shared/schema/common';
import {Fragment, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const getDefaults = (definedLanguages: Language[]): Defaults => {
  const defaults = {
    title: {
      en: `Comment our Youtube video`,
      fr: `Commentez notre vidéo Youtube`,
      es: `Comenta nuestro video de Youtube`,
    },
    description: {},
  };
  return parseDefaults(defaults, definedLanguages);
};

const YoutubeCommentTriggerConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps<YoutubeCommentTriggerStruct>
> = ({formReturn: {setValue, control, watch, reset, register}, trigger, step}) => {
  const init = useRef(false);
  const merchant = useCurrentMerchant();

  const {t} = useTranslation('app-youtube', {
    keyPrefix: 'triggerIntegrations.comment.configComponent',
  });

  const [definedLanguages, connectionId, videoId] = watch([
    'definedLanguages',
    'trigger.connectionId',
    'trigger.triggerIntegrationConfig.videoId',
  ]);

  const {
    data: videosResponse,
    isLoading: isVideosLoading,
    isFetched: isVideosFetched,
  } = useYoutubeVideos(merchant.id, connectionId ?? '', undefined, {
    enabled: !!connectionId && init.current,
  });

  const videos = videosResponse?.output.videos ?? [];

  const onSelectVideo = (selectedMediaId: string): void => {
    const video = videos.find(video => video.id === selectedMediaId);
    if (video) {
      setValue('trigger.triggerIntegrationConfig.videoId', video.id);
      setValue('trigger.triggerIntegrationConfig.videoTitle', video.title);
    }
  };

  useEffect(() => {
    if (init.current) {
      const defaults = getDefaults(definedLanguages);
      setValue('defaultTitle', defaults.title);
      setValue('defaultDescription', defaults.description);
    }
  }, [definedLanguages, setValue]);

  useEffect(() => {
    if (init.current) {
      return;
    }
    const defaults = getDefaults(definedLanguages);
    reset(formValues => ({
      ...formValues,
      defaultTitle: defaults.title,
      defaultDescription: defaults.description,
      descriptionMode: getDescriptionMode(definedLanguages, defaults, step),
      title: step?.title ?? defaults.title,
      description: step?.description ?? defaults.description,
      icon: step?.icon !== undefined ? step.icon : '🗣️',
      media: null,
      trigger: {
        id: trigger?.id ?? null,
        connectionId: trigger?.connectionId ?? null,
        triggerIntegrationId: 'youtube.comment',
        triggerIntegrationConfig: {
          videoId: trigger?.triggerIntegrationConfig.videoId ?? '',
          videoTitle: trigger?.triggerIntegrationConfig.videoTitle ?? '',
        },
      },
    }));
    init.current = true;
  }, [definedLanguages, reset, step, trigger]);

  if (!init.current) {
    return null;
  }

  const videoSelector = match({
    connectionId,
    videos,
    isVideosLoading,
    isVideosFetched,
  })
    .with({connectionId: null}, () => null)
    .with({isVideosLoading: true}, () => <Loader size={30} color="gray" />)
    .with({videos: []}, () => <p>{t('noVideosMessage')}</p>)
    .with({isVideosFetched: true}, () => {
      const selectedVideo = videos.find(video => video.id === videoId);

      return (
        <YoutubeVideoSelector
          description={t('descriptionVideoSelect')}
          onSelect={onSelectVideo}
          title={t('titleVideoSelect')}
          selectedVideo={selectedVideo}
          videos={videos}
        />
      );
    })
    .otherwise(() => <ErrorState />);

  return (
    <Fragment>
      <div className="max-w-sm">
        <ConnectionPicker
          appId="youtube"
          control={control}
          register={register}
          connectionIdFieldName="trigger.connectionId"
        />
      </div>
      {videoSelector}
      <DescriptionModeSelect />
      <LanguageSelectorInput />
      <StepTitleInput />
    </Fragment>
  );
};

export default YoutubeCommentTriggerConfigComponent;
