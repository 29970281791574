import LocalizedTextEditorInput from '@cohort/merchants/components/form/textEditor/LocalizedTextEditorInput';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {CreateEditStepModalType} from '@cohort/merchants/pages/campaigns/campaign/edit/settings/challenge/utils';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Fragment} from 'react/jsx-runtime';
import {useTranslation} from 'react-i18next';

const StepDescriptionInput: React.FC = () => {
  const {t} = useTranslation('apps', {
    keyPrefix: 'common.stepDescriptionInput',
  });
  const {register, control, watch} = useCohortForm<CreateEditStepModalType>();

  const [selectedLanguage, descriptionMode, defaultDescription] = watch([
    'selectedLanguage',
    'descriptionMode',
    'defaultDescription',
  ]);

  return (
    <Fragment>
      <div className={cn('w-full', descriptionMode === 'custom' && 'hidden')}>
        <LocalizedTextEditorInput
          label={t('label')}
          name="defaultDescription"
          placeholder={defaultDescription[selectedLanguage] ?? undefined}
          register={register}
          control={control}
          selectedLanguage={selectedLanguage}
          optional
          disabled
        />
      </div>
      <div className={cn('w-full', descriptionMode !== 'custom' && 'hidden')}>
        <LocalizedTextEditorInput
          label={t('label')}
          name="description"
          placeholder={defaultDescription[selectedLanguage] ?? undefined}
          register={register}
          control={control}
          selectedLanguage={selectedLanguage}
          optional
        />
      </div>
    </Fragment>
  );
};
export default StepDescriptionInput;
