import type {PreviewCtaComponentProps} from '@cohort/merchants/apps';
import ActionCtaPreview from '@cohort/merchants/apps/common/ActionCtaPreview';
import {useTranslation} from 'react-i18next';

const CohortFormTriggerIntegrationActionCtaComponentPreview: React.FC<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PreviewCtaComponentProps<any>
> = ({localizationConfig}) => {
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'triggerIntegrations.common',
    lng: localizationConfig.desiredLanguage,
  });
  return <ActionCtaPreview text={t('ctaTitle')} />;
};

export default CohortFormTriggerIntegrationActionCtaComponentPreview;
