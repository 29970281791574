import Button from '@cohort/merchants/components/buttons/Button';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Popover, Transition} from '@headlessui/react';
import React from 'react';

type DropdownEntryProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  label: string | JSX.Element;
  icon: JSX.Element;
  subtitle?: string | JSX.Element;
  handleClick?: () => void;
  testId?: string;
};

export const DropdownEntry: React.FC<DropdownEntryProps> = ({
  label,
  icon,
  disabled = false,
  handleClick,
  subtitle,
  testId,
}) => (
  <button
    data-testid={testId}
    className={cn(
      'flex w-full items-center gap-2 text-nowrap px-4 py-2 text-left text-sm text-gray-700 hover:bg-slate-100',
      disabled && 'cursor-not-allowed opacity-50'
    )}
    onClick={e => {
      e.preventDefault();
      e.stopPropagation();
      handleClick?.();
    }}
    disabled={disabled}
  >
    <div className="flex items-start gap-2">
      {icon}
      <div className="flex flex-col gap-1">
        <p>{label}</p>
        {subtitle && <p className="text-sm text-gray-500">{subtitle}</p>}
      </div>
    </div>
  </button>
);

export type DropdownPanelAlign = 'left' | 'right' | 'center';

type DropdownPanelProps = {
  align?: DropdownPanelAlign;
} & React.ComponentProps<typeof Popover.Panel>;

export const DropdownPanel: React.FC<DropdownPanelProps> = ({
  children,
  className,
  align = 'right',
  ...props
}) => {
  return (
    <Transition
      className="relative z-30"
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Popover.Panel
        className={cn(
          'absolute mt-2 rounded-md border bg-white shadow-md [min-width:200px]',
          align === 'left' && 'left-0',
          align === 'right' && 'right-0',
          align === 'center' && 'left-1/2 -translate-x-1/2',
          className
        )}
        as="ul"
        {...props}
      >
        {children}
      </Popover.Panel>
    </Transition>
  );
};

type DropdownButtonProps = {
  variant: 'primary' | 'secondary';
} & React.ComponentProps<typeof Button>;

export const DropdownButton: React.FC<DropdownButtonProps> = ({children, variant, ...props}) => (
  <Popover.Button data-testid="dropdown-button" as="div">
    <Button variant={variant} {...props}>
      {children}
    </Button>
  </Popover.Button>
);

type DropdownProps = React.ComponentProps<typeof Popover.Panel>;

export const Dropdown: React.FC<DropdownProps> = ({className, children, ...props}) => (
  <Popover open className={cn('relative', className)} {...props}>
    {children}
  </Popover>
);

export default Dropdown;
