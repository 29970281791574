/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {GetContentsParamsADto} from '@cohort/admin-schemas/content';
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {getContent, getContents} from '@cohort/merchants/lib/api/Contents';
import {useInfiniteQuery} from '@tanstack/react-query';

type UseContentsWithInfiniteQueryParams = Partial<GetContentsParamsADto> & {pageSize: number};

export const contentsKeys = {
  contents: ['contents'] as const,
  list: (merchantId: string, params: GetContentsParamsADto | UseContentsWithInfiniteQueryParams) =>
    [...contentsKeys.contents, merchantId, 'list', params] as const,
  listInfiniteContents: (merchantId: string, params: UseContentsWithInfiniteQueryParams) =>
    [...contentsKeys.list(merchantId, params), 'infinite'] as const,
  getById: (merchantId: string, contentId: string) =>
    [...contentsKeys.contents, merchantId, contentId] as const,
};

export const useContents = (
  merchantId: string,
  params: GetContentsParamsADto,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: contentsKeys.list(merchantId, params),
    queryFn: async () => getContents(merchantId, params),
    ...options,
  });

export const useContent = (merchantId: string, contentId: string, options?: QueryOptions) =>
  useCohortQuery({
    queryKey: contentsKeys.getById(merchantId, contentId),
    queryFn: async () => getContent(merchantId, contentId),
    ...options,
  });

export const useContentsWithInfiniteQuery = (
  merchantId: string,
  params: UseContentsWithInfiniteQueryParams
) =>
  useInfiniteQuery({
    queryKey: contentsKeys.listInfiniteContents(merchantId, params),
    queryFn: async ({pageParam = 1}) => {
      const [pagination, data] = await getContents(merchantId, {
        ...params,
        page: pageParam,
        pageSize: params.pageSize,
        orderBy: params.orderBy || 'createdAt',
      });
      return {data, meta: pagination};
    },
    getNextPageParam: lastPage => {
      if (lastPage.meta.page === lastPage.meta.pages) {
        return undefined;
      }
      return lastPage.meta.page + 1;
    },
  });
