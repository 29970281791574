import CohortArticleVisualPreviewContent from '@cohort/components-xps/components/contents/apps/cohort/article/VisualPreviewContent';
import type {VisualPreviewProps} from '@cohort/merchants/apps';
import type {CohortArticleMediaStruct} from '@cohort/shared/apps/cohort/medias/article';

const CohortArticleVisualPreview: React.FC<VisualPreviewProps<CohortArticleMediaStruct>> = ({
  config,
  title,
  imageSize = 'XL',
  isMobile,
}) => (
  <CohortArticleVisualPreviewContent
    imageFileKey={config.imageFileKey}
    title={title}
    isMobile={isMobile}
    imageSize={imageSize}
  />
);

export default CohortArticleVisualPreview;
