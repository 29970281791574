import type {TriggerIntegration} from '@cohort/merchants/apps';
import CohortPerkUsedTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/cohort/triggerIntegrations/perkUsed/StepCompletionTitleComponent';
import type {CohortPerkUsedTriggerStruct} from '@cohort/shared/apps/cohort/triggers/perkUsed';
import {CohortPerkUsedTriggerIntegrationSpec} from '@cohort/shared/apps/cohort/triggers/perkUsed';

export const CohortPerkUsedTriggerIntegration: TriggerIntegration<CohortPerkUsedTriggerStruct> = {
  spec: CohortPerkUsedTriggerIntegrationSpec,
  stepCompletionTitleComponent: CohortPerkUsedTriggerIntegrationStepCompletionTitleComponent,
  getTitle: t => t('triggerIntegrations.perk-used.title', {ns: 'app-cohort'}),
};
