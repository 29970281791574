import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import UserPropertyInput from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/UserPropertyInput';
import StepContentInput from '@cohort/merchants/apps/common/StepContentInput';
import StepDescriptionInput from '@cohort/merchants/apps/common/StepDescriptionInput';
import StepTitleInput from '@cohort/merchants/apps/common/StepTitleInput';
import TriggerReferenceIdInput from '@cohort/merchants/apps/common/TriggerReferenceIdInput';
import type {Defaults} from '@cohort/merchants/apps/common/triggers/utils';
import {defaultMedia, parseDefaults} from '@cohort/merchants/apps/common/triggers/utils';
import LanguageSelectorInput from '@cohort/merchants/components/form/LanguageSelectorInput';
import Separator from '@cohort/merchants/components/Separator';
import type {CohortFormScoreTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/score';
import type {Language} from '@cohort/shared/schema/common';
import {Fragment, useEffect, useRef} from 'react';

const getDefaults = (definedLanguages: Language[]): Defaults => {
  const defaults = {
    title: {},
    description: {},
  };
  return parseDefaults(defaults, definedLanguages);
};

const CohortFormScoreTriggerConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps<CohortFormScoreTriggerStruct>
> = ({formReturn: {reset, watch}, step, trigger}) => {
  const init = useRef(false);

  const [definedLanguages] = watch(['definedLanguages']);

  useEffect(() => {
    if (init.current) {
      return;
    }
    const defaults = getDefaults(definedLanguages);
    reset(formValues => ({
      ...formValues,
      defaultTitle: defaults.title,
      defaultDescription: defaults.description,
      descriptionMode: 'custom',
      title: step?.title ?? {},
      description: step?.description ?? {},
      icon: step?.icon !== undefined ? step.icon : '⭐️',
      media: step?.media ?? defaultMedia,
      trigger: {
        id: trigger?.id ?? null,
        connectionId: null,
        triggerIntegrationId: 'cohort-form.score',
        triggerIntegrationConfig: {
          referenceId: trigger?.triggerIntegrationConfig.referenceId ?? null,
          userPropertyId: trigger?.triggerIntegrationConfig.userPropertyId ?? null,
          withUserProperty: !!trigger?.triggerIntegrationConfig.userPropertyId,
        },
      },
    }));
    init.current = true;
  }, [definedLanguages, reset, step, trigger]);

  if (!init.current) {
    return null;
  }

  return (
    <Fragment>
      <LanguageSelectorInput />
      <StepTitleInput />
      <StepDescriptionInput />
      <Separator />
      <StepContentInput />
      <Separator />
      <UserPropertyInput promptType="number" />
      <Separator />
      <TriggerReferenceIdInput />
    </Fragment>
  );
};

export default CohortFormScoreTriggerConfigComponent;
