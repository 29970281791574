import type {TriggerIntegration} from '@cohort/merchants/apps';
import useBeActiveStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useBeActiveStepCompletionColumns';
import GarminConnectBeActiveTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/garmin-connect/triggerIntegratons/beActive/StepCompletionTitleComponent';
import type {GarminConnectBeActiveTriggerStruct} from '@cohort/shared/apps/garmin-connect/triggers/beActive';
import {GarminConnectBeActiveTriggerIntegrationSpec} from '@cohort/shared/apps/garmin-connect/triggers/beActive';

export const GarminConnectConnectBeActiveTriggerIntegration: TriggerIntegration<GarminConnectBeActiveTriggerStruct> =
  {
    spec: GarminConnectBeActiveTriggerIntegrationSpec,
    stepCompletionTitleComponent:
      GarminConnectBeActiveTriggerIntegrationStepCompletionTitleComponent,
    useStepCompletionColumns: context =>
      useBeActiveStepCompletionColumns<GarminConnectBeActiveTriggerStruct>({
        ...context,
        userNameKey: 'garminUsername',
        metricKey: 'metric',
        thresholdValueKey: 'thresholdValue',
        cumulativeValueKey: 'cumulativeValue',
      }),
    getTitle: t => t('triggerIntegrations.be-active.title', {ns: 'app-garmin-connect'}),
  };
