import FacebookCommentPostTriggerIntegrationInstructionsComponent from '@cohort/components-xps/components/apps/facebook/triggers/commentPost/InstructionsComponent';
import type {TriggerIntegration} from '@cohort/merchants/apps';
import useSocialStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useSocialStepCompletionColumns';
import FacebookCommentPostTriggerActionCtaComponentPreview from '@cohort/merchants/apps/facebook/triggers/commentPost/ActionCtaComponentPreview';
import FacebookCommentPostTriggerConfigComponent from '@cohort/merchants/apps/facebook/triggers/commentPost/ConfigComponent';
import FacebookCommentPostTriggerIntegrationStepCompletionContextComponent from '@cohort/merchants/apps/facebook/triggers/commentPost/StepCompletionContextComponent';
import FacebookCommentPostTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/facebook/triggers/commentPost/StepCompletionTitleComponent';
import FacebookCommentPostTriggerIntegrationComponentPreview from '@cohort/merchants/apps/facebook/triggers/commentPost/TriggerComponentPreview';
import type {FacebookCommentPostTriggerStruct} from '@cohort/shared/apps/facebook/triggers/commentPost';
import {FacebookCommentPostTriggerSpec} from '@cohort/shared/apps/facebook/triggers/commentPost';

export const FacebookCommentPostTriggerIntegration: TriggerIntegration<FacebookCommentPostTriggerStruct> =
  {
    spec: FacebookCommentPostTriggerSpec,
    configComponent: FacebookCommentPostTriggerConfigComponent,
    stepCompletionTitleComponent: FacebookCommentPostTriggerIntegrationStepCompletionTitleComponent,
    stepCompletionContextComponent:
      FacebookCommentPostTriggerIntegrationStepCompletionContextComponent,
    useStepCompletionColumns: context =>
      useSocialStepCompletionColumns<FacebookCommentPostTriggerStruct>({
        ...context,
        userNameKey: 'username',
        getComment: data => data.comment?.message ?? null,
      }),
    getTitle: t => t('triggers.comment-post.title', {ns: 'app-facebook'}),
    preview: {
      InstructionsComponent: FacebookCommentPostTriggerIntegrationInstructionsComponent,
      CtaComponent: FacebookCommentPostTriggerActionCtaComponentPreview,
      TriggerComponent: FacebookCommentPostTriggerIntegrationComponentPreview,
    },
  };
