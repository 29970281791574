import type {PreviewCtaComponentProps} from '@cohort/merchants/apps';
import ActionCtaPreview from '@cohort/merchants/apps/common/ActionCtaPreview';
import type {CohortFormMediaTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/media';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const CohortFormMediaTriggerIntegrationActionCtaComponentPreview: React.FC<
  PreviewCtaComponentProps<CohortFormMediaTriggerStruct>
> = ({localizationConfig, config}) => {
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'triggerIntegrations.media.actionComponent',
    lng: localizationConfig.desiredLanguage,
  });
  const text = match(config.mediaType)
    .with('image', () => t('submitBtnImage'))
    .with('video', () => t('submitBtnVideo'))
    .with('imageOrVideo', () => t('submitBtnImageOrVideo'))
    .otherwise(() => t('submit'));

  return <ActionCtaPreview text={text} />;
};

export default CohortFormMediaTriggerIntegrationActionCtaComponentPreview;
