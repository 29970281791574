import SpotifyFollowPlaylistTriggerIntegrationInstructionsComponent from '@cohort/components-xps/components/apps/spotify/triggers/followPlaylist/InstructionsComponent';
import type {TriggerIntegration} from '@cohort/merchants/apps';
import useSocialStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useSocialStepCompletionColumns';
import SpotifyFollowPlaylistTriggerActionCtaComponentPreview from '@cohort/merchants/apps/spotify/triggers/followPlaylist/ActionCtaComponentPreview';
import SpotifyFollowPlaylistTriggerIntegrationConfigComponent from '@cohort/merchants/apps/spotify/triggers/followPlaylist/ConfigComponent';
import SpotifyFollowPlaylistTriggerIntegrationStepCompletionContextComponent from '@cohort/merchants/apps/spotify/triggers/followPlaylist/StepCompletionContextComponent';
import SpotifyFollowPlaylistTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/spotify/triggers/followPlaylist/StepCompletionTitleComponent';
import SpotifyFollowPlaylistTriggerIntegrationComponentPreview from '@cohort/merchants/apps/spotify/triggers/followPlaylist/TriggerComponentPreview';
import type {SpotifyFollowPlaylistTriggerStruct} from '@cohort/shared/apps/spotify/triggers/followPlaylist';
import {SpotifyFollowPlaylistTriggerSpec} from '@cohort/shared/apps/spotify/triggers/followPlaylist';

export const SpotifyFollowPlaylistTriggerIntegration: TriggerIntegration<SpotifyFollowPlaylistTriggerStruct> =
  {
    spec: SpotifyFollowPlaylistTriggerSpec,
    configComponent: SpotifyFollowPlaylistTriggerIntegrationConfigComponent,
    stepCompletionTitleComponent:
      SpotifyFollowPlaylistTriggerIntegrationStepCompletionTitleComponent,
    stepCompletionContextComponent:
      SpotifyFollowPlaylistTriggerIntegrationStepCompletionContextComponent,
    useStepCompletionColumns: context =>
      useSocialStepCompletionColumns<SpotifyFollowPlaylistTriggerStruct>({
        ...context,
        userNameKey: 'userName',
      }),
    getTitle: t => t('triggerIntegrations.follow-playlist.title', {ns: 'app-spotify'}),
    preview: {
      InstructionsComponent: SpotifyFollowPlaylistTriggerIntegrationInstructionsComponent,
      CtaComponent: SpotifyFollowPlaylistTriggerActionCtaComponentPreview,
      TriggerComponent: SpotifyFollowPlaylistTriggerIntegrationComponentPreview,
    },
  };
