import type {CohortADto} from '@cohort/admin-schemas/cohort';
import {ReferenceIdFormSchema} from '@cohort/admin-schemas/referenceId';
import Button from '@cohort/merchants/components/buttons/Button';
import Input from '@cohort/merchants/components/form/input/Input';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@cohort/merchants/components/modals/Dialog';
import {cohortsKeys} from '@cohort/merchants/hooks/api/Cohorts';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {notify} from '@cohort/merchants/hooks/toast';
import {patchCohort} from '@cohort/merchants/lib/api/Cohorts';
import {isCohortError} from '@cohort/shared/schema/common/errors';
import {zodResolver} from '@hookform/resolvers/zod';
import {useQueryClient} from '@tanstack/react-query';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

interface EditCohortReferenceIdModalProps {
  cohort: CohortADto;
  onClose: () => void;
}

export const CohortReferenceIdFormSchema = z.object({
  referenceId: ReferenceIdFormSchema,
});
type CohortReferenceIdFormValues = z.infer<typeof CohortReferenceIdFormSchema>;

const EditCohortReferenceIdModal: React.FC<EditCohortReferenceIdModalProps> = ({
  cohort,
  onClose,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const queryClient = useQueryClient();
  const {t} = useTranslation('components', {keyPrefix: 'modals.editCohortReferenceIdModal'});

  const {register, handleSubmit, control, setError} = useForm<CohortReferenceIdFormValues>({
    resolver: zodResolver(CohortReferenceIdFormSchema),
    defaultValues: {
      referenceId: cohort.referenceId,
    },
  });

  const {isLoading, mutate: createCohortMutation} = useCohortMutation({
    mutationFn: async (data: CohortReferenceIdFormValues) =>
      patchCohort(merchantId, cohort.id, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries(cohortsKeys.list(merchantId));
      queryClient.invalidateQueries(cohortsKeys.getById(merchantId, cohort.id));
      onClose();
    },
    onError: (err: Error) => {
      if (isCohortError(err, ['cohort.reference-id-already-exists'])) {
        setError('referenceId', {message: 'errorNotAvailable'});
        return;
      }
      notify('error', err.message);
    },
  });

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent className="[width:600px]">
        <form onSubmit={handleSubmit(data => createCohortMutation(data))}>
          <DialogHeader>
            <DialogTitle className="text-lg">{t('title')}</DialogTitle>
          </DialogHeader>
          <DialogBody className="space-y-6">
            <Input
              type="text"
              name="referenceId"
              autoFocus={true}
              register={register}
              control={control}
              label={t('labelReferenceId')}
              optional
            />
          </DialogBody>
          <DialogFooter>
            <Button variant="secondary" onClick={onClose}>
              {t('buttonCancel')}
            </Button>
            <Button type="submit" disabled={false} loading={isLoading}>
              {t('buttonSave')}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditCohortReferenceIdModal;
