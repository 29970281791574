import TikTokPostVisualPreviewContent from '@cohort/components-xps/components/contents/apps/tiktok/post/VisualPreviewContent';
import type {VisualPreviewProps} from '@cohort/merchants/apps';
import type {TikTokPostMediaStruct} from '@cohort/shared/apps/tiktok/medias/post';

const TikTokPostVisualPreview: React.FC<VisualPreviewProps<TikTokPostMediaStruct>> = ({
  config,
  title,
  imageSize = 'XL',
  isMobile,
}) => (
  <TikTokPostVisualPreviewContent
    imageSize={imageSize}
    imageFileKey={config.imageFileKey}
    title={title}
    isMobile={isMobile}
  />
);

export default TikTokPostVisualPreview;
