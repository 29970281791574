import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import StepContentInput from '@cohort/merchants/apps/common/StepContentInput';
import StepDescriptionInput from '@cohort/merchants/apps/common/StepDescriptionInput';
import StepTitleInput from '@cohort/merchants/apps/common/StepTitleInput';
import type {Defaults} from '@cohort/merchants/apps/common/triggers/utils';
import {defaultMedia, parseDefaults} from '@cohort/merchants/apps/common/triggers/utils';
import UrlInput from '@cohort/merchants/components/form/input/UrlInput';
import LanguageSelectorInput from '@cohort/merchants/components/form/LanguageSelectorInput';
import Separator from '@cohort/merchants/components/Separator';
import {useUrlMetadata} from '@cohort/merchants/hooks/api/Merchants';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {CohortVisitLinkTriggerStruct} from '@cohort/shared/apps/cohort/triggers/visitLink';
import type {Language} from '@cohort/shared/schema/common';
import {UrlInputSchema} from '@cohort/shared/schema/common/urls';
import {Fragment, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

const getDefaults = (definedLanguages: Language[]): Defaults => {
  const defaults = {
    title: {
      en: 'Visit the link',
      fr: 'Voir le lien',
      es: 'Visita el enlace',
    },
    description: {
      en: 'To complete this step, visit the page via the button below',
      fr: 'Pour valider cette étape, ouvrez la page via le bouton ci-dessous',
      es: 'Para completar este paso, visita la página a través del botón de abajo',
    },
  };
  return parseDefaults(defaults, definedLanguages);
};

const CohortVisitLinkTriggerConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps<CohortVisitLinkTriggerStruct>
> = ({formReturn: {control, register, reset, watch, setValue}, step, trigger}) => {
  const init = useRef(false);
  const merchant = useCurrentMerchant();

  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'triggerIntegrations.visit-link.configComponent',
  });

  const [debouncedUrl, setDebouncedUrl] = useState<string | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const definedLanguages = watch('definedLanguages');

  const {data: urlMetadata} = useUrlMetadata(merchant.id, debouncedUrl ?? '', {
    enabled: !!debouncedUrl,
  });

  useEffect(() => {
    if (init.current) {
      const defaults = getDefaults(definedLanguages);
      setValue('defaultTitle', defaults.title);
      setValue('defaultDescription', defaults.description);
    }
  }, [definedLanguages, setValue]);

  useEffect(() => {
    if (init.current) {
      return;
    }
    const defaults = getDefaults(definedLanguages);

    reset(formValues => ({
      ...formValues,
      defaultTitle: defaults.title,
      defaultDescription: defaults.description,
      descriptionMode: 'custom',
      title: step?.title ?? {},
      description: step?.description ?? {},
      icon: step?.icon !== undefined ? step.icon : '👁️',
      media: step?.media ?? defaultMedia,
      trigger: {
        id: trigger?.id ?? null,
        connectionId: null,
        triggerIntegrationId: 'cohort.visit-link',
        triggerIntegrationConfig: {
          url: trigger?.triggerIntegrationConfig.url ?? '',
          title: trigger?.triggerIntegrationConfig.title ?? undefined,
          imageUrl: trigger?.triggerIntegrationConfig.imageUrl ?? undefined,
        },
      },
    }));
    init.current = true;
  }, [definedLanguages, reset, step, trigger]);

  useEffect(() => {
    if (urlMetadata?.error === false) {
      setValue('trigger.triggerIntegrationConfig.title', urlMetadata.title ?? null);
      setValue('trigger.triggerIntegrationConfig.imageUrl', urlMetadata.image?.url ?? null);
    }
  }, [debouncedUrl, setValue, urlMetadata]);

  if (!init.current) {
    return null;
  }

  return (
    <Fragment>
      <UrlInput
        name="trigger.triggerIntegrationConfig.url"
        label={t('labelLink')}
        register={register}
        control={control}
        onChange={e => {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
          timeoutRef.current = setTimeout(() => {
            if (UrlInputSchema.safeParse(e.target.value).success) {
              setDebouncedUrl(e.target.value);
            }
          }, 200);
        }}
      />
      <LanguageSelectorInput />
      <StepTitleInput />
      <StepDescriptionInput />
      <Separator />
      <StepContentInput />
    </Fragment>
  );
};

export default CohortVisitLinkTriggerConfigComponent;
