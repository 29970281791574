import {Trans} from 'react-i18next';

const StepCompletionTitleComponent: React.FC = () => (
  <Trans
    // i18nOwl-ignore [app-cohort-form.triggerIntegrations.common.stepCompletionTitleComponent.success]
    i18nKey="triggerIntegrations.common.stepCompletionTitleComponent.success"
    ns="app-cohort-form"
  />
);

export default StepCompletionTitleComponent;
