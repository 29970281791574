import FieldWrapper from '@cohort/merchants/components/campaigns/preview/FieldWrapper';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Star} from '@phosphor-icons/react';
import {useState} from 'react';

type ScoreInputProps = {
  label?: string;
  mandatory?: boolean;
  width?: string;
  error?: JSX.Element;
  className?: string;
};

const SCORE_VALUES = [1, 2, 3, 4, 5];

export default function ScoreInputPreview({
  label,
  mandatory,
  width,
  error,
  className,
}: ScoreInputProps): JSX.Element {
  const [value, setValue] = useState<number>(0);

  return (
    <FieldWrapper className={width} label={label} mandatory={mandatory} error={error}>
      <div className={cn('flex gap-1', className)}>
        {SCORE_VALUES.map(val => (
          <button key={val} type="button" onClick={() => setValue(val)}>
            {val <= value ? (
              <Star className="h-6 w-6 text-yellow-400" weight="fill" />
            ) : (
              <Star className="h-6 w-6 text-[var(--xps-p-color)] opacity-30" weight="duotone" />
            )}
          </button>
        ))}
      </div>
    </FieldWrapper>
  );
}
