// i18nOwl-ignore [app-typeform.triggerIntegrations.take-quiz.stepCompletionTitleComponent.failedQuizTitle]
// i18nOwl-ignore [app-typeform.triggerIntegrations.take-quiz.stepCompletionTitleComponent.passingQuizTitle]
import type {TriggerIntegration} from '@cohort/merchants/apps';
import TypeformTakeQuizTriggerIntegrationConfigComponent from '@cohort/merchants/apps/typeform/triggerIntegrations/quiz/ConfigComponent';
import TypeformTakeQuizTriggerIntegrationStepCompletionContextComponent from '@cohort/merchants/apps/typeform/triggerIntegrations/quiz/StepCompletionContextComponent';
import TypeformTakeQuizTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/typeform/triggerIntegrations/quiz/StepCompletionTitleComponent';
import TypeformTakeQuizTriggerIntegrationComponentPreview from '@cohort/merchants/apps/typeform/triggerIntegrations/quiz/TriggerComponentPreview';
import useTypeformStepCompletionColumns from '@cohort/merchants/apps/typeform/triggerIntegrations/useTypeformStepCompletionColumns';
import type {TypeformTakeQuizTriggerStruct} from '@cohort/shared/apps/typeform/triggers/takeQuiz';
import {TypeformTakeQuizTriggerIntegrationSpec} from '@cohort/shared/apps/typeform/triggers/takeQuiz';

export const TypeformTakeQuizTriggerIntegration: TriggerIntegration<TypeformTakeQuizTriggerStruct> =
  {
    spec: TypeformTakeQuizTriggerIntegrationSpec,
    configComponent: TypeformTakeQuizTriggerIntegrationConfigComponent,
    stepCompletionTitleComponent: TypeformTakeQuizTriggerIntegrationStepCompletionTitleComponent,
    stepCompletionContextComponent:
      TypeformTakeQuizTriggerIntegrationStepCompletionContextComponent,
    useStepCompletionColumns: context =>
      useTypeformStepCompletionColumns<TypeformTakeQuizTriggerStruct>({
        ...context,
        formIdConfigKey: 'formId',
        questionsWithAnswersKey: 'questionsWithAnswers',
        userScoreKey: 'userScore',
        minScoreKey: 'minScore',
      }),
    getTitle: t => t('triggerIntegrations.take-quiz.title', {ns: 'app-typeform'}),
    preview: {
      TriggerComponent: TypeformTakeQuizTriggerIntegrationComponentPreview,
    },
  };
