import Title from '@cohort/components-xps/components/Title';
import type {TriggerComponentPreviewProps} from '@cohort/merchants/apps';
import CheckboxInputPreview from '@cohort/merchants/components/campaigns/preview/CheckboxInputPreview';
import type {CohortFormCheckboxTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/checkbox';
import {getLocalizedContent} from '@cohort/shared/utils/localization';

const CohortFormCheckboxTriggerIntegrationComponentPreview: React.FC<
  TriggerComponentPreviewProps<CohortFormCheckboxTriggerStruct>
> = ({config, localizationConfig}) => {
  const checkboxLabel = getLocalizedContent(config.checkboxText, localizationConfig);

  return (
    <div className="flex w-full justify-center gap-x-4">
      <CheckboxInputPreview />
      <Title className="w-fit text-xl">{checkboxLabel}</Title>
    </div>
  );
};

export default CohortFormCheckboxTriggerIntegrationComponentPreview;
