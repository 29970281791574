import type {TriggerIntegration} from '@cohort/merchants/apps';
import CohortFormTriggerIntegrationActionCtaComponentPreview from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/ActionCtaComponentPreview';
import StepCompletionContextComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/StepCompletionContextComponent';
import StepCompletionsGridComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/StepCompletionsGridComponent';
import StepCompletionTitleComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/StepCompletionTitleComponent';
import useStepCompletionColumns from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/useStepCompletionColumns';
import CohortFormEmailTriggerConfigComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/email/ConfigComponent';
import CohortFormEmailTriggerIntegrationComponentPreview from '@cohort/merchants/apps/cohort-form/triggerIntegrations/email/TriggerComponentPreview';
import type {CohortFormEmailTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/email';
import {CohortFormEmailTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/email';

export const CohortFormEmailTriggerIntegration: TriggerIntegration<CohortFormEmailTriggerStruct> = {
  spec: CohortFormEmailTriggerIntegrationSpec,
  configComponent: CohortFormEmailTriggerConfigComponent,
  stepCompletionTitleComponent: StepCompletionTitleComponent,
  stepCompletionContextComponent: StepCompletionContextComponent,
  useStepCompletionColumns: useStepCompletionColumns,
  stepCompletionsGridComponent: StepCompletionsGridComponent,
  getTitle: t => t('triggerIntegrations.email.title', {ns: 'app-cohort-form'}),
  preview: {
    CtaComponent: CohortFormTriggerIntegrationActionCtaComponentPreview,
    TriggerComponent: CohortFormEmailTriggerIntegrationComponentPreview,
  },
};
