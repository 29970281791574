import type {StepCompletionAttemptADto} from '@cohort/admin-schemas/challengeParticipation';
import type {
  UserEvent,
  UserEventDetailComponentProps,
  UserEventDetailModalComponentProps,
} from '@cohort/merchants/apps';
import {formatDetailComponentBoldText} from '@cohort/merchants/apps/cohort/utils/format';
import {StepCompletion} from '@cohort/merchants/components/campaigns/challenges/StepCompletion';
import Loader from '@cohort/merchants/components/Loader';
import {useChallengeParticipation} from '@cohort/merchants/hooks/api/ChallengeParticipations';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {getCampaignRoute} from '@cohort/merchants/lib/Pages';
import type {CohortChallengeStepCompletedEventStruct} from '@cohort/shared/apps/cohort/userEvents/challenge/challengeStepCompleted';
import {CohortChallengeStepCompletedEventSpec} from '@cohort/shared/apps/cohort/userEvents/challenge/challengeStepCompleted';
import {Trans, useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const DetailComponent: React.FC<UserEventDetailComponentProps> = ({userEvent}) => {
  const campaignId = (userEvent.properties as CohortChallengeStepCompletedEventStruct['Properties'])
    .campaignId;
  const campaignInternalName = userEvent.campaignInternalName;
  const navigate = useNavigate();
  return (
    <Trans
      i18nKey="userEvents.challenge.step-completed.detail"
      ns="app-cohort"
      values={{
        campaignInternalName,
      }}
      components={{
        bold: formatDetailComponentBoldText(userEvent, () =>
          navigate(getCampaignRoute(campaignId).path)
        ),
      }}
    />
  );
};

const DetailModalComponent: React.FC<UserEventDetailModalComponentProps> = ({userEvent}) => {
  const properties = userEvent.properties as CohortChallengeStepCompletedEventStruct['Properties'];
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('pages', {
    keyPrefix: 'campaigns.campaign.activity.challenge.challengeParticipationsStep',
  });

  const {data: challengeParticipation} = useChallengeParticipation(
    merchant.id,
    properties.challengeParticipationId
  );

  if (challengeParticipation === undefined) {
    return <Loader />;
  }

  const challengeCompletionAttempt = challengeParticipation.stepCompletionAttempts.find(
    (attempt: StepCompletionAttemptADto) => attempt.id === properties.challengeStepCompletionId
  );
  if (challengeCompletionAttempt === undefined) {
    throw new Error('Challenge completion attempt not found');
  }

  const challengeStep = challengeParticipation.challengeSteps.find(
    step => step.id === challengeCompletionAttempt.stepId
  );
  const challengeStepTrigger = challengeStep?.triggers.find(
    trigger => trigger.triggerIntegrationId === challengeCompletionAttempt.triggerIntegrationId
  );

  const isManuallyCompleted = challengeCompletionAttempt.manual === true;

  if (!challengeStep || !challengeStepTrigger) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-6">
      {isManuallyCompleted ? (
        <div className="rounded-md border border-slate-200 bg-slate-50 p-4 text-sm text-slate-700">
          {t('stepManualyCompleted')}
        </div>
      ) : (
        <StepCompletion
          step={challengeStep}
          stepTrigger={challengeStepTrigger}
          attempt={challengeCompletionAttempt}
          displayDate={false}
        />
      )}
    </div>
  );
};

export const CohortChallengeStepCompletedUserEvent: UserEvent<CohortChallengeStepCompletedEventStruct> =
  {
    spec: CohortChallengeStepCompletedEventSpec,
    detailComponent: DetailComponent,
    detailModalComponent: DetailModalComponent,
  };
