import type {PreviewCtaComponentProps} from '@cohort/merchants/apps';
import ActionCtaPreview from '@cohort/merchants/apps/common/ActionCtaPreview';
import type {InstagramFollowTriggerStruct} from '@cohort/shared/apps/instagram/triggers/follow';
import {useTranslation} from 'react-i18next';

const InstagramFollowTriggerActionCtaComponentPreview: React.FC<
  PreviewCtaComponentProps<InstagramFollowTriggerStruct>
> = ({localizationConfig}) => {
  const {t} = useTranslation('app-instagram', {
    keyPrefix: 'triggerIntegrations.follow',
    lng: localizationConfig.desiredLanguage,
  });
  return <ActionCtaPreview text={t('ctaTitle')} />;
};

export default InstagramFollowTriggerActionCtaComponentPreview;
