import type {PreviewCtaComponentProps} from '@cohort/merchants/apps';
import ButtonPreview from '@cohort/merchants/components/campaigns/preview/ButtonPreview';
import type {CohortSwipeCardsTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/swipeCards';
import {getLocalizedContent} from '@cohort/shared/utils/localization';
import {cn} from '@cohort/shared-frontend/utils/classNames';

const CohortFormSwipeCardsIntegrationActionCtaComponentPreview: React.FC<
  PreviewCtaComponentProps<CohortSwipeCardsTriggerStruct>
> = ({localizationConfig, config}) => {
  const leftBtnLabel = getLocalizedContent(config.leftBtnLabel, localizationConfig);
  const rightBtnLabel = getLocalizedContent(config.rightBtnLabel, localizationConfig);
  return (
    <div className="flex w-full justify-center gap-2">
      <ButtonPreview
        variant="secondary"
        className={cn('w-full transition-colors active:bg-[--xps-primary-btn-background-color]')}
      >
        {leftBtnLabel}
      </ButtonPreview>
      <ButtonPreview
        variant="secondary"
        className={cn('w-full transition-colors active:bg-[--xps-primary-btn-background-color]')}
      >
        {rightBtnLabel}
      </ButtonPreview>
    </div>
  );
};

export default CohortFormSwipeCardsIntegrationActionCtaComponentPreview;
