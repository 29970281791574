import CohortImageVisualPreviewContent from '@cohort/components-xps/components/contents/apps/cohort/image/VisualPreviewContent';
import type {VisualPreviewProps} from '@cohort/merchants/apps';
import type {CohortImageMediaStruct} from '@cohort/shared/apps/cohort/medias/image';

const CohortImageVisualPreview: React.FC<VisualPreviewProps<CohortImageMediaStruct>> = ({
  config,
  title,
  imageSize = 'XL',
}) => (
  <CohortImageVisualPreviewContent
    imageSize={imageSize}
    imageFileKey={config.imageFileKey}
    title={title}
  />
);

export default CohortImageVisualPreview;
