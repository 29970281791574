import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import DescriptionModeSelect from '@cohort/merchants/apps/common/DescriptionModeSelect';
import StepTitleInput from '@cohort/merchants/apps/common/StepTitleInput';
import type {Defaults} from '@cohort/merchants/apps/common/triggers/utils';
import {parseDefaults} from '@cohort/merchants/apps/common/triggers/utils';
import {getDescriptionMode} from '@cohort/merchants/apps/common/triggers/utils';
import {useInstagramMedias} from '@cohort/merchants/apps/instagram/actions';
import InstagramMediaSelector from '@cohort/merchants/apps/instagram/triggerIntegrations/components/InstagramMediaSelector';
import ConnectionPicker from '@cohort/merchants/components/connections/ConnectionPicker';
import ErrorState from '@cohort/merchants/components/ErrorState';
import LanguageSelectorInput from '@cohort/merchants/components/form/LanguageSelectorInput';
import Loader from '@cohort/merchants/components/Loader';
import {useConnectionProfile} from '@cohort/merchants/hooks/api/Connections';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {InstagramMerchantConnectorStruct} from '@cohort/shared/apps/instagram/merchantConnector';
import type {InstagramCommentMediaTriggerStruct} from '@cohort/shared/apps/instagram/triggers/commentMedia';
import type {Language} from '@cohort/shared/schema/common';
import {Fragment, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const getDefaults = (definedLanguages: Language[]): Defaults => {
  const defaults = {
    title: {
      en: `Comment our Instagram post`,
      fr: `Commentez notre publication Instagram`,
      es: `Comenta nuestra publicación de Instagram`,
    },
    description: {},
  };
  return parseDefaults(defaults, definedLanguages);
};

const InstagramCommentMediaTriggerConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps<InstagramCommentMediaTriggerStruct>
> = ({formReturn: {watch, setValue, reset, control, register}, trigger, step}) => {
  const init = useRef(false);
  const merchant = useCurrentMerchant();

  const {t} = useTranslation('app-instagram', {
    keyPrefix: 'triggerIntegrations.comment-media.configComponent',
  });

  const [definedLanguages, connectionId, mediaId] = watch([
    'definedLanguages',
    'trigger.connectionId',
    'trigger.triggerIntegrationConfig.mediaId',
  ]);

  const {data: profile} = useConnectionProfile<InstagramMerchantConnectorStruct>(
    merchant.id,
    connectionId ?? '',
    {enabled: !!connectionId && init.current}
  );

  const {
    data: mediasResponse,
    isLoading: isMediasLoading,
    isFetched: isMediasFetched,
  } = useInstagramMedias(merchant.id, connectionId ?? '', {enabled: !!connectionId});

  const medias = mediasResponse?.output.medias ?? [];
  const sortedMedias = medias.sort(media => (media.isCommentEnabled ? -1 : 1));

  const onSelectMedia = (mediaId: string): void => {
    const media = sortedMedias.find(media => media.id === mediaId);
    if (media) {
      setValue('trigger.triggerIntegrationConfig.mediaId', mediaId);
      setValue('trigger.triggerIntegrationConfig.mediaPermalink', media.permalink);
    }
  };

  useEffect(() => {
    if (profile) {
      setValue('trigger.triggerIntegrationConfig.merchantUsername', profile.properties.username);
    }
  }, [setValue, profile]);

  useEffect(() => {
    if (init.current) {
      const defaults = getDefaults(definedLanguages);
      setValue('defaultTitle', defaults.title);
      setValue('defaultDescription', defaults.description);
    }
  }, [definedLanguages, setValue]);

  useEffect(() => {
    if (init.current) {
      return;
    }
    const defaults = getDefaults(definedLanguages);
    reset(formValues => ({
      ...formValues,
      defaultTitle: defaults.title,
      defaultDescription: defaults.description,
      descriptionMode: getDescriptionMode(definedLanguages, defaults, step),
      title: step?.title ?? defaults.title,
      description: step?.description ?? defaults.description,
      icon: step?.icon !== undefined ? step.icon : '🗣️',
      media: null,
      trigger: {
        id: trigger?.id ?? null,
        connectionId: trigger?.connectionId ?? null,
        triggerIntegrationId: 'instagram.comment-media',
        triggerIntegrationConfig: {
          mediaId: trigger?.triggerIntegrationConfig.mediaId ?? '',
          mediaPermalink: trigger?.triggerIntegrationConfig.mediaPermalink ?? '',
          merchantUsername: trigger?.triggerIntegrationConfig.merchantUsername ?? '',
        },
      },
    }));
    init.current = true;
  }, [definedLanguages, profile, reset, step, trigger]);

  if (!init.current) {
    return null;
  }

  const mediaSelector = match({
    connectionId,
    sortedMedias,
    isMediasLoading,
    isMediasFetched,
  })
    .with({connectionId: null}, () => null)
    .with({isMediasLoading: true}, () => <Loader size={30} color="gray" />)
    .with({sortedMedias: []}, () => <p>{t('noMediasMessage')}</p>)
    .with({isMediasFetched: true}, () => {
      const selectedMedia = medias.find(media => media.id === mediaId);
      return (
        <InstagramMediaSelector
          description={t('descriptionMediaSelect')}
          onSelectMedia={onSelectMedia}
          title={t('titleMediaSelect')}
          selectedMedia={selectedMedia}
          medias={medias}
        />
      );
    })
    .otherwise(() => <ErrorState />);

  return (
    <Fragment>
      <div className="max-w-sm">
        <ConnectionPicker
          appId="instagram"
          control={control}
          register={register}
          connectionIdFieldName="trigger.connectionId"
        />
      </div>
      {mediaSelector}
      <DescriptionModeSelect />
      <LanguageSelectorInput />
      <StepTitleInput />
    </Fragment>
  );
};

export default InstagramCommentMediaTriggerConfigComponent;
