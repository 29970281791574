import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import UserPropertyInput from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/UserPropertyInput';
import PictureChoiceFormSection from '@cohort/merchants/apps/cohort-form/triggerIntegrations/pictureChoice/PictureChoiceFormSection';
import StepDescriptionInput from '@cohort/merchants/apps/common/StepDescriptionInput';
import StepTitleInput from '@cohort/merchants/apps/common/StepTitleInput';
import TriggerReferenceIdInput from '@cohort/merchants/apps/common/TriggerReferenceIdInput';
import type {Defaults} from '@cohort/merchants/apps/common/triggers/utils';
import {parseDefaults} from '@cohort/merchants/apps/common/triggers/utils';
import LanguageSelectorInput from '@cohort/merchants/components/form/LanguageSelectorInput';
import Separator from '@cohort/merchants/components/Separator';
import type {CohortFormPictureChoiceTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/pictureChoice';
import type {Language} from '@cohort/shared/schema/common';
import {Fragment, useEffect, useRef} from 'react';
import {isNumber} from 'remeda';

const getDefaults = (definedLanguages: Language[]): Defaults => {
  const defaults = {
    title: {},
    description: {},
  };
  return parseDefaults(defaults, definedLanguages);
};

const CohortFormPictureChoiceTriggerConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps<CohortFormPictureChoiceTriggerStruct>
> = ({formReturn: {reset, setFormAssets, watch}, step, trigger}) => {
  const init = useRef(false);

  const [definedLanguages, multipleChoice] = watch([
    'definedLanguages',
    'trigger.triggerIntegrationConfig.multipleChoice',
  ]);

  useEffect(() => {
    if (init.current) {
      return;
    }
    const defaults = getDefaults(definedLanguages);
    reset(formValues => ({
      ...formValues,
      defaultTitle: defaults.title,
      defaultDescription: defaults.description,
      descriptionMode: 'custom',
      title: step?.title ?? {},
      description: step?.description ?? {},
      icon: step?.icon !== undefined ? step.icon : '❤️',
      media: null,
      trigger: {
        id: trigger?.id ?? null,
        connectionId: null,
        triggerIntegrationId: 'cohort-form.picture-choice',
        triggerIntegrationConfig: {
          referenceId: trigger?.triggerIntegrationConfig.referenceId ?? null,
          multipleChoice: trigger?.triggerIntegrationConfig.multipleChoice ?? false,
          maxMultipleChoicesType: isNumber(trigger?.triggerIntegrationConfig.maxMultipleChoices)
            ? 'exact-number'
            : 'unlimited',
          maxMultipleChoices: trigger?.triggerIntegrationConfig.maxMultipleChoices ?? null,
          showLabels: trigger?.triggerIntegrationConfig.showLabels ?? false,
          pictureChoiceOptions: trigger?.triggerIntegrationConfig.pictureChoiceOptions ?? [],
          userPropertyId: trigger?.triggerIntegrationConfig.userPropertyId ?? null,
          withUserProperty: !!trigger?.triggerIntegrationConfig.userPropertyId,
        },
      },
    }));
    setFormAssets([
      {
        name: 'trigger.triggerIntegrationConfig.pictureChoiceOptions',
        nestedName: 'imageFileKey',
        type: 'pictureChoicePromptImage',
      },
    ]);
    init.current = true;
  }, [definedLanguages, reset, setFormAssets, step, trigger]);

  if (!init.current) {
    return null;
  }

  return (
    <Fragment>
      <LanguageSelectorInput />
      <StepTitleInput />
      <StepDescriptionInput />
      <PictureChoiceFormSection />
      <Separator />
      <UserPropertyInput promptType="picture-choice" multipleChoice={multipleChoice} />
      <Separator />
      <TriggerReferenceIdInput />
    </Fragment>
  );
};

export default CohortFormPictureChoiceTriggerConfigComponent;
