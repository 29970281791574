import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import DescriptionModeSelect from '@cohort/merchants/apps/common/DescriptionModeSelect';
import StepTitleInput from '@cohort/merchants/apps/common/StepTitleInput';
import type {Defaults} from '@cohort/merchants/apps/common/triggers/utils';
import {parseDefaults} from '@cohort/merchants/apps/common/triggers/utils';
import {getDescriptionMode} from '@cohort/merchants/apps/common/triggers/utils';
import BaseTikTokSelectPostTriggerIntegrationConfig from '@cohort/merchants/apps/tiktok/triggers/BaseTikTokSelectPostTriggerIntegrationConfig';
import ConnectionPicker from '@cohort/merchants/components/connections/ConnectionPicker';
import LanguageSelectorInput from '@cohort/merchants/components/form/LanguageSelectorInput';
import type {TikTokLikeTiktokTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/likeTiktok';
import type {Language} from '@cohort/shared/schema/common';
import {Fragment, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';

const getDefaults = (definedLanguages: Language[]): Defaults => {
  const defaults = {
    title: {
      en: `Like our TikTok post`,
      fr: `Aimer notre publication TikTok`,
      es: `Dale me gusta a nuestra publicación de TikTok`,
    },
    description: {},
  };
  return parseDefaults(defaults, definedLanguages);
};

const TikTokLikeTiktokTriggerConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps<TikTokLikeTiktokTriggerStruct>
> = ({formReturn: {setValue, control, watch, reset, register}, trigger, step}) => {
  const init = useRef(false);

  const {t} = useTranslation('app-tiktok', {
    keyPrefix: 'triggers.like-tiktok.configComponent',
  });

  const [definedLanguages, connectionId, videoId] = watch([
    'definedLanguages',
    'trigger.connectionId',
    'trigger.triggerIntegrationConfig.videoId',
  ]);

  useEffect(() => {
    if (init.current) {
      const defaults = getDefaults(definedLanguages);
      setValue('defaultTitle', defaults.title);
      setValue('defaultDescription', defaults.description);
    }
  }, [definedLanguages, setValue]);

  useEffect(() => {
    if (init.current) {
      return;
    }
    const defaults = getDefaults(definedLanguages);
    reset(formValues => ({
      ...formValues,
      defaultTitle: defaults.title,
      defaultDescription: defaults.description,
      descriptionMode: getDescriptionMode(definedLanguages, defaults, step),
      title: step?.title ?? defaults.title,
      description: step?.description ?? defaults.description,
      icon: step?.icon !== undefined ? step.icon : '👍',
      media: null,
      trigger: {
        id: trigger?.id ?? null,
        connectionId: trigger?.connectionId ?? null,
        triggerIntegrationId: 'tiktok.like-tiktok',
        triggerIntegrationConfig: {
          url: trigger?.triggerIntegrationConfig.url ?? '',
          videoId: trigger?.triggerIntegrationConfig.videoId ?? '',
        },
      },
    }));
    init.current = true;
  }, [definedLanguages, reset, step, trigger]);

  if (!init.current) {
    return null;
  }

  return (
    <Fragment>
      <div className="max-w-sm">
        <ConnectionPicker
          appId="tiktok"
          control={control}
          register={register}
          connectionIdFieldName="trigger.connectionId"
        />
      </div>
      {!!connectionId && (
        <BaseTikTokSelectPostTriggerIntegrationConfig
          videoId={videoId}
          connectionId={connectionId}
          onSelectVideo={({videoId, url}) => {
            setValue('trigger.triggerIntegrationConfig.videoId', videoId);
            setValue('trigger.triggerIntegrationConfig.url', url);
          }}
          description={t('descriptionVideoSelect')}
          title={t('titleVideoSelect')}
          noVideosMessage={t('noVideosMessage')}
          unverifiedInfo={t('unverifiedInfo')}
        />
      )}
      <DescriptionModeSelect />
      <LanguageSelectorInput />
      <StepTitleInput />
    </Fragment>
  );
};

export default TikTokLikeTiktokTriggerConfigComponent;
