import type {TriggerComponentPreviewProps} from '@cohort/merchants/apps';
import type {CohortFormMediaTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/media';
import {getLocalizedContentOrThrow} from '@cohort/shared/utils/localization';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {CameraPlus, Image, VideoCamera} from '@phosphor-icons/react';
import {get} from 'lodash';

const UPLOAD_ICON = {
  image: <Image size={24} className="text-[--xps-p-color]" />,
  video: <VideoCamera size={24} className="text-[--xps-p-color]" />,
  imageOrVideo: <CameraPlus size={24} className="text-[--xps-p-color]" />,
};

const Label = {
  image: {
    en: 'Upload image',
    fr: 'Télécharger une image',
    es: 'Cargar imagen',
  },
  video: {
    en: 'Upload video',
    fr: 'Télécharger une vidéo',
    es: 'Cargar imagen o video',
  },
  imageOrVideo: {
    en: 'Upload image or video',
    fr: 'Télécharger une image ou une vidéo',
    es: 'Cargar video',
  },
};

const CohortFormMediaTriggerIntegrationComponentPreview: React.FC<
  TriggerComponentPreviewProps<CohortFormMediaTriggerStruct>
> = ({config, localizationConfig, isMobile, data}) => {
  const mediaType = config.mediaType;

  const getUploadLabel = (): string => {
    if (mediaType === 'image') {
      return getLocalizedContentOrThrow(Label.image, localizationConfig);
    }
    if (mediaType === 'video') {
      return getLocalizedContentOrThrow(Label.video, localizationConfig);
    }
    return getLocalizedContentOrThrow(Label.imageOrVideo, localizationConfig);
  };

  const buttonIcon = get(UPLOAD_ICON, mediaType);

  if (data.media !== null && data.media.kind !== 'no-media') {
    return null;
  }

  return (
    <div
      className={cn(
        'border-slate relative flex min-h-[120px] justify-center rounded-md border-2 border-dashed bg-[--xps-input-background-color] text-center text-slate-400',
        isMobile ? 'size-[350px]' : 'size-[480px]'
      )}
    >
      <div className="flex w-full flex-col items-center justify-center">
        <div
          className={cn(
            'relative flex w-full flex-col items-center space-y-1 rounded-md p-5 ring-primary'
          )}
        >
          <input
            type="file"
            disabled
            className="absolute left-0 top-0 h-full w-full opacity-0 ring-opacity-100"
          />
          {buttonIcon}
          <p>{getUploadLabel()}</p>
        </div>
      </div>
    </div>
  );
};

export default CohortFormMediaTriggerIntegrationComponentPreview;
