import SpotifyPlaylistVisualPreviewContent from '@cohort/components-xps/components/contents/apps/spotify/playlist/VisualPreviewContent';
import type {VisualPreviewProps} from '@cohort/merchants/apps';
import type {SpotifyPlaylistMediaStruct} from '@cohort/shared/apps/spotify/medias/playlist';

const SpotifyPlaylistVisualPreview: React.FC<VisualPreviewProps<SpotifyPlaylistMediaStruct>> = ({
  config,
  title,
}) => (
  <SpotifyPlaylistVisualPreviewContent thumbnailUrl={config.playlistThumbnailUrl} title={title} />
);

export default SpotifyPlaylistVisualPreview;
