import type {TriggerIntegrationUseStepCompletionColumns} from '@cohort/merchants/apps';
import {CohortFormAnswer} from '@cohort/merchants/apps/cohort-form/components/CohortFormAnswer';
import {
  promptTypeFromIntegrationId,
  VerificationDataSchema,
} from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/utils';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';

const useStepCompletionColumns: TriggerIntegrationUseStepCompletionColumns<
  TriggerIntegrationStruct
> = ({step, stepTrigger, columnHelper}) => {
  const merchant = useCurrentMerchant();
  const promptType = promptTypeFromIntegrationId(stepTrigger.triggerIntegrationId);
  if (!promptType) {
    return [];
  }
  return [
    columnHelper.accessor(row => row.data, {
      id: stepTrigger.id,
      header: () => (
        <p className="line-clamp-1" title={step.title[merchant.defaultLanguage] ?? ''}>
          {step.title[merchant.defaultLanguage]}
        </p>
      ),
      cell: data => {
        const value = VerificationDataSchema.parse(data.getValue());
        return (
          <CohortFormAnswer promptType={promptType} response={value.answer} mediaViewMode="modal" />
        );
      },
      enableSorting: false,
    }),
  ];
};
export default useStepCompletionColumns;
