import type {PreviewCtaComponentProps} from '@cohort/merchants/apps';
import ActionCtaPreview from '@cohort/merchants/apps/common/ActionCtaPreview';
import type {CohortScanQrCodeTriggerStruct} from '@cohort/shared/apps/cohort/triggers/scanQrCode';
import {useTranslation} from 'react-i18next';

const CohortScanQrCodeTriggerIntegrationActionCtaComponentPreview: React.FC<
  PreviewCtaComponentProps<CohortScanQrCodeTriggerStruct>
> = ({localizationConfig}) => {
  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'triggerIntegrations.scan-qr-code',
    lng: localizationConfig.desiredLanguage,
  });
  return <ActionCtaPreview text={t('ctaTitle')} />;
};

export default CohortScanQrCodeTriggerIntegrationActionCtaComponentPreview;
