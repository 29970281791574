import type {TriggerComponentPreviewProps} from '@cohort/merchants/apps';
import InputPreview from '@cohort/merchants/components/campaigns/preview/InputPreview';
import type {CohortFormEmailTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/email';
import {getLocalizedContentOrThrow} from '@cohort/shared/utils/localization';

const placeholders = {
  en: 'Enter your email here',
  fr: 'Entrez votre email ici',
  es: 'Escribe tu email aquí',
};

const CohortFormEmailTriggerIntegrationComponentPreview: React.FC<
  TriggerComponentPreviewProps<CohortFormEmailTriggerStruct>
> = ({localizationConfig}) => {
  const placeholder = getLocalizedContentOrThrow(placeholders, localizationConfig);

  return <InputPreview width="w-[350px]" type="email" placeholder={placeholder} />;
};

export default CohortFormEmailTriggerIntegrationComponentPreview;
