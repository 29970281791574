import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import DescriptionModeSelect from '@cohort/merchants/apps/common/DescriptionModeSelect';
import StepContentInput from '@cohort/merchants/apps/common/StepContentInput';
import StepTitleInput from '@cohort/merchants/apps/common/StepTitleInput';
import type {Defaults} from '@cohort/merchants/apps/common/triggers/utils';
import {defaultMedia, parseDefaults} from '@cohort/merchants/apps/common/triggers/utils';
import {getDescriptionMode} from '@cohort/merchants/apps/common/triggers/utils';
import ConnectionPicker from '@cohort/merchants/components/connections/ConnectionPicker';
import Input from '@cohort/merchants/components/form/input/Input';
import LanguageSelectorInput from '@cohort/merchants/components/form/LanguageSelectorInput';
import Separator from '@cohort/merchants/components/Separator';
import type {TikTokKeywordInDescriptionTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/keywordInDescription';
import type {Language} from '@cohort/shared/schema/common';
import {Fragment, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';

const getDefaults = (definedLanguages: Language[]): Defaults => {
  const defaults = {
    title: {
      en: `Add a keyword to the description of a TikTok post`,
      fr: `Ajoutez un mot-clé à la description d'une publication TikTok`,
      es: `Añade una palabra clave a la descripción de una publicación de TikTok`,
    },
    description: {},
  };
  return parseDefaults(defaults, definedLanguages);
};

const TikTokKeywordInDescriptionTriggerConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps<TikTokKeywordInDescriptionTriggerStruct>
> = ({formReturn: {setValue, control, watch, reset, register}, trigger, step}) => {
  const init = useRef(false);

  const {t} = useTranslation('app-tiktok', {
    keyPrefix: 'triggers.keyword-in-description.configComponent',
  });

  const [definedLanguages] = watch(['definedLanguages']);

  useEffect(() => {
    if (init.current) {
      const defaults = getDefaults(definedLanguages);
      setValue('defaultTitle', defaults.title);
      setValue('defaultDescription', defaults.description);
    }
  }, [definedLanguages, setValue]);

  useEffect(() => {
    if (init.current) {
      return;
    }
    const defaults = getDefaults(definedLanguages);
    reset(formValues => ({
      ...formValues,
      defaultTitle: defaults.title,
      defaultDescription: defaults.description,
      descriptionMode: getDescriptionMode(definedLanguages, defaults, step),
      title: step?.title ?? defaults.title,
      description: step?.description ?? defaults.description,
      icon: step?.icon !== undefined ? step.icon : '📸',
      media: step?.media ?? defaultMedia,
      trigger: {
        id: trigger?.id ?? null,
        connectionId: trigger?.connectionId ?? null,
        triggerIntegrationId: 'tiktok.keyword-in-description',
        triggerIntegrationConfig: {
          keyword: trigger?.triggerIntegrationConfig.keyword ?? '',
        },
      },
    }));
    init.current = true;
  }, [definedLanguages, reset, step, trigger]);

  if (!init.current) {
    return null;
  }

  return (
    <Fragment>
      <div className="max-w-sm">
        <ConnectionPicker
          appId="tiktok"
          control={control}
          register={register}
          connectionIdFieldName="trigger.connectionId"
        />
      </div>
      <Input
        name="trigger.triggerIntegrationConfig.keyword"
        label={t('labelKeyword')}
        register={register}
        control={control}
      />
      <DescriptionModeSelect />
      <LanguageSelectorInput />
      <StepTitleInput />
      <Separator />
      <StepContentInput />
    </Fragment>
  );
};

export default TikTokKeywordInDescriptionTriggerConfigComponent;
