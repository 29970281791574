import FacebookLikePageTriggerIntegrationInstructionsComponent from '@cohort/components-xps/components/apps/facebook/triggers/likePage/InstructionsComponent';
import type {TriggerIntegration} from '@cohort/merchants/apps';
import useSocialStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useSocialStepCompletionColumns';
import FacebookLikePageTriggerActionCtaComponentPreview from '@cohort/merchants/apps/facebook/triggers/likePage/ActionCtaComponentPreview';
import FacebookLikePageTriggerConfigComponent from '@cohort/merchants/apps/facebook/triggers/likePage/ConfigComponent';
import FacebookLikePageTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/facebook/triggers/likePage/StepCompletionTitleComponent';
import FacebookLikePageTriggerIntegrationComponentPreview from '@cohort/merchants/apps/facebook/triggers/likePage/TriggerComponentPreview';
import type {FacebookLikePageTriggerStruct} from '@cohort/shared/apps/facebook/triggers/likePage';
import {FacebookLikePageTriggerSpec} from '@cohort/shared/apps/facebook/triggers/likePage';

export const FacebookLikePageTriggerIntegration: TriggerIntegration<FacebookLikePageTriggerStruct> =
  {
    spec: FacebookLikePageTriggerSpec,
    configComponent: FacebookLikePageTriggerConfigComponent,
    stepCompletionTitleComponent: FacebookLikePageTriggerIntegrationStepCompletionTitleComponent,
    useStepCompletionColumns: context =>
      useSocialStepCompletionColumns<FacebookLikePageTriggerStruct>({
        ...context,
        userNameKey: 'username',
      }),
    getTitle: t => t('triggers.like-page.title', {ns: 'app-facebook'}),
    preview: {
      InstructionsComponent: FacebookLikePageTriggerIntegrationInstructionsComponent,
      CtaComponent: FacebookLikePageTriggerActionCtaComponentPreview,
      TriggerComponent: FacebookLikePageTriggerIntegrationComponentPreview,
    },
  };
