import {Badge} from '@cohort/merchants/components/Badge';
import CopyToClipboard from '@cohort/merchants/components/buttons/CopyToClipboard';
import {Fragment} from 'react';

type CohortTypeBadgeProps = {
  referenceId: string;
  showCopyToClipboard: boolean;
};

export const CohortReferenceIdBadge: React.FC<CohortTypeBadgeProps> = ({
  referenceId,
  showCopyToClipboard,
}) => {
  return (
    <Fragment>
      <Badge
        text={`# ${referenceId}`}
        backgroundColor="bg-slate-50"
        textColor="text-slate-800"
        size="small"
      />
      {showCopyToClipboard && (
        <CopyToClipboard target={referenceId} variant="secondary" disableNotification size={16} />
      )}
    </Fragment>
  );
};
