import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import DescriptionModeSelect from '@cohort/merchants/apps/common/DescriptionModeSelect';
import StepTitleInput from '@cohort/merchants/apps/common/StepTitleInput';
import type {Defaults} from '@cohort/merchants/apps/common/triggers/utils';
import {parseDefaults} from '@cohort/merchants/apps/common/triggers/utils';
import {getDescriptionMode} from '@cohort/merchants/apps/common/triggers/utils';
import ConnectionPicker from '@cohort/merchants/components/connections/ConnectionPicker';
import LanguageSelectorInput from '@cohort/merchants/components/form/LanguageSelectorInput';
import HighlightText from '@cohort/merchants/components/HighlightText';
import {useConnectionProfile} from '@cohort/merchants/hooks/api/Connections';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {INSTAGRAM_URL} from '@cohort/shared/apps/instagram/common';
import type {InstagramMerchantConnectorStruct} from '@cohort/shared/apps/instagram/merchantConnector';
import type {InstagramFollowTriggerStruct} from '@cohort/shared/apps/instagram/triggers/follow';
import type {Language} from '@cohort/shared/schema/common';
import {Fragment, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';

const getDefaults = (definedLanguages: Language[]): Defaults => {
  const defaults = {
    title: {
      en: `Follow us on Instagram`,
      fr: `Suivez-nous sur Instagram`,
      es: `Sigue a nosotros en Instagram`,
    },
    description: {},
  };
  return parseDefaults(defaults, definedLanguages);
};

const InstagramFollowTriggerConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps<InstagramFollowTriggerStruct>
> = ({formReturn: {watch, setValue, reset, control, register}, trigger, step}) => {
  const init = useRef(false);
  const merchant = useCurrentMerchant();

  const {t} = useTranslation('app-instagram', {
    keyPrefix: 'triggerIntegrations.follow.configComponent',
  });

  const [definedLanguages, connectionId] = watch(['definedLanguages', 'trigger.connectionId']);

  const {data: profile} = useConnectionProfile<InstagramMerchantConnectorStruct>(
    merchant.id,
    connectionId ?? '',
    {enabled: !!connectionId && init.current}
  );

  useEffect(() => {
    if (profile) {
      const defaults = getDefaults(definedLanguages);
      setValue('defaultTitle', defaults.title);
      setValue('defaultDescription', defaults.description);
      setValue(
        'trigger.triggerIntegrationConfig.url',
        `${INSTAGRAM_URL}/${profile.properties.username}`
      );
      setValue('trigger.triggerIntegrationConfig.merchantUsername', profile.properties.username);
    }
  }, [setValue, profile, definedLanguages]);

  useEffect(() => {
    if (init.current) {
      return;
    }
    const defaults = getDefaults(definedLanguages);
    reset(formValues => ({
      ...formValues,
      defaultTitle: defaults.title,
      defaultDescription: defaults.description,
      descriptionMode: getDescriptionMode(definedLanguages, defaults, step),
      title: step?.title ?? defaults.title,
      description: step?.description ?? defaults.description,
      icon: step?.icon !== undefined ? step.icon : '🫱🏼‍🫲🏿',
      media: null,
      trigger: {
        id: trigger?.id ?? null,
        connectionId: null,
        triggerIntegrationId: 'instagram.follow',
        triggerIntegrationConfig: {
          url: trigger?.triggerIntegrationConfig.url ?? '',
          merchantUsername: trigger?.triggerIntegrationConfig.merchantUsername ?? '',
        },
      },
    }));
    init.current = true;
  }, [definedLanguages, profile, reset, step, trigger]);

  if (!init.current) {
    return null;
  }

  return (
    <Fragment>
      <div className="max-w-sm">
        <ConnectionPicker
          appId="instagram"
          control={control}
          register={register}
          connectionIdFieldName="trigger.connectionId"
        />
      </div>
      <HighlightText type="info" text={t('unverifiedInfo')} />
      <DescriptionModeSelect />
      <LanguageSelectorInput />
      <StepTitleInput />
    </Fragment>
  );
};

export default InstagramFollowTriggerConfigComponent;
