import type {PerkIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import UrlInput from '@cohort/merchants/components/form/input/UrlInput';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {PerkFormValues} from '@cohort/merchants/pages/perks/perk/edit/utils';
import {useTranslation} from 'react-i18next';

const CohortLinkPerkIntegrationConfigComponent: React.FC<
  PerkIntegrationConfigComponentProps
> = () => {
  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'perkIntegrations.link.configComponent',
  });
  const {control, register} = useCohortForm<PerkFormValues>();

  return (
    <div className="space-y-6">
      <UrlInput
        name="integration.config.url"
        label={t('labelUrl')}
        register={register}
        control={control}
      />
    </div>
  );
};

export default CohortLinkPerkIntegrationConfigComponent;
