import type {TriggerComponentPreviewProps} from '@cohort/merchants/apps';
import type {CohortFormPictureChoiceTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/pictureChoice';
import {getLocalizedContent} from '@cohort/shared/utils/localization';
import {getImageUrl, Sizes} from '@cohort/shared/utils/media';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import type {RadioCardOption} from '@cohort/shared-frontend/utils/form';
import {isFile} from '@cohort/shared-frontend/utils/isFile';
import {useState} from 'react';
import {isDefined} from 'remeda';

type RadioCardsProps = Omit<JSX.IntrinsicElements['input'], 'name'> & {
  options: RadioCardOption[];
  hideLabels?: boolean;
  config?: {
    multipleChoice: boolean;
    maxMultipleChoices?: number | null;
  };
};

function RadioCardsInput({
  options,
  disabled,
  className,
  config = {
    multipleChoice: false,
    maxMultipleChoices: null,
  },
  hideLabels = false,
  ...props
}: RadioCardsProps): JSX.Element {
  const [hasReachedMaxMultipleChoices, setHasReachedMaxMultipleChoices] = useState(false);
  const [selected, setSelected] = useState<Array<string>>([]);

  const {multipleChoice, maxMultipleChoices} = config;

  const handleOptionClick = (optionValue: string): void => {
    if (multipleChoice) {
      const currentValues = selected;
      const isSelected = currentValues.includes(optionValue);

      if (
        !isSelected &&
        isDefined(maxMultipleChoices) &&
        currentValues.length >= maxMultipleChoices
      ) {
        setHasReachedMaxMultipleChoices(true);
        // Prevent adding more options if the max number of choices is reached
        return;
      }

      const newValues = isSelected
        ? currentValues.filter(value => value !== optionValue)
        : [...currentValues, optionValue];
      setSelected(newValues);

      setHasReachedMaxMultipleChoices(
        isDefined(maxMultipleChoices) && newValues.length >= maxMultipleChoices
      );
    } else {
      setSelected([optionValue]);
    }
  };

  return (
    <div
      className={cn(
        'w-[350px]',
        options.length === 1 ? 'grid grid-cols-1 justify-items-center' : 'grid grid-cols-2 gap-4',
        className
      )}
    >
      {options.map((option: RadioCardOption) => {
        const isSelected = selected.includes(option.value);

        return (
          <div
            key={option.value}
            className={cn(
              options.length === 1 ? 'w-1/2' : 'grow basis-0',
              !isSelected && hasReachedMaxMultipleChoices && 'opacity-50 hover:cursor-not-allowed'
            )}
            onClick={e => {
              e.preventDefault();
              if (!option.disabled && !disabled) {
                handleOptionClick(option.value);
              }
            }}
          >
            <div
              className={cn(
                'relative h-full flex-1 cursor-pointer rounded-[--xps-card-border-radius] border bg-[--xps-card-background-color]',
                isSelected
                  ? 'border-2 border-[--xps-input-active-border-color]'
                  : 'border-[--xps-border-color]',
                (option.disabled || disabled) && '!cursor-not-allowed bg-slate-50 opacity-50'
              )}
              style={{
                borderColor: isSelected ? 'var(--xps-accent-color' : '',
              }}
            >
              <label
                className={cn(
                  'flex h-full cursor-pointer flex-col justify-between gap-4 p-4',
                  (option.disabled || disabled) && 'cursor-not-allowed'
                )}
              >
                {option.prefix && option.prefix}
                {!hideLabels && (
                  <div className="mt-4 flex-1 flex-col gap-2 text-sm">
                    <span className="font-medium">{option.label}</span>
                  </div>
                )}
                {option.suffix && option.suffix}
              </label>
              <input
                className="pointer-events-none absolute opacity-0"
                type={multipleChoice ? 'checkbox' : 'radio'}
                value={option.value}
                onBlur={undefined}
                onChange={e => {
                  props.onChange?.(e);
                }}
                checked={selected.includes(option.value)}
                disabled={option.disabled || disabled}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

const CohortFormPictureChoiceTriggerIntegrationComponentPreview: React.FC<
  TriggerComponentPreviewProps<CohortFormPictureChoiceTriggerStruct>
> = ({config, localizationConfig}) => {
  const options: Array<RadioCardOption> = config.pictureChoiceOptions.map(option => {
    const value = isFile(option.imageFileKey) ? option.imageFileKey.name : option.imageFileKey;

    const imageSrc = isFile(option.imageFileKey)
      ? URL.createObjectURL(option.imageFileKey)
      : getImageUrl(import.meta.env.COHORT_ENV, option.imageFileKey, {
          h: Sizes.M,
          w: Sizes.M,
        });

    return {
      value: value,
      label: option.label ? (getLocalizedContent(option.label, localizationConfig) ?? '') : '',
      prefix: (
        <div>
          <img
            className={cn(
              'pointer-events-none mx-auto aspect-square rounded-[--xps-img-border-radius] object-cover'
            )}
            src={imageSrc}
          />
        </div>
      ),
    };
  });

  return (
    <RadioCardsInput
      hideLabels={!config.showLabels}
      options={options}
      config={{
        multipleChoice: config.multipleChoice ?? false,
        maxMultipleChoices: config.maxMultipleChoices,
      }}
    />
  );
};

export default CohortFormPictureChoiceTriggerIntegrationComponentPreview;
