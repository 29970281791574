import FieldWrapper from '@cohort/merchants/components/campaigns/preview/FieldWrapper';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import React from 'react';
import type {UseFormRegisterReturn} from 'react-hook-form';

type DefaultInput = JSX.IntrinsicElements['input'];

type InputProps = {
  width?: string;
  register?: UseFormRegisterReturn;
  label?: string;
  mandatory?: boolean;
  error?: JSX.Element;
};

const InputPreview: React.FC<DefaultInput & InputProps> = ({
  register,
  className,
  label,
  mandatory,
  width,
  error,
  ...props
}) => (
  <FieldWrapper className={width} label={label} mandatory={mandatory} error={error}>
    <input
      {...props}
      {...register}
      className={cn(
        'duration-50 block w-full rounded-md transition ease-in-out focus:border-0 focus:ring-2 focus:ring-offset-0 sm:text-sm',
        className
      )}
      style={
        {
          borderWidth: '1px',
          borderRadius: 'var(--xps-input-border-radius)',
          backgroundColor: 'var(--xps-input-background-color)',
          borderColor: 'var(--xps-input-border-color)',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as Record<string, any>
      }
    />
  </FieldWrapper>
);
export default InputPreview;
