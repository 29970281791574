import Button from '@cohort/merchants/components/buttons/Button';
import {CohortFormProvider} from '@cohort/merchants/components/form/CohortFormContext';
import Input from '@cohort/merchants/components/form/input/Input';
import {Dialog, DialogContent, DialogTitle} from '@cohort/merchants/components/modals/Dialog';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

export const CreateEntityModalFormSchema = z.object({
  internalName: z.string().min(1, {message: 'errorTooShort1'}),
});
export type CreateEntityModalForm = z.infer<typeof CreateEntityModalFormSchema>;

type CreateEntityModalProps = {
  title: string;
  placeholder: string;
  onClose: () => void;
  onSubmit: (data: CreateEntityModalForm) => Promise<void>;
  isLoading: boolean;
};
type CreateEntityFormProps = Pick<CreateEntityModalProps, 'onSubmit' | 'isLoading'>;

const CreateEntityForm: React.FC<CreateEntityFormProps> = ({onSubmit, isLoading}) => {
  const {t} = useTranslation('components', {keyPrefix: 'modals.createEntityModal'});
  const {register, control, formState, handleSubmit} = useCohortForm<CreateEntityModalForm>();

  return (
    <form className="mt-6 flex flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
      <Input
        type="text"
        name="internalName"
        register={register}
        control={control}
        description={t('contextInternalName')}
      />
      <Button
        type="submit"
        loading={isLoading}
        disabled={!formState.isDirty}
        className="h-10 w-full"
      >
        {t('buttonCreate')}
      </Button>
    </form>
  );
};

const CreateEntityModal: React.FC<CreateEntityModalProps> = ({
  title,
  placeholder,
  onClose,
  ...props
}) => (
  <Dialog open onOpenChange={onClose}>
    <DialogContent className="p-6" displayCloseButton={false}>
      <div className="flex justify-center">
        <DialogTitle className="text-lg">{title}</DialogTitle>
      </div>
      <CohortFormProvider
        schema={CreateEntityModalFormSchema}
        defaultValues={{
          internalName: placeholder,
        }}
      >
        <CreateEntityForm {...props} />
      </CohortFormProvider>
    </DialogContent>
  </Dialog>
);

export default CreateEntityModal;
