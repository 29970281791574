import {useFacebookPosts} from '@cohort/merchants/apps/facebook/actions';
import FacebookPostSelector from '@cohort/merchants/apps/facebook/triggers/FacebookPostSelector';
import ErrorState from '@cohort/merchants/components/ErrorState';
import Loader from '@cohort/merchants/components/Loader';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {FacebookPost} from '@cohort/shared/apps/facebook/common';
import {useMemo} from 'react';
import {match} from 'ts-pattern';

type BaseFacebookSelectPostTriggerIntegrationConfigProps = {
  connectionId: string;
  description: string;
  title: string;
  noPostsMessage: string;
  onSelectPost: (post: FacebookPost) => void;
  selectedPostId?: string;
};

const BaseFacebookSelectPostTriggerIntegrationConfig: React.FC<
  BaseFacebookSelectPostTriggerIntegrationConfigProps
> = ({connectionId, description, title, noPostsMessage, onSelectPost, selectedPostId}) => {
  const merchant = useCurrentMerchant();

  const {
    data: postsResponse,
    isLoading: isPostsLoading,
    isFetched: isPostsFetched,
  } = useFacebookPosts(merchant.id, connectionId);

  const posts = useMemo(() => postsResponse?.output.posts ?? [], [postsResponse]);

  return match({
    posts,
    isPostsLoading,
    isPostsFetched,
  })
    .with({isPostsLoading: true}, () => <Loader size={30} color="gray" />)
    .with({posts: []}, () => <p>{noPostsMessage}</p>)
    .with({isPostsFetched: true}, () => {
      const selectedPost = posts.find(post => post.id === selectedPostId);

      return (
        <FacebookPostSelector
          description={description}
          onSelectPost={onSelectPost}
          title={title}
          selectedPost={selectedPost}
          posts={posts}
        />
      );
    })
    .otherwise(() => <ErrorState />);
};

export default BaseFacebookSelectPostTriggerIntegrationConfig;
