import type {PreviewCtaComponentProps} from '@cohort/merchants/apps';
import ActionCtaPreview from '@cohort/merchants/apps/common/ActionCtaPreview';
import type {SpotifyFollowPlaylistTriggerStruct} from '@cohort/shared/apps/spotify/triggers/followPlaylist';
import {useTranslation} from 'react-i18next';

const SpotifyFollowPlaylistTriggerActionCtaComponentPreview: React.FC<
  PreviewCtaComponentProps<SpotifyFollowPlaylistTriggerStruct>
> = ({localizationConfig}) => {
  const {t} = useTranslation('app-spotify', {
    keyPrefix: 'triggerIntegrations.follow-playlist',
    lng: localizationConfig.desiredLanguage,
  });
  return <ActionCtaPreview text={t('ctaTitle')} withRefresh />;
};

export default SpotifyFollowPlaylistTriggerActionCtaComponentPreview;
