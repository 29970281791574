import type {TriggerComponentPreviewProps} from '@cohort/merchants/apps';
import InputPreview from '@cohort/merchants/components/campaigns/preview/InputPreview';
import type {CohortFormNumberTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/number';
import {getLocalizedContentOrThrow} from '@cohort/shared/utils/localization';

const placeholders = {
  en: 'Enter a number here',
  fr: 'Entrez un nombre ici',
  es: 'Escribe un número aquí',
};

const CohortFormNumberTriggerIntegrationComponentPreview: React.FC<
  TriggerComponentPreviewProps<CohortFormNumberTriggerStruct>
> = ({localizationConfig}) => {
  const placeholder = getLocalizedContentOrThrow(placeholders, localizationConfig);

  return <InputPreview width="w-[350px]" type="number" placeholder={placeholder} />;
};

export default CohortFormNumberTriggerIntegrationComponentPreview;
