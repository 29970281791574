import {useTikTokVideos} from '@cohort/merchants/apps/tiktok/actions';
import type {OnSelectVideo} from '@cohort/merchants/apps/tiktok/triggers/components/SelectVideoSheet';
import TikTokVideoSelector from '@cohort/merchants/apps/tiktok/triggers/components/TikTokVideoSelector';
import ErrorState from '@cohort/merchants/components/ErrorState';
import HighlightText from '@cohort/merchants/components/HighlightText';
import Loader from '@cohort/merchants/components/Loader';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {Fragment} from 'react';
import {match} from 'ts-pattern';

type BaseTikTokSelectPostTriggerIntegrationConfigProps = {
  connectionId: string | null;
  videoId: string | null;
  onSelectVideo: OnSelectVideo;
  description: string;
  title: string;
  noVideosMessage: string;
  unverifiedInfo: string;
};

const BaseTikTokSelectPostTriggerIntegrationConfig: React.FC<
  BaseTikTokSelectPostTriggerIntegrationConfigProps
> = ({
  connectionId,
  videoId,
  onSelectVideo,
  description,
  title,
  noVideosMessage,
  unverifiedInfo,
}) => {
  const merchant = useCurrentMerchant();

  const {
    data: videosResponse,
    isLoading: isVideosLoading,
    isFetched: isVideosFetched,
  } = useTikTokVideos(merchant.id, connectionId ?? '', {enabled: !!connectionId});

  const videos = videosResponse?.output.videos ?? [];

  return match({
    videos,
    isVideosLoading,
    isVideosFetched,
  })
    .with({isVideosLoading: true}, () => <Loader size={30} color="gray" />)
    .with({videos: []}, () => <p>{noVideosMessage}</p>)
    .with({isVideosFetched: true}, () => {
      const selectedVideo = videos.find(video => video.id === videoId);

      return (
        <Fragment>
          <TikTokVideoSelector
            description={description}
            onSelectVideo={onSelectVideo}
            title={title}
            selectedVideo={selectedVideo}
            videos={videos}
          />
          <HighlightText type="info" text={unverifiedInfo} />
        </Fragment>
      );
    })
    .otherwise(() => <ErrorState />);
};

export default BaseTikTokSelectPostTriggerIntegrationConfig;
