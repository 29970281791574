/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {GetCohortsParamsADto, GetCohortUsersParamsADto} from '@cohort/admin-schemas/cohort';
import type {RuleGroupInputADto} from '@cohort/admin-schemas/rule';
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {
  getCohortById,
  getCohortRule,
  getCohorts,
  getCohortUsers,
  getSegmentPreview,
} from '@cohort/merchants/lib/api/Cohorts';
import type {CohortType} from '@cohort/shared/schema/common/cohort';
import {useInfiniteQuery} from '@tanstack/react-query';

export const cohortsKeys = {
  cohorts: ['cohorts'] as const,
  list: (merchantId: string) => [...cohortsKeys.cohorts, merchantId, 'list'] as const,
  listWithFilters: (merchantId: string, params?: GetCohortsParamsADto) =>
    [...cohortsKeys.list(merchantId), params] as const,
  listInfiniteCohorts: (merchantId: string, params?: GetCohortsParamsADto) =>
    [...cohortsKeys.list(merchantId), params, 'infinite'] as const,
  getById: (merchantId: string, cohortId: string) =>
    [...cohortsKeys.cohorts, merchantId, cohortId] as const,
  getCohortUsers: (merchantId: string, cohortId: string, params?: GetCohortUsersParamsADto) =>
    [...cohortsKeys.getById(merchantId, cohortId), 'users', params] as const,
  getCohortRule: (merchantId: string, cohortId: string) =>
    [...cohortsKeys.getById(merchantId, cohortId), 'rule'] as const,
  getSegmentPreview: (merchantId: string, groups: RuleGroupInputADto[], limit: number) =>
    [...cohortsKeys.getCohortRule(merchantId, 'segment-preview'), groups, limit] as const,
};

export const useCohorts = (
  merchantId: string,
  params: GetCohortsParamsADto,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: cohortsKeys.listWithFilters(merchantId, params),
    queryFn: async () => getCohorts(merchantId, params),
    ...options,
    refetchInterval: options?.autoRefresh === true ? 5000 : false,
  });

export const useCohort = (merchantId: string, cohortId: string, options?: QueryOptions) =>
  useCohortQuery({
    queryKey: cohortsKeys.getById(merchantId, cohortId),
    queryFn: async () => getCohortById(merchantId, cohortId),
    ...options,
  });

export const useCohortUsers = (
  merchantId: string,
  cohortId: string,
  params: GetCohortUsersParamsADto,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: cohortsKeys.getCohortUsers(merchantId, cohortId, params),
    queryFn: async () => getCohortUsers(merchantId, cohortId, params),
    ...options,
  });

export const useCohortRule = (merchantId: string, cohortId: string, options?: QueryOptions) =>
  useCohortQuery({
    queryKey: cohortsKeys.getCohortRule(merchantId, cohortId),
    queryFn: async () => getCohortRule(merchantId, cohortId),
    ...options,
    refetchOnWindowFocus: false,
  });

export const useSegmentPreview = (
  merchantId: string,
  groups: RuleGroupInputADto[],
  limit: number,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: cohortsKeys.getSegmentPreview(merchantId, groups, limit),
    queryFn: async () => getSegmentPreview(merchantId, groups, limit),
    ...options,
    refetchOnWindowFocus: false,
  });

export const useCohortsWithInfiniteQuery = (
  merchantId: string,
  pageSize: number,
  search?: string,
  type?: CohortType
) =>
  useInfiniteQuery({
    queryKey: cohortsKeys.listInfiniteCohorts(merchantId),
    queryFn: async ({pageParam = 1}) => {
      const [pagination, cohorts] = await getCohorts(merchantId, {
        page: pageParam,
        pageSize,
        ...(search !== undefined && search.trim().length > 0 && {search}),
        ...(type !== undefined && {type}),
      });

      return {data: cohorts, meta: pagination};
    },
    getNextPageParam: lastPage => {
      if (lastPage.meta.page === lastPage.meta.pages) {
        return undefined;
      }
      return lastPage.meta.page + 1;
    },
  });
