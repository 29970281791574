import type {StepCompletionAttemptADto} from '@cohort/admin-schemas/challengeParticipation';
import type {
  ChallengeStepADto,
  ChallengeStepTriggerADto,
} from '@cohort/admin-schemas/challengeSteps';
import type {TriggerIntegration} from '@cohort/merchants/apps';
import {useTriggerIntegrations} from '@cohort/merchants/apps/useTriggerIntegrations';
import {formatDate} from '@cohort/shared/utils/format';
import {useTranslation} from 'react-i18next';

type StepCompletionTitleProps = {
  attempt: StepCompletionAttemptADto;
  triggerIntegration: TriggerIntegration | null;
};

const StepCompletionTitle: React.FC<StepCompletionTitleProps> = ({attempt, triggerIntegration}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'campaigns.challenges.stepCompletion',
  });

  if (attempt.manual) {
    return <p>{t('stepManualyCompleted')}</p>;
  }

  if (triggerIntegration === null) {
    // some completion attempt can be linked to deleted triggers (facebook.connect-account...)
    return null;
  }

  return (
    <triggerIntegration.stepCompletionTitleComponent
      data={attempt.data}
      completionSuccess={attempt.success}
    />
  );
};

type StepCompletionProps = {
  step: ChallengeStepADto;
  stepTrigger: ChallengeStepTriggerADto;
  attempt: StepCompletionAttemptADto;
  displayDate: boolean;
};

export const StepCompletion: React.FC<StepCompletionProps> = ({
  step,
  stepTrigger,
  attempt,
  displayDate,
}) => {
  const {getTriggerIntegration} = useTriggerIntegrations();

  const triggerIntegration =
    attempt.triggerIntegrationId === null
      ? null
      : getTriggerIntegration(attempt.triggerIntegrationId);

  return (
    <div className="mr-4 flex w-full flex-col space-y-2 px-2 text-sm">
      <div className="flex flex-col text-sm">
        <p>
          <StepCompletionTitle attempt={attempt} triggerIntegration={triggerIntegration} />
        </p>
        {displayDate && (
          <p className="mb-2 text-xs text-slate-500">{formatDate(attempt.createdAt)}</p>
        )}
      </div>
      {triggerIntegration?.stepCompletionContextComponent !== undefined && (
        <triggerIntegration.stepCompletionContextComponent
          step={step}
          stepTrigger={stepTrigger}
          data={attempt.data}
        />
      )}
    </div>
  );
};
