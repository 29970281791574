import type {ConnectionADto} from '@cohort/admin-schemas/connection';
import {useApps} from '@cohort/merchants/apps/useApps';
import Button from '@cohort/merchants/components/buttons/Button';
import SelectInput from '@cohort/merchants/components/form/select/SelectInput';
import type {SelectOption} from '@cohort/merchants/components/form/select/SelectPicker';
import Loader from '@cohort/merchants/components/Loader';
import {useConnections} from '@cohort/merchants/hooks/api/Connections';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import EditConnectionModal from '@cohort/merchants/pages/connections/new/EditConnectionModal';
import type {AppId, MerchantConnectorId} from '@cohort/shared/apps';
import {getAppSpec} from '@cohort/shared/apps';
import type {AppStruct} from '@cohort/shared/apps/app';
import ConnectionErrorBadge from '@cohort/shared-frontend/components/ConnectionErrorBadge';
import {PlusCircle} from '@phosphor-icons/react';
import {useEffect, useState} from 'react';
import type {Control, FieldValues, Path, UseFormRegister} from 'react-hook-form';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {isNil} from 'remeda';

export type ConnectionPickerProps<T extends AppStruct, U extends FieldValues> = {
  appId: T['Id'];
  register: UseFormRegister<U>;
  control: Control<U>;
  connectionIdFieldName: Path<U>;
  withCreateButton?: boolean;
  onConnectionModalClose?: () => void;
};

const ConnectionPicker = <T extends AppStruct, U extends FieldValues>({
  appId,
  register,
  control,
  withCreateButton = false,
  onConnectionModalClose,
  connectionIdFieldName,
}: ConnectionPickerProps<T, U>): React.ReactElement => {
  const merchant = useCurrentMerchant();
  const [createConnectionModalOpen, setCreateConnectionModalOpen] = useState(false);
  const {t} = useTranslation('components', {
    keyPrefix: 'connections.connectionPicker',
  });
  const appSpec = getAppSpec(appId);
  const {appDisabled} = useApps();

  withCreateButton = appDisabled(appSpec.id as AppId) || withCreateButton;

  if (appSpec.merchantConnector === null) {
    throw new Error(`App ${appId} does not support merchant connections`);
  }

  const {data: connections} = useConnections(merchant.id, {
    connectorId: [appSpec.merchantConnector.id as MerchantConnectorId],
  });
  const readyAndBrokenConnections = connections?.filter(connection =>
    ['ready', 'broken'].includes(connection.status)
  );

  const {field: connectionIdField} = useController({name: connectionIdFieldName, control});

  useEffect(() => {
    if (
      isNil(connectionIdField.value) &&
      readyAndBrokenConnections !== undefined &&
      readyAndBrokenConnections.length > 0
    ) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      connectionIdField.onChange(readyAndBrokenConnections[0]!.id);
    }
  }, [connectionIdField, readyAndBrokenConnections]);

  if (!readyAndBrokenConnections) {
    return (
      <div className="space-y-6">
        <Loader size={30} color="gray" />
      </div>
    );
  }

  const formatConnectionLabel = (connection: ConnectionADto): string | JSX.Element => {
    if (connection.status === 'ready') {
      return connection.name;
    } else {
      return (
        <div className="flex justify-between text-sm">
          <div className="text-slate-400">{connection.name}</div>
          <ConnectionErrorBadge label={t('connectionErrorLabel')} iconSize="sm" />
        </div>
      );
    }
  };

  return (
    <div className="space-y-2">
      <SelectInput
        name={connectionIdFieldName}
        label={t('labelConnection')}
        register={register}
        control={control}
        options={readyAndBrokenConnections.map(
          connection =>
            ({
              label: formatConnectionLabel(connection),
              value: connection.id,
              isDisabled: connection.status !== 'ready',
            }) satisfies SelectOption
        )}
        placeholder={t('placeholder')}
      />
      {!withCreateButton && (
        <Button
          variant="ghost"
          data-testid="create-connection-btn"
          onClick={() => {
            setCreateConnectionModalOpen(true);
          }}
        >
          <PlusCircle size={20} className="mr-2" />
          {t('buttonCreateConnection')}
        </Button>
      )}
      {createConnectionModalOpen && (
        <EditConnectionModal
          onClose={() => {
            setCreateConnectionModalOpen(false);
            onConnectionModalClose?.();
          }}
          appId={appId}
        />
      )}
    </div>
  );
};

export default ConnectionPicker;
