import type {AssetADto} from '@cohort/admin-schemas/asset';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {notify} from '@cohort/merchants/hooks/toast';
import {usePrompt} from '@cohort/merchants/hooks/usePrompt';
import {getDefinedLanguages} from '@cohort/merchants/lib/form/localization';
import {handleFormErrors, uploadAsset} from '@cohort/merchants/lib/form/utils';
import type {Language} from '@cohort/shared/schema/common';
import {LanguageSchema} from '@cohort/shared/schema/common';
import {LocalizedRichTextSchema, LocalizedStringSchema} from '@cohort/shared/schema/common';
import type {AdminAssetKind} from '@cohort/shared/schema/common/assets';
import {LocalizedFaqsSchema} from '@cohort/shared/schema/common/campaign';
import {isFile, isFileList} from '@cohort/shared-frontend/utils/isFile';
import {zodResolver} from '@hookform/resolvers/zod';
import type {UseMutateAsyncFunction, UseMutationResult} from '@tanstack/react-query';
import {useIsMutating} from '@tanstack/react-query';
import get from 'lodash/get';
import React, {createContext, useCallback, useMemo, useState} from 'react';
import type {
  DefaultValues,
  FieldError,
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
  SubmitErrorHandler,
  UseFormGetValues,
  UseFormReturn,
  UseFormSetValue,
} from 'react-hook-form';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {isDefined, isEmpty} from 'remeda';
import type {ZodType} from 'zod';
import {z, ZodError} from 'zod';

function findLanguageInErrors(errors: FieldErrors | FieldError | null): Language | null {
  if (typeof errors !== 'object' || errors === null) {
    return null;
  }
  const errorObj = errors as Record<string, unknown>;

  for (const key in errorObj) {
    const value = errorObj[key];

    const language = LanguageSchema.safeParse(key);

    if (language.success) {
      return language.data;
    }

    // Skip React ref objects to avoid circular references
    if (key === 'ref' || key === '_valueTracker' || key === '_wrapperState') {
      continue;
    }

    if (typeof value === 'object' && value !== null) {
      const nestedResult = findLanguageInErrors(value as FieldErrors);

      if (nestedResult) {
        return nestedResult;
      }
    }
  }
  return null;
}

function findLanguageInZodErrors(errors: ZodError): Language | null {
  for (const error of errors.issues) {
    const language = LanguageSchema.safeParse(error.path[error.path.length - 1]);

    if (language.success) {
      return language.data;
    }
  }
  return null;
}

function changeSelectedLanguageIfNeeded(
  errors: FieldErrors | ZodError,
  definedLanguages: Array<Language>,
  defaultLanguage: Language,
  setSelectedLanguage: (language: Language) => void
): void {
  let languageWithErrors: Language | null = null;

  if (errors instanceof ZodError) {
    languageWithErrors = findLanguageInZodErrors(errors);
  } else {
    languageWithErrors = findLanguageInErrors(errors);
  }

  if (languageWithErrors !== null && definedLanguages.includes(languageWithErrors)) {
    setSelectedLanguage(languageWithErrors);
  } else {
    // Can happen when you are a in localized form, in an additional language and there's an error in the default language
    // on a field that is not localized
    setSelectedLanguage(defaultLanguage);
  }
}

function handleLanguageSelection<TFieldValues extends FieldValues>(
  isLocalized: boolean,
  definedLanguages: Array<Language>,
  defaultLanguage: Language,
  errors: FieldErrors | ZodError,
  setValue: UseFormSetValue<TFieldValues>
): void {
  if (isLocalized) {
    const setSelectedLanguage = (lang: Language): void =>
      setValue(
        'selectedLanguage' as Path<TFieldValues>,
        lang as PathValue<TFieldValues, Path<TFieldValues>>
      );

    changeSelectedLanguageIfNeeded(errors, definedLanguages, defaultLanguage, setSelectedLanguage);
  }
}

export type LocalizedField<TFieldValues extends FieldValues> = {
  path: Path<TFieldValues>;
  isOptional: boolean;
};

export function getLocalizedFields<TFieldValues extends FieldValues>(
  schema: z.ZodTypeAny,
  prefix = '',
  isOptional = false
): Array<LocalizedField<TFieldValues>> {
  if (
    schema === LocalizedStringSchema ||
    schema === LocalizedRichTextSchema ||
    schema === LocalizedFaqsSchema
  ) {
    return [{path: prefix as Path<TFieldValues>, isOptional}];
  }

  if (schema instanceof z.ZodObject) {
    return Object.entries(schema.shape).flatMap(([key, value]) =>
      getLocalizedFields(value as z.ZodTypeAny, prefix ? `${prefix}.${key}` : key, isOptional)
    );
  }

  if (schema instanceof z.ZodArray) {
    return getLocalizedFields(schema.element, `${prefix}[]`, isOptional);
  }

  if (schema instanceof z.ZodOptional || schema instanceof z.ZodNullable) {
    return getLocalizedFields(schema.unwrap(), prefix, true);
  }

  if (schema instanceof z.ZodEffects) {
    return getLocalizedFields(schema.innerType(), prefix, isOptional);
  }

  if (schema instanceof z.ZodIntersection) {
    const leftFields = getLocalizedFields<TFieldValues>(schema._def.left, prefix, isOptional);
    const rightFields = getLocalizedFields<TFieldValues>(schema._def.right, prefix, isOptional);
    return [...leftFields, ...rightFields];
  }

  if (schema instanceof z.ZodDiscriminatedUnion) {
    return schema._def.options.flatMap((option: z.ZodTypeAny) =>
      getLocalizedFields<TFieldValues>(option, prefix, isOptional)
    );
  }

  return [];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isLocalizedFieldDefined(localizedField: any, language: string): boolean {
  return !isEmpty(localizedField) && isDefined(localizedField[language]);
}

function validateLocalizedField<TFieldValues extends FieldValues>(
  fieldPath: string,
  isOptional: boolean,
  getValues: UseFormGetValues<TFieldValues>,
  fieldPathPrefix?: string
): void {
  const fullPath = fieldPathPrefix ? `${fieldPathPrefix}.${fieldPath}` : fieldPath;
  const localizedField = getValues(fullPath as Path<TFieldValues>);
  const defaultLanguage = getValues('defaultLanguage' as Path<TFieldValues>);

  // If the field is not optional, at least the default language must be set
  if (!isOptional && !isLocalizedFieldDefined(localizedField, defaultLanguage)) {
    throw new ZodError([
      {
        code: z.ZodIssueCode.custom,
        message: 'errorTooShort1',
        path: [...fieldPath.split('.'), defaultLanguage],
      },
    ]);
  }

  // If the field is optional, if you provide a value for a language, the default language must also be provided
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (isOptional && !isEmpty(localizedField) && !localizedField[defaultLanguage]) {
    throw new ZodError([
      {
        code: z.ZodIssueCode.custom,
        message: 'errorTooShort1',
        path: [...fieldPath.split('.'), defaultLanguage],
      },
    ]);
  }
}

export function applyLanguageFieldValidation<TFieldValues extends FieldValues>(
  isLocalized: boolean,
  localizedFields: Array<LocalizedField<TFieldValues>>,
  getValues: UseFormGetValues<TFieldValues>,
  fieldPathPrefix?: string
): void {
  if (isLocalized) {
    localizedFields.forEach(field => {
      const fieldPath = field.path.split('.');
      const isArrayField = fieldPath.some(part => part.includes('[]'));

      // If the field is an array field, we need to validate each element of the array
      // Since getValues does not support array fields, we need to manually iterate over the array
      // and validate each element until we reach an undefined value
      if (isArrayField) {
        const arrayFieldIndex = fieldPath.findIndex(part => part.includes('[]'));
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        fieldPath[arrayFieldIndex] = fieldPath.at(arrayFieldIndex)!.replace('[]', '');
        const arrayFieldPath = fieldPath.slice(0, arrayFieldIndex + 1);
        const subFieldPath = fieldPath.slice(arrayFieldIndex + 1);
        let index = 0;

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        while (true) {
          const fullPath = [...arrayFieldPath, `[${index}]`, ...subFieldPath].join('.');
          const value = getValues(fullPath as Path<TFieldValues>);

          if (value === undefined) {
            break;
          }
          validateLocalizedField(fullPath, field.isOptional, getValues, fieldPathPrefix);
          index++;
        }
      } else {
        validateLocalizedField(field.path, field.isOptional, getValues, fieldPathPrefix);
      }
    });
  }
}

export async function uploadAssets<TFieldValues extends FieldValues = FieldValues>(
  data: TFieldValues,
  assets: Array<FormAsset<TFieldValues>> | null,
  uploadMutation: UseMutateAsyncFunction<
    AssetADto,
    unknown,
    {file: File; assetKind: AdminAssetKind},
    unknown
  >
): Promise<UploadedAssets<TFieldValues> | null> {
  if (!assets || assets.length === 0) {
    return null;
  }

  const nestedOptionsAssets: Array<{
    name: Path<TFieldValues>;
    type: AdminAssetKind;
  }> = assets.flatMap(({name, nestedName, type}) => {
    if (nestedName) {
      const value = get(data, name);

      if (Array.isArray(value)) {
        const result: Array<{name: Path<TFieldValues>; type: AdminAssetKind}> = [];
        for (let i = 0; i < value.length; i++) {
          result.push({
            name: `${name}.${i}.${nestedName}` as Path<TFieldValues>,
            type,
          });
        }
        return result;
      }
    }
    return [];
  });

  const uploadPromises = [...assets, ...nestedOptionsAssets]
    .filter(({name}) => {
      const value = get(data, name);
      return value && (isFile(value) || (isFileList(value) && value.length === 1));
    })
    .map(async ({name, type}) => {
      const value = get(data, name);
      const fileName = isFileList(value) ? value[0] : value;
      const file = await uploadMutation({file: fileName, assetKind: type});

      return [name, file] as const;
    });
  const uploadedAssets: UploadedAssets<TFieldValues> = new Map(await Promise.all(uploadPromises));

  return uploadedAssets;
}

export type CohortFormContextType<TFieldValues extends FieldValues = FieldValues> = Omit<
  UseFormReturn<TFieldValues>,
  'handleSubmit'
> & {
  upload: UseMutationResult<AssetADto, unknown, {file: File; assetKind: AdminAssetKind}>;
  handleSubmit: (
    onValid: CohortSubmitHandler<TFieldValues>,
    onInvalid?: SubmitErrorHandler<TFieldValues>
  ) => (e?: React.BaseSyntheticEvent) => Promise<unknown>;
  handleManualSubmit: <TCustomSchema extends ZodType>(
    customSchema: TCustomSchema,
    onSubmit: CohortSubmitHandler<z.infer<TCustomSchema>>
  ) => Promise<void>;
  isLocalized: boolean;
  isLoading: boolean;
  setFormAssets: React.Dispatch<React.SetStateAction<FormAsset<TFieldValues>[] | null>>;
  localizedFields: Array<LocalizedField<TFieldValues>>;
};

export const CohortFormContext = createContext<CohortFormContextType | null>(null);

type FormAsset<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>;
  type: AdminAssetKind;
  nestedName?: string;
};

export type UploadedAssets<TFieldValues extends FieldValues> = Map<
  Path<TFieldValues>,
  AssetADto | null
>;

type FormProviderProps<TSchema extends ZodType> = {
  children: React.ReactNode;
  schema: TSchema;
  defaultValues: DefaultValues<z.input<TSchema>>;
  warnOnUnsavedChanges?: boolean;
  assets?: Array<FormAsset<z.input<TSchema>>> | null;
};

type CohortSubmitHandler<TFieldValues extends FieldValues> = (
  data: TFieldValues,
  uploadedAssets: UploadedAssets<TFieldValues> | null
) => Promise<unknown>;

export function CohortFormProvider<TSchema extends ZodType>({
  children,
  schema,
  defaultValues,
  warnOnUnsavedChanges = false,
  assets = null,
}: FormProviderProps<TSchema>): JSX.Element {
  type TFieldValues = z.input<TSchema>;
  const [formAssets, setFormAssets] = useState<Array<FormAsset<TFieldValues>> | null>(assets);
  const [isLoading, setIsLoading] = useState(false);
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('components', {
    keyPrefix: 'form.formProvider',
  });
  const localizedFields = useMemo(
    () =>
      // Remove duplicate fields on complex schemas (e.g. intersections)
      Array.from(new Map(getLocalizedFields(schema).map(field => [field.path, field])).values()),
    [schema]
  );

  const unwrapSchema = useCallback((schema: z.ZodTypeAny): z.ZodTypeAny => {
    if (schema instanceof z.ZodEffects) {
      return unwrapSchema(schema.innerType());
    }

    if (
      schema instanceof z.ZodOptional ||
      schema instanceof z.ZodDefault ||
      schema instanceof z.ZodNullable
    ) {
      return unwrapSchema(schema._def.innerType);
    }

    if (schema instanceof z.ZodIntersection) {
      // Recursively unwrap both left and right sides
      const left = unwrapSchema(schema._def.left);
      const right = unwrapSchema(schema._def.right);

      // If both are objects, merge their shapes
      if (left instanceof z.ZodObject && right instanceof z.ZodObject) {
        return z.object({
          ...left.shape,
          ...right.shape,
        });
      }

      // If only one is an object, return that
      return left instanceof z.ZodObject ? left : right instanceof z.ZodObject ? right : schema;
    }

    if (schema instanceof z.ZodDiscriminatedUnion) {
      // Extract the first matching object (arbitrary choice, but better than returning union)
      const firstOption = [...schema.options.values()][0];
      return unwrapSchema(firstOption);
    }

    return schema;
  }, []);

  const isLocalized = useMemo(() => {
    const unwrappedSchema = unwrapSchema(schema);
    const schemaShape = unwrappedSchema instanceof z.ZodObject ? unwrappedSchema.shape : {};

    return (
      'defaultLanguage' in schemaShape &&
      'selectedLanguage' in schemaShape &&
      'definedLanguages' in schemaShape
    );
  }, [schema, unwrapSchema]);

  const methods = useForm<TFieldValues>({
    defaultValues: {
      ...(isLocalized && {
        defaultLanguage: merchant.defaultLanguage,
        selectedLanguage: merchant.defaultLanguage,
        definedLanguages: getDefinedLanguages(
          merchant.defaultLanguage,
          localizedFields.map(field => get(defaultValues, field.path))
        ),
      }),
      ...defaultValues,
    },
    resolver: zodResolver(schema),
  });

  const {
    setError,
    clearErrors,
    setValue,
    getValues,
    handleSubmit: hookFormHandleSubmit,
    formState,
  } = methods;

  usePrompt(t('promptLostChangesWarning'), warnOnUnsavedChanges && formState.isDirty);

  const mutationMethods = useCohortMutation({
    mutationKey: ['uploadAsset'],
    mutationFn: async ({file, assetKind}: {file: File; assetKind: AdminAssetKind}) =>
      uploadAsset(file, assetKind, merchant.id),
    onError: () => notify('error', t('errorFileUploadFailed')),
  });
  const {mutateAsync} = mutationMethods;
  const ongoingUploads = useIsMutating({
    mutationKey: ['uploadAsset'],
  });

  const handleSubmit = useCallback(
    (onValid: CohortSubmitHandler<TFieldValues>, onInvalid?: SubmitErrorHandler<TFieldValues>) =>
      (e?: React.BaseSyntheticEvent) =>
        hookFormHandleSubmit(
          async data => {
            try {
              setIsLoading(true);
              applyLanguageFieldValidation(
                isLocalized,
                localizedFields,
                getValues as UseFormGetValues<FieldValues>
              );
              const uploadedAssets = await uploadAssets(data, formAssets, mutateAsync);

              await onValid(data, uploadedAssets);
            } catch (error) {
              if (error instanceof z.ZodError) {
                handleFormErrors(error, clearErrors, setError);
                handleLanguageSelection(
                  isLocalized,
                  data.definedLanguages,
                  data.defaultLanguage,
                  error,
                  setValue
                );
              } else {
                throw error;
              }
            } finally {
              setIsLoading(false);
            }
          },
          errors => {
            handleLanguageSelection(
              isLocalized,
              getValues('definedLanguages' as Path<TFieldValues>),
              getValues('defaultLanguage' as Path<TFieldValues>),
              errors,
              setValue
            );
            onInvalid?.(errors);
          }
        )(e),
    [
      clearErrors,
      formAssets,
      getValues,
      hookFormHandleSubmit,
      isLocalized,
      localizedFields,
      mutateAsync,
      setError,
      setValue,
    ]
  );

  const handleManualSubmit = useCallback(
    async <TCustomSchema extends ZodType>(
      customSchema: TCustomSchema,
      onSubmit: CohortSubmitHandler<z.infer<TCustomSchema>>
    ) => {
      try {
        setIsLoading(true);
        clearErrors();
        const currentValues = getValues();
        const validatedData = customSchema.parse(currentValues);
        const localizedFields = getLocalizedFields(customSchema);
        const typedAssets = formAssets as Array<FormAsset<typeof validatedData>> | null;

        applyLanguageFieldValidation(
          isLocalized,
          localizedFields,
          getValues as UseFormGetValues<FieldValues>
        );
        const uploadedAssets = await uploadAssets(validatedData, typedAssets, mutateAsync);

        await onSubmit(validatedData, uploadedAssets as UploadedAssets<z.infer<TCustomSchema>>);
      } catch (error) {
        if (error instanceof z.ZodError) {
          handleFormErrors(error, clearErrors, setError);
          handleLanguageSelection(
            isLocalized,
            getValues('definedLanguages' as Path<TFieldValues>),
            getValues('defaultLanguage' as Path<TFieldValues>),
            error,
            setValue
          );
        } else {
          throw error;
        }
      } finally {
        setIsLoading(false);
      }
    },
    [clearErrors, formAssets, getValues, isLocalized, mutateAsync, setError, setValue]
  );

  const value = {
    ...methods,
    setFormAssets,
    isLoading,
    handleSubmit,
    handleManualSubmit,
    upload: {
      ...mutationMethods,
      isLoading: ongoingUploads > 0,
    },
    isLocalized,
    localizedFields,
  };

  // @ts-expect-error - issue with a type on watch method, cannot figure out why
  return <CohortFormContext.Provider value={value}>{children}</CohortFormContext.Provider>;
}
