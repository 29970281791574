import type {TriggerIntegration} from '@cohort/merchants/apps';
import CohortVisitLinkTriggerIntegrationActionCtaComponentPreview from '@cohort/merchants/apps/cohort/triggerIntegrations/visitLink/ActionCtaComponentPreview';
import CohortVisitLinkTriggerIntegrationConfigComponent from '@cohort/merchants/apps/cohort/triggerIntegrations/visitLink/ConfigComponent';
import CohortVisitLinkTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/cohort/triggerIntegrations/visitLink/StepCompletionTitleComponent';
import CohortVisitLinkTriggerIntegrationComponentPreview from '@cohort/merchants/apps/cohort/triggerIntegrations/visitLink/TriggerComponentPreview';
import type {CohortVisitLinkTriggerStruct} from '@cohort/shared/apps/cohort/triggers/visitLink';
import {CohortVisitLinkTriggerIntegrationSpec} from '@cohort/shared/apps/cohort/triggers/visitLink';

export const CohortVisitLinkTriggerIntegration: TriggerIntegration<CohortVisitLinkTriggerStruct> = {
  spec: CohortVisitLinkTriggerIntegrationSpec,
  configComponent: CohortVisitLinkTriggerIntegrationConfigComponent,
  stepCompletionTitleComponent: CohortVisitLinkTriggerIntegrationStepCompletionTitleComponent,
  getTitle: t => t('triggerIntegrations.visit-link.title', {ns: 'app-cohort'}),
  preview: {
    CtaComponent: CohortVisitLinkTriggerIntegrationActionCtaComponentPreview,
    TriggerComponent: CohortVisitLinkTriggerIntegrationComponentPreview,
  },
};
