import LinkPreviewCard from '@cohort/components-xps/components/apps/LinkPreviewCard';
import type {TriggerComponentPreviewProps} from '@cohort/merchants/apps';
import type {CohortVisitLinkTriggerStruct} from '@cohort/shared/apps/cohort/triggers/visitLink';

const CohortVisitLinkTriggerIntegrationComponentPreview: React.FC<
  TriggerComponentPreviewProps<CohortVisitLinkTriggerStruct>
> = ({config}) => {
  if (!config.title && !config.imageUrl) {
    return null;
  }

  return <LinkPreviewCard title={config.title} description={config.url} image={config.imageUrl} />;
};

export default CohortVisitLinkTriggerIntegrationComponentPreview;
