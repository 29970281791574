import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import DescriptionModeSelect from '@cohort/merchants/apps/common/DescriptionModeSelect';
import StepContentInput from '@cohort/merchants/apps/common/StepContentInput';
import StepTitleInput from '@cohort/merchants/apps/common/StepTitleInput';
import type {Defaults} from '@cohort/merchants/apps/common/triggers/utils';
import {defaultMedia, parseDefaults} from '@cohort/merchants/apps/common/triggers/utils';
import {getDescriptionMode} from '@cohort/merchants/apps/common/triggers/utils';
import ConnectionPicker from '@cohort/merchants/components/connections/ConnectionPicker';
import LanguageSelectorInput from '@cohort/merchants/components/form/LanguageSelectorInput';
import Separator from '@cohort/merchants/components/Separator';
import {useConnectionProfile} from '@cohort/merchants/hooks/api/Connections';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {InstagramMerchantConnectorStruct} from '@cohort/shared/apps/instagram/merchantConnector';
import type {InstagramTagInPostTriggerStruct} from '@cohort/shared/apps/instagram/triggers/tagInPost';
import type {Language} from '@cohort/shared/schema/common';
import {Fragment, useEffect, useRef} from 'react';

const getDefaults = (definedLanguages: Language[]): Defaults => {
  const defaults = {
    title: {
      en: `Tag us in a post`,
      fr: `Mentionnez-nous dans une publication`,
      es: `Etiqueta a nosotros en una publicación`,
    },
    description: {},
  };
  return parseDefaults(defaults, definedLanguages);
};

const InstagramTagInPostTriggerConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps<InstagramTagInPostTriggerStruct>
> = ({formReturn: {watch, setValue, reset, control, register}, trigger, step}) => {
  const init = useRef(false);
  const merchant = useCurrentMerchant();

  const [definedLanguages, connectionId] = watch(['definedLanguages', 'trigger.connectionId']);

  const {data: profile} = useConnectionProfile<InstagramMerchantConnectorStruct>(
    merchant.id,
    connectionId ?? '',
    {enabled: !!connectionId && init.current}
  );

  useEffect(() => {
    if (profile) {
      setValue('trigger.triggerIntegrationConfig.merchantUsername', profile.properties.username);
    }
  }, [setValue, profile]);

  useEffect(() => {
    if (profile) {
      const defaults = getDefaults(definedLanguages);
      setValue('defaultTitle', defaults.title);
      setValue('defaultDescription', defaults.description);
    }
  }, [definedLanguages, profile, setValue]);

  useEffect(() => {
    if (init.current) {
      return;
    }
    const defaults = getDefaults(definedLanguages);
    reset(formValues => ({
      ...formValues,
      defaultTitle: defaults.title,
      defaultDescription: defaults.description,
      descriptionMode: getDescriptionMode(definedLanguages, defaults, step),
      title: step?.title ?? defaults.title,
      description: step?.description ?? defaults.description,
      icon: step?.icon !== undefined ? step.icon : '📸',
      media: step?.media ?? defaultMedia,
      trigger: {
        id: trigger?.id ?? null,
        connectionId: null,
        triggerIntegrationId: 'instagram.tag-in-post',
        triggerIntegrationConfig: {
          merchantUsername: trigger?.triggerIntegrationConfig.merchantUsername ?? '',
        },
      },
    }));
    init.current = true;
  }, [definedLanguages, profile, reset, step, trigger]);

  if (!init.current) {
    return null;
  }

  return (
    <Fragment>
      <div className="max-w-sm">
        <ConnectionPicker
          appId="instagram"
          control={control}
          register={register}
          connectionIdFieldName="trigger.connectionId"
        />
      </div>
      <DescriptionModeSelect />
      <LanguageSelectorInput />
      <StepTitleInput />
      <Separator />
      <StepContentInput />
    </Fragment>
  );
};

export default InstagramTagInPostTriggerConfigComponent;
