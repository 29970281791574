import {DEFAULT_ACCENT_COLOR} from '@cohort/shared/schema/common';
import Spinner from '@cohort/shared-frontend/components/Spinner';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {isDark} from '@cohort/shared-frontend/utils/isDark';
import type {VariantProps} from 'class-variance-authority';
import {cva} from 'class-variance-authority';
import React from 'react';

export const buttonVariants = cva(
  cn(
    'inline-flex items-center justify-center rounded-lg border px-4 py-3 text-sm gap-2',
    'font-medium focus:outline-none whitespace-nowrap ring-offset-background transition-colors',
    'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
    'disabled:pointer-events-none disabled:opacity-60'
  ),
  {
    variants: {
      variant: {
        primary: cn(
          'text-[--xps-primary-btn-color] border-[--xps-primary-btn-border-color]',
          'bg-[--xps-primary-btn-background-color] hover:text-[--xps-primary-btn-hover-color]',
          'hover:bg-[--xps-primary-btn-hover-background-color]'
        ),
        secondary: cn(
          'bg-[--xps-secondary-btn-background-color] hover:bg-[--xps-secondary-btn-hover-background-color]',
          'hover:text-[--xps-secondary-btn-hover-color] border-[--xps-secondary-btn-border-color]',
          'text-[--xps-secondary-btn-color]'
        ),
        link: 'text-[--xps-link-text-color] border-none !p-0 underline',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  }
);

export type ButtonBaseProps = Pick<JSX.IntrinsicElements['button'], 'className' | 'type'> & {
  asChild?: boolean;
  loading?: boolean;
  size?: 'default' | 'small' | 'large';
  testId?: string;
  text?: JSX.Element | string;
  accentColor?: string;
};

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants>,
    ButtonBaseProps {}

type BtnTextTransform = 'none' | 'uppercase' | 'lowercase' | 'capitalize';

const ButtonPreview = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      accentColor,
      className,
      testId,
      variant,
      size = 'default',
      loading,
      disabled,
      type,
      children,
      style,
      ...props
    },
    ref
  ) => {
    const hasDarkAccent = isDark(accentColor ?? DEFAULT_ACCENT_COLOR);
    const isDisabled = disabled || loading;
    const btnType = type || 'button';
    const defaultVariant = variant || 'primary';

    return (
      <button
        data-testid={testId}
        className={cn(
          buttonVariants({variant, className}),
          disabled && 'cursor-not-allowed text-opacity-50',
          size === 'small' && '!py-1.5',
          size === 'large' && '!py-3.5'
        )}
        {...props}
        type={btnType}
        disabled={isDisabled}
        ref={ref}
        style={{
          borderRadius:
            size === 'small'
              ? `calc(var(--xps-${defaultVariant}-btn-border-radius) * 0.75)`
              : `var(--xps-${defaultVariant}-btn-border-radius)`,
          textTransform: `var(--xps-${defaultVariant}-btn-text-transform)` as BtnTextTransform,
          ...style,
        }}
      >
        {loading && <Spinner color={hasDarkAccent ? '#ffffff' : '#000000'} size={20} />}
        {children}
      </button>
    );
  }
);

export default ButtonPreview;
