import {
  SelectMediaConfig,
  SelectMediaConfigButton,
  SelectMediaConfigEmptyState,
} from '@cohort/merchants/apps/common/triggers/SelectMedia/SelectMediaConfig';
import {SelectMediaContextProvider} from '@cohort/merchants/apps/common/triggers/SelectMedia/SelectMediaContextProvider';
import SelectVideoSheet from '@cohort/merchants/apps/youtube/triggers/SelectVideoSheet';
import YoutubeVideoCard from '@cohort/merchants/apps/youtube/triggers/YoutubeVideoCard';
import type {YoutubeVideo} from '@cohort/shared/apps/youtube/common';
import {Fragment} from 'react/jsx-runtime';

type YoutubeVideoSelectorProps = {
  description: string;
  onSelect: (mediaId: string) => void;
  title: string;
  videos: YoutubeVideo[];
  selectedVideo?: YoutubeVideo;
};

const YoutubeVideoSelector: React.FC<YoutubeVideoSelectorProps> = ({
  description,
  onSelect,
  selectedVideo,
  title,
  videos,
}) => (
  <SelectMediaContextProvider defaultMediaId={selectedVideo?.id ?? null}>
    <SelectMediaConfig title={title} description={description}>
      {selectedVideo === undefined ? (
        <Fragment>
          <SelectMediaConfigEmptyState />
          <SelectMediaConfigButton mode="add" />
        </Fragment>
      ) : (
        <Fragment>
          <YoutubeVideoCard media={selectedVideo} />
          <SelectMediaConfigButton mode="change" />
        </Fragment>
      )}
      <SelectVideoSheet onSelect={onSelect} videos={videos} />
    </SelectMediaConfig>
  </SelectMediaContextProvider>
);

export default YoutubeVideoSelector;
