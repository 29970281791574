import {Widget} from '@typeform/embed-react';

type TypeformWidgetProps = {
  formId: string;
  isMobile: boolean;
};

const TypeformWidget: React.FC<TypeformWidgetProps> = ({formId, isMobile}) => {
  return (
    <Widget
      id={formId}
      style={{width: '100%', height: isMobile ? '100%' : '800px'}}
      inlineOnMobile
      iframeProps={{
        // We disable the iframe click verification hook to prevent blur
        // when the user clicks on the widget leading to weird behavior with the form inputs
        // @ts-expect-error - type issue with data-*
        'data-ignore-iframe-click-verification': 'true',
      }}
    />
  );
};

export default TypeformWidget;
