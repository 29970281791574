import FacebookEmbed from '@cohort/components-xps/components/apps/facebook/FacebookEmbedPost';
import type {TriggerComponentPreviewProps} from '@cohort/merchants/apps';
import type {FacebookLikePageTriggerStruct} from '@cohort/shared/apps/facebook/triggers/likePage';

const FacebookLikePageTriggerIntegrationComponentPreview: React.FC<
  TriggerComponentPreviewProps<FacebookLikePageTriggerStruct>
> = ({config, isMobile}) => {
  if (config.pageId.length < 1) {
    return null;
  }
  return (
    <FacebookEmbed
      type="page"
      url={`https://www.facebook.com/${config.pageId}`}
      width={isMobile ? '350px' : '400px'}
    />
  );
};

export default FacebookLikePageTriggerIntegrationComponentPreview;
