import type {WrappedField} from '@cohort/merchants/components/form/FieldWrapper';
import FieldWrapper from '@cohort/merchants/components/form/FieldWrapper';
import type {SuggestionsRefProps} from '@cohort/merchants/components/form/textEditor/Suggestions';
import Suggestions from '@cohort/merchants/components/form/textEditor/Suggestions';
import {MenuBar} from '@cohort/merchants/components/rich-text-editor/MenuBar';
import {UserPSchema} from '@cohort/shared/schema/public/users';
import {getPropertiesPathFromZodSchema} from '@cohort/shared/utils/zod';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Document} from '@tiptap/extension-document';
import {Link} from '@tiptap/extension-link';
import {Mention} from '@tiptap/extension-mention';
import {Paragraph} from '@tiptap/extension-paragraph';
import {Placeholder} from '@tiptap/extension-placeholder';
import {Text} from '@tiptap/extension-text';
import type {AnyExtension} from '@tiptap/react';
import {EditorContent, mergeAttributes, ReactRenderer, useEditor} from '@tiptap/react';
import {StarterKit} from '@tiptap/starter-kit';
import {useEffect} from 'react';
import type {Instance} from 'tippy.js';
import tippy from 'tippy.js';
import {z} from 'zod';

const DefaultSuggestionsSchema = z.object({
  user: UserPSchema,
});
const suggestionsList = getPropertiesPathFromZodSchema(DefaultSuggestionsSchema);

type Extension = 'starterKit' | 'link' | 'mention';

type ExtentionContext = {
  suggestions: string[];
};

export type BaseTextEditorProps = WrappedField & {
  name: string;
  value: string;
  onChange: (value: string | null) => void;
  onBlur: () => void;
  error?: string;
  placeholder?: string;
  disabled?: boolean;
  optional?: boolean;
  suggestions?: string[];
  extensions?: Extension[];
  editorClassName?: string;
};

const extensionsConfiguration: Record<Extension, (context: ExtentionContext) => AnyExtension> = {
  starterKit: () => StarterKit,
  link: () =>
    Link.configure({
      linkOnPaste: true,
      openOnClick: true,
    }),
  mention: ({suggestions}) =>
    Mention.configure({
      renderHTML: ({options, node}) => [
        'span',
        mergeAttributes(
          {class: 'bg-purple-50 text-primary p-1 rounded-md font-medium'},
          options.HTMLAttributes
        ),
        `{{${node.attrs.label ?? node.attrs.id}}}`,
      ],
      suggestion: {
        char: '{{',
        items: ({query}: {query: string}) =>
          [...suggestionsList, ...suggestions].filter(item =>
            item.toLowerCase().startsWith(query.toLowerCase())
          ),
        render: () => {
          let component: ReactRenderer<SuggestionsRefProps> | null = null;
          let popup: Instance[] | null = null;
          let clientRect: (() => DOMRect) | null = null;

          return {
            onStart: props => {
              component = new ReactRenderer(Suggestions, {
                props,
                editor: props.editor,
              });
              const rect = props.clientRect?.();

              if (!rect) {
                return;
              }
              clientRect = () => rect;

              popup = tippy('body', {
                getReferenceClientRect: clientRect,
                appendTo: () => document.body,
                content: component.element,
                showOnCreate: true,
                interactive: true,
                trigger: 'manual',
                placement: 'bottom-start',
              });
            },

            onUpdate(props) {
              component?.updateProps(props);

              if (!clientRect) {
                return;
              }
              popup?.[0]?.setProps({
                getReferenceClientRect: clientRect,
              });
            },

            onKeyDown(props: {event: KeyboardEvent}) {
              if (props.event.key === 'Escape') {
                popup?.[0]?.hide();
                return true;
              }

              return component?.ref?.onKeyDown(props) ?? false;
            },

            onExit() {
              popup?.[0]?.destroy();
              component?.destroy();
            },
          };
        },
      },
    }),
};

const BaseTextEditor: React.FC<BaseTextEditorProps> = ({
  label,
  description,
  name,
  placeholder,
  disabled,
  value,
  error,
  onChange,
  onBlur,
  optional,
  editorClassName,
  extensions = ['starterKit', 'link'],
  suggestions = [],
}) => {
  const extensionContext: ExtentionContext = {suggestions: suggestions};
  const editor = useEditor({
    content: value,
    editable: !disabled,
    extensions: [
      ...(!extensions.includes('starterKit') ? [Document, Text, Paragraph] : []),
      Placeholder.configure({placeholder}),
      ...extensions.map((ext: Extension) => extensionsConfiguration[ext](extensionContext)),
    ],
    onUpdate: ({editor}) => onChange(editor.isEmpty ? null : editor.getHTML()),
    onBlur: () => onBlur(),
    parseOptions: {
      preserveWhitespace: 'full',
    },
  });

  useEffect(() => {
    if (editor && editor.getHTML() !== value) {
      editor.commands.setContent(value, false);
    }
    if (editor && error) {
      editor.commands.focus();
    }
  }, [error, editor, value]);

  useEffect(() => {
    if (editor) {
      const defaultClasses = 'p-2 prose-sm cursor-text m-0 outline-primary h-full min-h-[100px]';
      const disabledClasses = 'bg-slate-100 cursor-not-allowed text-slate-400';

      editor.setOptions({
        editorProps: {
          attributes: {
            class: cn(defaultClasses, disabled && disabledClasses, editorClassName),
            'data-testid': name,
          },
        },
      });
    }
  }, [disabled, editor, name, editorClassName]);

  return (
    <FieldWrapper
      label={label}
      name={name}
      description={description}
      error={error}
      optional={optional}
    >
      <div
        className={cn(
          'grid h-full w-full rounded-md border bg-white text-sm shadow-sm [grid-template-rows:min-content_1fr]',
          disabled && 'opacity-50'
        )}
      >
        {editor !== null && extensions.includes('starterKit') && (
          <MenuBar editor={editor} disabled={disabled} />
        )}
        <EditorContent name={name} editor={editor} disabled={disabled} />
      </div>
    </FieldWrapper>
  );
};

export default BaseTextEditor;
