import type {PreviewCtaComponentProps} from '@cohort/merchants/apps';
import ActionCtaPreview from '@cohort/merchants/apps/common/ActionCtaPreview';
import type {FacebookCommentPostTriggerStruct} from '@cohort/shared/apps/facebook/triggers/commentPost';
import {useTranslation} from 'react-i18next';

const FacebookCommentPostTriggerActionCtaComponentPreview: React.FC<
  PreviewCtaComponentProps<FacebookCommentPostTriggerStruct>
> = ({localizationConfig}) => {
  const {t} = useTranslation('app-facebook', {
    keyPrefix: 'triggers.comment-post',
    lng: localizationConfig.desiredLanguage,
  });
  return <ActionCtaPreview text={t('ctaTitle')} withRefresh />;
};

export default FacebookCommentPostTriggerActionCtaComponentPreview;
