import type {TriggerIntegration} from '@cohort/merchants/apps';
import CohortFormCheckboxTriggerConfigComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/checkbox/ConfigComponent';
import CohortFormCheckboxTriggerIntegrationComponentPreview from '@cohort/merchants/apps/cohort-form/triggerIntegrations/checkbox/TriggerComponentPreview';
import CohortFormTriggerIntegrationActionCtaComponentPreview from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/ActionCtaComponentPreview';
import StepCompletionContextComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/StepCompletionContextComponent';
import StepCompletionsGridComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/StepCompletionsGridComponent';
import StepCompletionTitleComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/StepCompletionTitleComponent';
import useStepCompletionColumns from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/useStepCompletionColumns';
import type {CohortFormCheckboxTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/checkbox';
import {CohortFormCheckboxTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/checkbox';

export const CohortFormCheckboxTriggerIntegration: TriggerIntegration<CohortFormCheckboxTriggerStruct> =
  {
    spec: CohortFormCheckboxTriggerIntegrationSpec,
    configComponent: CohortFormCheckboxTriggerConfigComponent,
    stepCompletionTitleComponent: StepCompletionTitleComponent,
    stepCompletionContextComponent: StepCompletionContextComponent,
    useStepCompletionColumns: useStepCompletionColumns,
    stepCompletionsGridComponent: StepCompletionsGridComponent,
    getTitle: t => t('triggerIntegrations.checkbox.title', {ns: 'app-cohort-form'}),
    preview: {
      CtaComponent: CohortFormTriggerIntegrationActionCtaComponentPreview,
      TriggerComponent: CohortFormCheckboxTriggerIntegrationComponentPreview,
    },
  };
