import FacebookTagInPostTriggerIntegrationInstructionsComponent from '@cohort/components-xps/components/apps/facebook/triggers/tagInPost/InstructionsComponent';
import type {TriggerIntegration} from '@cohort/merchants/apps';
import useSocialStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useSocialStepCompletionColumns';
import FacebookTagInPostTriggerConfigComponent from '@cohort/merchants/apps/facebook/triggers/tagInPost/ConfigComponent';
import FacebookTagInPostTriggerIntegrationStepCompletionContextComponent from '@cohort/merchants/apps/facebook/triggers/tagInPost/StepCompletionContextComponent';
import FacebookTagInPostTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/facebook/triggers/tagInPost/StepCompletionTitleComponent';
import type {FacebookTagInPostTriggerStruct} from '@cohort/shared/apps/facebook/triggers/tagInPost';
import {FacebookTagInPostTriggerSpec} from '@cohort/shared/apps/facebook/triggers/tagInPost';

export const FacebookTagInPostTriggerIntegration: TriggerIntegration<FacebookTagInPostTriggerStruct> =
  {
    spec: FacebookTagInPostTriggerSpec,
    configComponent: FacebookTagInPostTriggerConfigComponent,
    stepCompletionTitleComponent: FacebookTagInPostTriggerIntegrationStepCompletionTitleComponent,
    stepCompletionContextComponent:
      FacebookTagInPostTriggerIntegrationStepCompletionContextComponent,
    useStepCompletionColumns: context =>
      useSocialStepCompletionColumns<FacebookTagInPostTriggerStruct>({
        ...context,
        userNameKey: 'username',
      }),
    getTitle: t => t('triggers.tag-in-post.title', {ns: 'app-facebook'}),
    preview: {
      InstructionsComponent: FacebookTagInPostTriggerIntegrationInstructionsComponent,
    },
  };
