import CohortVideoVisualPreviewContent from '@cohort/components-xps/components/contents/apps/cohort/video/VisualPreviewContent';
import type {VisualPreviewProps} from '@cohort/merchants/apps';
import type {CohortVideoMediaStruct} from '@cohort/shared/apps/cohort/medias/video';

const CohortVideoVisualPreview: React.FC<VisualPreviewProps<CohortVideoMediaStruct>> = ({
  config,
  title,
  imageSize = 'XL',
}) => (
  <CohortVideoVisualPreviewContent
    imageSize={imageSize}
    thumbnailFileKey={config.thumbnailFileKey}
    title={title}
  />
);

export default CohortVideoVisualPreview;
