import type {TriggerComponentPreviewProps} from '@cohort/merchants/apps';
import InputPreview from '@cohort/merchants/components/campaigns/preview/InputPreview';
import type {CohortFormDateTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/date';

const CohortFormDateTriggerIntegrationComponentPreview: React.FC<
  TriggerComponentPreviewProps<CohortFormDateTriggerStruct>
> = () => {
  return <InputPreview width="w-[350px]" type="date" />;
};

export default CohortFormDateTriggerIntegrationComponentPreview;
