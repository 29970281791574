import type {PerkUsageViewComponentProps} from '@cohort/merchants/apps';
import {PerkUsageLayout} from '@cohort/merchants/components/perks/PerkUsageLayout';
import type {CohortLinkConfig} from '@cohort/shared/apps/cohort/perks/link';
import {useTranslation} from 'react-i18next';

const CohortLinkPerkIntegrationsUsageViewComponent: React.FC<PerkUsageViewComponentProps> = ({
  usage,
}) => {
  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'perkIntegrations.link.usageViewComponent',
  });
  return (
    <PerkUsageLayout.Grid>
      <PerkUsageLayout.Row
        name={t('userVisitedLink')}
        value={(usage.integrationConfig as CohortLinkConfig).url}
      />
    </PerkUsageLayout.Grid>
  );
};

export default CohortLinkPerkIntegrationsUsageViewComponent;
