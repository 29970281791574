import type {PreviewCtaComponentProps} from '@cohort/merchants/apps';
import ActionCtaPreview from '@cohort/merchants/apps/common/ActionCtaPreview';
import type {TikTokFollowTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/follow';
import {useTranslation} from 'react-i18next';

const TikTokFollowTriggerActionCtaComponentPreview: React.FC<
  PreviewCtaComponentProps<TikTokFollowTriggerStruct>
> = ({localizationConfig}) => {
  const {t} = useTranslation('app-tiktok', {
    keyPrefix: 'triggers.follow',
    lng: localizationConfig.desiredLanguage,
  });
  return <ActionCtaPreview text={t('ctaTitle')} />;
};

export default TikTokFollowTriggerActionCtaComponentPreview;
