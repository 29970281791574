import type {TriggerIntegration} from '@cohort/merchants/apps';
import useCompleteActivityStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useCompleteActivityStepCompletionColumns';
import StravaCompleteActivityTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/strava/triggerIntegratons/completeActivity/CompletionSuccessComponent';
import type {StravaCompleteActivityTriggerStruct} from '@cohort/shared/apps/strava/triggers/completeActivity';
import {StravaCompleteActivityTriggerIntegrationSpec} from '@cohort/shared/apps/strava/triggers/completeActivity';

export const StravaCompleteActivityTriggerIntegration: TriggerIntegration<StravaCompleteActivityTriggerStruct> =
  {
    spec: StravaCompleteActivityTriggerIntegrationSpec,
    stepCompletionTitleComponent:
      StravaCompleteActivityTriggerIntegrationStepCompletionTitleComponent,
    useStepCompletionColumns: context =>
      useCompleteActivityStepCompletionColumns<StravaCompleteActivityTriggerStruct>({
        ...context,
        userNameKey: 'stravaUsername',
        activityTypeKey: 'activityType',
        durationSecondsKey: 'durationSeconds',
        distanceMetersKey: 'distanceMeters',
      }),
    getTitle: t => t('triggerIntegrations.complete-activity.title', {ns: 'app-strava'}),
  };
