import {
  SelectMediaConfig,
  SelectMediaConfigButton,
  SelectMediaConfigEmptyState,
} from '@cohort/merchants/apps/common/triggers/SelectMedia/SelectMediaConfig';
import {SelectMediaContextProvider} from '@cohort/merchants/apps/common/triggers/SelectMedia/SelectMediaContextProvider';
import FacebookPostCard from '@cohort/merchants/apps/facebook/triggers/FacebookPostCard';
import SelectPostSheet from '@cohort/merchants/apps/facebook/triggers/SelectPostSheet';
import type {FacebookPost} from '@cohort/shared/apps/facebook/common';
import {Fragment} from 'react/jsx-runtime';

type FacebookPostSelectorProps = {
  description: string;
  onSelectPost: (post: FacebookPost) => void;
  title: string;
  posts: FacebookPost[];
  selectedPost?: FacebookPost;
};

const FacebookPostSelector: React.FC<FacebookPostSelectorProps> = ({
  description,
  onSelectPost,
  selectedPost,
  title,
  posts,
}) => (
  <SelectMediaContextProvider defaultMediaId={selectedPost?.id ?? null}>
    <SelectMediaConfig title={title} description={description}>
      {selectedPost === undefined ? (
        <Fragment>
          <SelectMediaConfigEmptyState />
          <SelectMediaConfigButton mode="add" />
        </Fragment>
      ) : (
        <Fragment>
          <FacebookPostCard media={selectedPost} />
          <SelectMediaConfigButton mode="change" />
        </Fragment>
      )}
      <SelectPostSheet onSelectPost={onSelectPost} posts={posts} />
    </SelectMediaConfig>
  </SelectMediaContextProvider>
);

export default FacebookPostSelector;
