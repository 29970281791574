import ButtonPreview from '@cohort/merchants/components/campaigns/preview/ButtonPreview';
import {ArrowsClockwise} from '@phosphor-icons/react';

type Props = {
  text: string;
  withRefresh?: boolean;
};

const ActionCtaPreview: React.FC<Props> = ({text, withRefresh}) => {
  return (
    <div className="flex w-full gap-2">
      <ButtonPreview variant="primary" className="w-full">
        {text}
      </ButtonPreview>
      {withRefresh && (
        <ButtonPreview variant="secondary" className="border-0">
          <ArrowsClockwise size={20} />
        </ButtonPreview>
      )}
    </div>
  );
};
export default ActionCtaPreview;
