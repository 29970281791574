import type {ChallengeStepInputADto} from '@cohort/admin-schemas/challengeSteps';
import type {FacebookPost} from '@cohort/shared/apps/facebook/common';
import type {InstagramMedia} from '@cohort/shared/apps/instagram/common';
import type {SpotifyPlaylist} from '@cohort/shared/apps/spotify/schema';
import type {TikTokVideo} from '@cohort/shared/apps/tiktok/common';
import type {YoutubeVideo} from '@cohort/shared/apps/youtube/common';
import type {Language, LocalizedString} from '@cohort/shared/schema/common';
import dayjs from 'dayjs';
import {isEqual} from 'lodash';
import {pick} from 'remeda';

export type MediaItem =
  | YoutubeVideo
  | TikTokVideo
  | SpotifyPlaylist
  | FacebookPost
  | InstagramMedia;

export const groupMediasByMonth = <M extends MediaItem>(
  medias: M[],
  getMediaDate: (media: M) => Date
): Map<string, M[]> =>
  medias.reduce((result, media) => {
    const month = dayjs(getMediaDate(media)).format('MMMM YYYY');
    if (!result.has(month)) {
      result.set(month, []);
    }
    result.get(month)?.push(media);
    return result;
  }, new Map<string, M[]>());

export const getDescriptionMode = (
  definedLanguages: Language[],
  defaults: {title: LocalizedString; description: LocalizedString},
  step?: ChallengeStepInputADto
): 'default' | 'custom' => {
  if (
    !step ||
    isEqual(parseDefaults(defaults, definedLanguages), {
      title: step.title,
      description: step.description,
    })
  ) {
    return 'default';
  }
  return 'custom';
};

export type Defaults = {
  title: LocalizedString;
  description: LocalizedString;
};

export const parseDefaults = (defaults: Defaults, definedLanguages: Language[]): Defaults => ({
  title: pick(defaults.title, definedLanguages),
  description: pick(defaults.description, definedLanguages),
});

export const defaultMedia = {
  kind: 'no-media',
  config: {},
};
