import type {ConnectionADto} from '@cohort/admin-schemas/connection';
import {useApps} from '@cohort/merchants/apps/useApps';
import {Dialog, DialogContent} from '@cohort/merchants/components/modals/Dialog';
import type {AppStruct} from '@cohort/shared/apps/app';
import {Fragment, useState} from 'react';

type EditConnectionModalProps<T extends AppStruct> = {
  onClose: () => void;
  appId: T['Id'];
  connectionToEdit?: ConnectionADto;
};

const EditConnectionModal = <T extends AppStruct>({
  appId,
  onClose,
  connectionToEdit,
}: EditConnectionModalProps<T>): React.ReactElement => {
  const [connectionToConfigure, setConnectionToConfigure] = useState<ConnectionADto | undefined>(
    undefined
  );
  const {getApp} = useApps();
  const app = getApp(appId);

  if (app === null || !app.ConnectionEditComponent) {
    return <Fragment />;
  }
  return (
    <Dialog onOpenChange={onClose} open lightMode>
      <DialogContent displayCloseButton={false} className="min-w-[50%]">
        <app.ConnectionEditComponent
          existingConnection={connectionToEdit ?? connectionToConfigure}
          onClose={(connection: ConnectionADto | null) => {
            if (connection !== null && connection.status === 'config_needed') {
              setConnectionToConfigure(connection);
              return;
            }
            onClose();
          }}
          showCancelBtn
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditConnectionModal;
