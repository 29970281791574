import emptyStateLogo from '@cohort/merchants/apps/common/triggers/SelectMedia/emptyStateLogo.svg';
import useSelectMediaContext from '@cohort/merchants/apps/common/triggers/SelectMedia/useSelectMediaContext';
import type {MediaCardProps} from '@cohort/merchants/apps/common/triggers/TriggerMediaCardWrapper';
import type {MediaItem} from '@cohort/merchants/apps/common/triggers/utils';
import Button from '@cohort/merchants/components/buttons/Button';
import {
  EmptyState,
  EmptyStateContainer,
  EmptyStateDescription,
  EmptyStateTitle,
} from '@cohort/merchants/components/EmptyState';
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetTitle,
} from '@cohort/merchants/components/modals/Sheet';
import SearchInput from '@cohort/merchants/components/SearchInput';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import React from 'react';
import {useTranslation} from 'react-i18next';

type SelectMediaSheetProps = {
  title: string;
  children: React.ReactNode;
  onSubmit: (selectedMediaId: string) => void;
};

const SelectMediaSheet: React.FC<SelectMediaSheetProps> = ({children, onSubmit, title}) => {
  const {selectedMediaId, selectMediaSheetOpened, setSelectMediaSheetOpened} =
    useSelectMediaContext();

  const {t} = useTranslation('apps', {
    keyPrefix: 'common.triggers.selectMediaSheet',
  });

  return (
    <Sheet open={selectMediaSheetOpened} onOpenChange={setSelectMediaSheetOpened} lightMode>
      <SheetContent className="max-h-100vh flex flex-col gap-0 bg-white pt-6">
        <SheetTitle className="flex-shrink-0 px-6">{title}</SheetTitle>
        {children}
        <SheetFooter className="flex w-full !flex-row !justify-between border-t border-border px-6 py-4">
          <Button variant="secondary" onClick={() => setSelectMediaSheetOpened(false)}>
            {t('buttonCancel')}
          </Button>
          <Button
            onClick={() => {
              if (selectedMediaId !== null) {
                onSubmit(selectedMediaId);
                setSelectMediaSheetOpened(false);
              }
            }}
            disabled={selectedMediaId === null}
          >
            {t('buttonConfirm')}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

type SelectMediaSheetPanelProps = {
  children: React.ReactNode;
  isEmpty: boolean;
};

const SelectMediaSheetPanel: React.FC<SelectMediaSheetPanelProps> = ({children, isEmpty}) => (
  <div className="no-scrollbar m-6 flex flex-grow flex-col gap-4 overflow-y-auto rounded-lg border border-slate-200 bg-slate-50 p-4">
    {!isEmpty ? children : <SelectMediaSheetEmptyState />}
  </div>
);

const SelectMediaSheetEmptyState: React.FC = () => {
  const {t} = useTranslation('apps', {keyPrefix: 'common.triggers.selectMediaSheet'});

  return (
    <div className="flex h-full flex-col justify-center">
      <div className="flex h-full flex-col justify-center">
        <EmptyStateContainer>
          <EmptyState>
            <img src={emptyStateLogo} alt="No media empty state" />
            <EmptyStateTitle>{t('emptyTitle')}</EmptyStateTitle>
            <EmptyStateDescription>{t('emptyDescription')}</EmptyStateDescription>
          </EmptyState>
        </EmptyStateContainer>
      </div>
    </div>
  );
};

type SelectMediaSheetSearchInputProps = {
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
};

const SelectMediaSheetSearchInput: React.FC<SelectMediaSheetSearchInputProps> = ({
  searchQuery,
  setSearchQuery,
}) => {
  const {t} = useTranslation('apps', {
    keyPrefix: 'common.triggers.selectMediaSheet',
  });
  return (
    <div className="my-6 w-full flex-shrink-0 px-6">
      <SearchInput
        value={searchQuery}
        onChange={setSearchQuery}
        placeholder={t('placeholderSearch')}
        delay={300}
        className="w-1/2"
      />
    </div>
  );
};

type SelectMediaSheetCardsGroupProps = {
  label: string;
  children: React.ReactNode;
};

const SelectMediaSheetCardsGroup = ({
  label,
  children,
}: SelectMediaSheetCardsGroupProps): React.ReactNode => (
  <div className="flex flex-col space-y-3">
    <p className="text-sm text-slate-700">{label}</p>
    {children}
  </div>
);

type SelectMediaSheetCardsProps<M extends MediaItem> = {
  medias: M[];
  Card: React.FC<MediaCardProps<M>>;
  className?: string;
};

const SelectMediaSheetCards = <M extends MediaItem>({
  className,
  medias,
  Card,
}: SelectMediaSheetCardsProps<M>): React.ReactNode => {
  const {setSelectedMediaId, selectedMediaId} = useSelectMediaContext();

  return (
    <div className={cn('grid gap-4', className)}>
      {medias.map(item => {
        const isSelected = item.id === selectedMediaId;
        return (
          <Card
            key={item.id}
            media={item}
            isSelected={isSelected}
            onClick={() => setSelectedMediaId(item.id)}
          />
        );
      })}
    </div>
  );
};

export {
  SelectMediaSheet,
  SelectMediaSheetPanel,
  SelectMediaSheetSearchInput,
  SelectMediaSheetCardsGroup,
  SelectMediaSheetCards,
};
