import {SelectMediaSheet} from '@cohort/merchants/apps/common/triggers/SelectMedia/SelectMediaSheet';
import {
  SelectMediaSheetCards,
  SelectMediaSheetCardsGroup,
  SelectMediaSheetPanel,
  SelectMediaSheetSearchInput,
} from '@cohort/merchants/apps/common/triggers/SelectMedia/SelectMediaSheet';
import {groupMediasByMonth} from '@cohort/merchants/apps/common/triggers/utils';
import TikTokVideoCard from '@cohort/merchants/apps/tiktok/triggers/components/TikTokVideoCard';
import type {TikTokVideo} from '@cohort/shared/apps/tiktok/common';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

export type OnSelectVideo = (video: {videoId: string; url: string | undefined}) => void;

type SelectVideoSheetProps = {
  videos: TikTokVideo[];
  onSelectVideo: OnSelectVideo;
};
const SelectVideoSheet: React.FC<SelectVideoSheetProps> = ({videos, onSelectVideo}) => {
  const {t} = useTranslation('app-tiktok', {
    keyPrefix: 'triggers.components.selectVideoSheet',
  });

  const [searchQuery, setSearchQuery] = useState('');

  const onSubmit = (selectedMediaId: string): void => {
    onSelectVideo({
      videoId: selectedMediaId,
      url: videos.find(video => video.id === selectedMediaId)?.shareUrl,
    });
  };

  const filteredVideos = videos.filter(video =>
    video.title.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const mediasByMonth = groupMediasByMonth(filteredVideos, media => media.createdAt);

  return (
    <SelectMediaSheet onSubmit={onSubmit} title={t('title')}>
      <SelectMediaSheetSearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      <SelectMediaSheetPanel isEmpty={filteredVideos.length === 0}>
        {Array.from(mediasByMonth).map(([month, medias]) => (
          <SelectMediaSheetCardsGroup label={month} key={month}>
            <SelectMediaSheetCards medias={medias} Card={TikTokVideoCard} />
          </SelectMediaSheetCardsGroup>
        ))}
      </SelectMediaSheetPanel>
    </SelectMediaSheet>
  );
};

export default SelectVideoSheet;
