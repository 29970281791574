import type {PreviewCtaComponentProps} from '@cohort/merchants/apps';
import ActionCtaPreview from '@cohort/merchants/apps/common/ActionCtaPreview';
import type {FacebookLikePageUnverifiedTriggerStruct} from '@cohort/shared/apps/facebook/triggers/likePageUnverified';
import React from 'react';
import {useTranslation} from 'react-i18next';

const FacebookLikePageUnverifiedTriggerActionCtaComponentPreview: React.FC<
  PreviewCtaComponentProps<FacebookLikePageUnverifiedTriggerStruct>
> = ({localizationConfig}) => {
  const {t} = useTranslation('app-facebook', {
    keyPrefix: 'triggers.like-page-unverified',
    lng: localizationConfig.desiredLanguage,
  });
  return <ActionCtaPreview text={t('ctaTitle')} />;
};

export default FacebookLikePageUnverifiedTriggerActionCtaComponentPreview;
