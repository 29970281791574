import type {PreviewCtaComponentProps} from '@cohort/merchants/apps';
import ActionCtaPreview from '@cohort/merchants/apps/common/ActionCtaPreview';
import type {YoutubeCommentTriggerStruct} from '@cohort/shared/apps/youtube/triggers/comment';
import {useTranslation} from 'react-i18next';

const YoutubeCommentTriggerActionCtaComponentPreview: React.FC<
  PreviewCtaComponentProps<YoutubeCommentTriggerStruct>
> = ({localizationConfig}) => {
  const {t} = useTranslation('app-youtube', {
    keyPrefix: 'triggerIntegrations.comment',
    lng: localizationConfig.desiredLanguage,
  });
  return <ActionCtaPreview text={t('ctaTitle')} withRefresh />;
};

export default YoutubeCommentTriggerActionCtaComponentPreview;
