import type {TriggerIntegration} from '@cohort/merchants/apps';
import CohortFormSwipeCardsIntegrationActionCtaComponentPreview from '@cohort/merchants/apps/cohort-form/triggerIntegrations/swipeCards/ActionCtaComponentPreview';
import CohortSwipeCardsTriggerConfigComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/swipeCards/ConfigComponent';
import CohortSwipeCardsTriggerIntegrationStepCompletionContextComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/swipeCards/StepCompletionContextComponent';
import CohortSwipeCardsTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/swipeCards/StepCompletionTitleComponent';
import CohortFormSwipeCardsTriggerIntegrationComponentPreview from '@cohort/merchants/apps/cohort-form/triggerIntegrations/swipeCards/TriggerComponentPreview';
import useCohortSwipeCardsTriggerIntegrationStepCompletionColumns from '@cohort/merchants/apps/cohort-form/triggerIntegrations/swipeCards/useStepCompletionColumns';
import type {CohortSwipeCardsTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/swipeCards';
import {CohortSwipeCardsTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/swipeCards';

export const CohortSwipeCardsTriggerIntegration: TriggerIntegration<CohortSwipeCardsTriggerStruct> =
  {
    spec: CohortSwipeCardsTriggerIntegrationSpec,
    configComponent: CohortSwipeCardsTriggerConfigComponent,
    stepCompletionTitleComponent: CohortSwipeCardsTriggerIntegrationStepCompletionTitleComponent,
    stepCompletionContextComponent:
      CohortSwipeCardsTriggerIntegrationStepCompletionContextComponent,
    useStepCompletionColumns: useCohortSwipeCardsTriggerIntegrationStepCompletionColumns,
    getTitle: t => t('triggerIntegrations.swipe-cards.title', {ns: 'app-cohort-form'}),
    preview: {
      CtaComponent: CohortFormSwipeCardsIntegrationActionCtaComponentPreview,
      TriggerComponent: CohortFormSwipeCardsTriggerIntegrationComponentPreview,
    },
  };
