import TiktokFollowTriggerIntegrationInstructionsComponent from '@cohort/components-xps/components/apps/tiktok/triggers/follow/InstructionsComponent';
import type {TriggerIntegration} from '@cohort/merchants/apps';
import useSocialStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useSocialStepCompletionColumns';
import TikTokFollowTriggerActionCtaComponentPreview from '@cohort/merchants/apps/tiktok/triggers/follow/ActionCtaComponentPreview';
import TikTokFollowTriggerConfigComponent from '@cohort/merchants/apps/tiktok/triggers/follow/ConfigComponent';
import TikTokFollowTriggerStepCompletionTitleComponent from '@cohort/merchants/apps/tiktok/triggers/follow/StepCompletionTitleComponent';
import type {TikTokFollowTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/follow';
import {TikTokFollowTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/follow';

export const TikTokFollowTriggerIntegration: TriggerIntegration<TikTokFollowTriggerStruct> = {
  spec: TikTokFollowTriggerSpec,
  configComponent: TikTokFollowTriggerConfigComponent,
  stepCompletionTitleComponent: TikTokFollowTriggerStepCompletionTitleComponent,
  useStepCompletionColumns: context =>
    useSocialStepCompletionColumns<TikTokFollowTriggerStruct>({
      ...context,
      userNameKey: 'username',
    }),
  getTitle: t => t('triggers.follow.title', {ns: 'app-tiktok'}),
  preview: {
    InstructionsComponent: TiktokFollowTriggerIntegrationInstructionsComponent,
    CtaComponent: TikTokFollowTriggerActionCtaComponentPreview,
  },
};
