import type {TriggerIntegration} from '@cohort/merchants/apps';
import CohortFillFormTriggerIntegrationStepCompletionContextComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/fillForm/StepCompletionContextComponent';
import {CohortFillFormTriggerIntegrationStepCompletionsGridComponent} from '@cohort/merchants/apps/cohort-form/triggerIntegrations/fillForm/StepCompletionsGridComponent';
import CohortFillFormTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/fillForm/StepCompletionTitleComponent';
import useCohortFillFormTriggerIntegrationStepCompletionColumns from '@cohort/merchants/apps/cohort-form/triggerIntegrations/fillForm/useStepCompletionColumns';
import type {CohortFillFormTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/fillForm';
import {CohortFillFormTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/fillForm';

export const CohortFillFormTriggerIntegration: TriggerIntegration<CohortFillFormTriggerStruct> = {
  spec: CohortFillFormTriggerIntegrationSpec,
  stepCompletionTitleComponent: CohortFillFormTriggerIntegrationStepCompletionTitleComponent,
  stepCompletionContextComponent: CohortFillFormTriggerIntegrationStepCompletionContextComponent,
  useStepCompletionColumns: useCohortFillFormTriggerIntegrationStepCompletionColumns,
  stepCompletionsGridComponent: CohortFillFormTriggerIntegrationStepCompletionsGridComponent,
  getTitle: t => t('triggerIntegrations.fill-form.title', {ns: 'app-cohort-form'}),
};
