import {SelectMediaSheet} from '@cohort/merchants/apps/common/triggers/SelectMedia/SelectMediaSheet';
import {
  SelectMediaSheetCards,
  SelectMediaSheetPanel,
} from '@cohort/merchants/apps/common/triggers/SelectMedia/SelectMediaSheet';
import type {MediaCardProps} from '@cohort/merchants/apps/common/triggers/TriggerMediaCardWrapper';
import SpotifyPlaylistCard from '@cohort/merchants/apps/spotify/triggers/SpotifyPlaylistCard';
import type {SpotifyPlaylist} from '@cohort/shared/apps/spotify/schema';
import {useTranslation} from 'react-i18next';

type SelectPlaylistSheetProps = {
  playlists: SpotifyPlaylist[];
  onSelectPlaylist: (playlistId: string) => void;
};
const SelectPlaylistSheet: React.FC<SelectPlaylistSheetProps> = ({playlists, onSelectPlaylist}) => {
  const {t} = useTranslation('app-spotify', {
    keyPrefix: 'components.selectPlaylistSheet',
  });

  const onSubmit = (selectedMediaId: string): void => onSelectPlaylist(selectedMediaId);

  return (
    <SelectMediaSheet onSubmit={onSubmit} title={t('title')}>
      <SelectMediaSheetPanel isEmpty={playlists.length === 0}>
        <SelectMediaSheetCards
          medias={playlists}
          Card={SpotifyPlaylistCard as React.FC<MediaCardProps<SpotifyPlaylist>>}
          className="grid-cols-2 gap-x-4"
        />
      </SelectMediaSheetPanel>
    </SelectMediaSheet>
  );
};

export default SelectPlaylistSheet;
