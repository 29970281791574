import YoutubeVideoPlayer from '@cohort/components-xps/components/apps/youtube/YoutubeVideoPlayer';
import type {TriggerComponentPreviewProps} from '@cohort/merchants/apps';
import type {YoutubeLikeTriggerStruct} from '@cohort/shared/apps/youtube/triggers/like';
import {cn} from '@cohort/shared-frontend/utils/classNames';

const YoutubeLikeTriggerIntegrationComponentPreview: React.FC<
  TriggerComponentPreviewProps<YoutubeLikeTriggerStruct>
> = ({config, isMobile}) => {
  if (config.videoId.length < 1) {
    return null;
  }
  return (
    <div className={cn(isMobile ? 'w-[350px]' : 'w-full')}>
      <YoutubeVideoPlayer videoId={config.videoId} />
    </div>
  );
};

export default YoutubeLikeTriggerIntegrationComponentPreview;
