import type {TriggerComponentPreviewProps} from '@cohort/merchants/apps';
import TypeformWidget from '@cohort/merchants/apps/typeform/triggerIntegrations/TypeformWidgetPreview';
import type {TypeformTakeQuizTriggerStruct} from '@cohort/shared/apps/typeform/triggers/takeQuiz';
import {cn} from '@cohort/shared-frontend/utils/classNames';

const TypeformTakeQuizTriggerIntegrationComponentPreview: React.FC<
  TriggerComponentPreviewProps<TypeformTakeQuizTriggerStruct>
> = ({config, isMobile}) => {
  if (config.formId.length < 1) {
    return null;
  }
  return (
    <div className={cn('h-full w-[350px]')}>
      <TypeformWidget formId={config.formId} isMobile={isMobile} />
      <div className="absolute left-0 top-0 h-full w-full bg-transparent" />
    </div>
  );
};

export default TypeformTakeQuizTriggerIntegrationComponentPreview;
