import TiktokEmbedPost from '@cohort/components-xps/components/apps/tiktok/TiktokEmbedPost';
import type {TriggerComponentPreviewProps} from '@cohort/merchants/apps';
import type {TikTokCommentTiktokTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/commentTiktok';

const TiktokCommentTiktokTriggerIntegrationComponentPreview: React.FC<
  TriggerComponentPreviewProps<TikTokCommentTiktokTriggerStruct>
> = ({config, isMobile}) => {
  if (config.videoId.length < 1) {
    return null;
  }
  return <TiktokEmbedPost videoId={config.videoId} width={isMobile ? 350 : 400} />;
};

export default TiktokCommentTiktokTriggerIntegrationComponentPreview;
