import type {TriggerIntegration} from '@cohort/merchants/apps';
import StepCompletionContextComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/StepCompletionContextComponent';
import StepCompletionsGridComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/StepCompletionsGridComponent';
import StepCompletionTitleComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/StepCompletionTitleComponent';
import useStepCompletionColumns from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/useStepCompletionColumns';
import CohortFormMediaTriggerIntegrationActionCtaComponentPreview from '@cohort/merchants/apps/cohort-form/triggerIntegrations/media/ActionCtaComponent';
import CohortFormMediaTriggerConfigComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/media/ConfigComponent';
import CohortFormMediaTriggerIntegrationComponentPreview from '@cohort/merchants/apps/cohort-form/triggerIntegrations/media/TriggerComponentPreview';
import type {CohortFormMediaTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/media';
import {CohortFormMediaTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/media';

export const CohortFormMediaTriggerIntegration: TriggerIntegration<CohortFormMediaTriggerStruct> = {
  spec: CohortFormMediaTriggerIntegrationSpec,
  configComponent: CohortFormMediaTriggerConfigComponent,
  stepCompletionTitleComponent: StepCompletionTitleComponent,
  stepCompletionContextComponent: StepCompletionContextComponent,
  useStepCompletionColumns: useStepCompletionColumns,
  stepCompletionsGridComponent: StepCompletionsGridComponent,
  getTitle: t => t('triggerIntegrations.media.title', {ns: 'app-cohort-form'}),
  preview: {
    CtaComponent: CohortFormMediaTriggerIntegrationActionCtaComponentPreview,
    TriggerComponent: CohortFormMediaTriggerIntegrationComponentPreview,
  },
};
