import {RadioCards} from '@cohort/merchants/components/form/RadioCards';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {CreateEditStepModalType} from '@cohort/merchants/pages/campaigns/campaign/edit/settings/challenge/utils';
import {useTranslation} from 'react-i18next';

const DescriptionModeSelect: React.FC = () => {
  const {t} = useTranslation('apps', {
    keyPrefix: 'common.defaultSelect',
  });
  const {register, control} = useCohortForm<CreateEditStepModalType>();

  return (
    <RadioCards
      name="descriptionMode"
      direction="row"
      label={t('labelMode')}
      register={register}
      control={control}
      options={[
        {
          label: t('labelDefault'),
          value: 'default',
        },
        {
          label: t('labelCustom'),
          value: 'custom',
        },
      ]}
    />
  );
};
export default DescriptionModeSelect;
