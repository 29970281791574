import {Card} from '@cohort/components-xps/components/cards/Card';
import type {TriggerComponentPreviewProps} from '@cohort/merchants/apps';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {CohortSwipeCardsTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/swipeCards';
import {DEFAULT_BACKGROUND_COLOR} from '@cohort/shared/schema/common';
import {getLocalizedContent} from '@cohort/shared/utils/localization';
import {getImageUrl, Sizes} from '@cohort/shared/utils/media';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {isDark} from '@cohort/shared-frontend/utils/isDark';
import {isFile, isFileList} from '@cohort/shared-frontend/utils/isFile';

const MAX_PREVIEW_NUMBER = 3;

const CardImage: React.FC<{value: string | File | null}> = ({value}) => {
  if (!value || (isFileList(value) && value.length === 0)) {
    return null;
  }
  const src = isFile(value)
    ? URL.createObjectURL(value)
    : getImageUrl(import.meta.env.COHORT_ENV, value, {
        h: Sizes.S,
        w: Sizes.S,
      });
  return <img src={src} className="h-full w-full object-cover" />;
};

const CohortFormSwipeCardsTriggerIntegrationComponentPreview: React.FC<
  TriggerComponentPreviewProps<CohortSwipeCardsTriggerStruct>
> = ({config, localizationConfig}) => {
  const cards = config.cards;
  const merchant = useCurrentMerchant();
  const hasDarkBg = isDark(merchant.backgroundColorCode ?? DEFAULT_BACKGROUND_COLOR);

  const visibleCount = Math.min(MAX_PREVIEW_NUMBER, cards.length);

  return (
    <ul className="mt-16 grid list-none pb-8 [&>*]:[grid-column:1] [&>*]:[grid-row:1]">
      {cards
        .slice(0, 3)
        .reverse()
        .map((card, idx) => {
          const title = card.title ? getLocalizedContent(card.title, localizationConfig) : null;

          return (
            <div
              className="w-[300px] rounded-3xl"
              style={{
                backgroundColor: hasDarkBg ? '#424242' : '#F5F5F3',
                transform: `scale(${1 - (visibleCount - 1 - idx) * 0.1}) translateY(${(visibleCount - 1 - idx) * -30}px)`,
              }}
            >
              <Card className={cn('flex flex-col items-center justify-center gap-2')}>
                <div className="aspect-square w-full overflow-hidden">
                  <CardImage value={card.imageFileKey} />
                </div>
                {title && <p className="font-semibold">{title}</p>}
              </Card>
            </div>
          );
        })}
    </ul>
  );
};

export default CohortFormSwipeCardsTriggerIntegrationComponentPreview;
