import {SelectMediaSheet} from '@cohort/merchants/apps/common/triggers/SelectMedia/SelectMediaSheet';
import {
  SelectMediaSheetCards,
  SelectMediaSheetPanel,
} from '@cohort/merchants/apps/common/triggers/SelectMedia/SelectMediaSheet';
import FacebookPostCard from '@cohort/merchants/apps/facebook/triggers/FacebookPostCard';
import type {FacebookPost} from '@cohort/shared/apps/facebook/common';
import {useTranslation} from 'react-i18next';

type SelectPostSheetProps = {
  posts: FacebookPost[];
  onSelectPost: (post: FacebookPost) => void;
};
const SelectPostSheet: React.FC<SelectPostSheetProps> = ({posts, onSelectPost}) => {
  const {t} = useTranslation('app-facebook', {
    keyPrefix: 'components.selectPostSheet',
  });

  const onSubmit = (selectedMediaId: string): void => {
    const selectedPost = posts.find(post => post.id === selectedMediaId);
    if (selectedPost) {
      onSelectPost(selectedPost);
    }
  };

  return (
    <SelectMediaSheet onSubmit={onSubmit} title={t('title')}>
      <SelectMediaSheetPanel isEmpty={posts.length === 0}>
        <SelectMediaSheetCards medias={posts} Card={FacebookPostCard} />
      </SelectMediaSheetPanel>
    </SelectMediaSheet>
  );
};

export default SelectPostSheet;
