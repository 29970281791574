import YoutubeSubscribeTriggerIntegrationInstructionsComponent from '@cohort/components-xps/components/apps/youtube/triggers/subscribe/InstructionsComponent';
import type {TriggerIntegration} from '@cohort/merchants/apps';
import useSocialStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useSocialStepCompletionColumns';
import YoutubeSubscribeTriggerActionCtaComponentPreview from '@cohort/merchants/apps/youtube/triggers/subscribe/ActionCtaComponentPreview';
import YoutubeSubscribeTriggerIntegrationConfigComponent from '@cohort/merchants/apps/youtube/triggers/subscribe/ConfigComponent';
import YoutubeSubscribeTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/youtube/triggers/subscribe/StepCompletionTitleComponent';
import type {YoutubeSubscribeTriggerStruct} from '@cohort/shared/apps/youtube/triggers/subscribe';
import {YoutubeSubscribeTriggerSpec} from '@cohort/shared/apps/youtube/triggers/subscribe';

export const YoutubeSubscribeTriggerIntegration: TriggerIntegration<YoutubeSubscribeTriggerStruct> =
  {
    spec: YoutubeSubscribeTriggerSpec,
    configComponent: YoutubeSubscribeTriggerIntegrationConfigComponent,
    stepCompletionTitleComponent: YoutubeSubscribeTriggerIntegrationStepCompletionTitleComponent,
    useStepCompletionColumns: context =>
      useSocialStepCompletionColumns<YoutubeSubscribeTriggerStruct>({
        ...context,
        userNameKey: 'channelHandle',
      }),
    getTitle: t => t('triggerIntegrations.subscribe.title', {ns: 'app-youtube'}),
    preview: {
      InstructionsComponent: YoutubeSubscribeTriggerIntegrationInstructionsComponent,
      CtaComponent: YoutubeSubscribeTriggerActionCtaComponentPreview,
    },
  };
