import type {TriggerIntegration} from '@cohort/merchants/apps';
import useCompleteActivityStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useCompleteActivityStepCompletionColumns';
import GarminConnectCompleteActivityTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/garmin-connect/triggerIntegratons/completeActivity/StepCompletionTitleComponent';
import type {GarminConnectCompleteActivityTriggerStruct} from '@cohort/shared/apps/garmin-connect/triggers/completeActivity';
import {GarminConnectCompleteActivityTriggerIntegrationSpec} from '@cohort/shared/apps/garmin-connect/triggers/completeActivity';

export const GarminConnectCompleteActivityTriggerIntegration: TriggerIntegration<GarminConnectCompleteActivityTriggerStruct> =
  {
    spec: GarminConnectCompleteActivityTriggerIntegrationSpec,
    stepCompletionTitleComponent:
      GarminConnectCompleteActivityTriggerIntegrationStepCompletionTitleComponent,
    useStepCompletionColumns: context =>
      useCompleteActivityStepCompletionColumns<GarminConnectCompleteActivityTriggerStruct>({
        ...context,
        userNameKey: 'garminConnectUsername',
        activityTypeKey: 'activityType',
        durationSecondsKey: 'durationSeconds',
        distanceMetersKey: 'distanceMeters',
      }),
    getTitle: t => t('triggerIntegrations.complete-activity.title', {ns: 'app-garmin-connect'}),
  };
