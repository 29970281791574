import type {TriggerIntegration} from '@cohort/merchants/apps';
import CohortFormTriggerIntegrationActionCtaComponentPreview from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/ActionCtaComponentPreview';
import StepCompletionContextComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/StepCompletionContextComponent';
import StepCompletionsGridComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/StepCompletionsGridComponent';
import StepCompletionTitleComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/StepCompletionTitleComponent';
import useStepCompletionColumns from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/useStepCompletionColumns';
import CohortFormSelectTriggerConfigComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/select/ConfigComponent';
import CohortFormSelectTriggerIntegrationComponentPreview from '@cohort/merchants/apps/cohort-form/triggerIntegrations/select/TriggerComponentPreview';
import type {CohortFormSelectTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/select';
import {CohortFormSelectTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/select';

export const CohortFormSelectTriggerIntegration: TriggerIntegration<CohortFormSelectTriggerStruct> =
  {
    spec: CohortFormSelectTriggerIntegrationSpec,
    configComponent: CohortFormSelectTriggerConfigComponent,
    stepCompletionTitleComponent: StepCompletionTitleComponent,
    stepCompletionContextComponent: StepCompletionContextComponent,
    useStepCompletionColumns: useStepCompletionColumns,
    stepCompletionsGridComponent: StepCompletionsGridComponent,
    getTitle: t => t('triggerIntegrations.select.title', {ns: 'app-cohort-form'}),
    preview: {
      CtaComponent: CohortFormTriggerIntegrationActionCtaComponentPreview,
      TriggerComponent: CohortFormSelectTriggerIntegrationComponentPreview,
    },
  };
