import type {App, TriggerIntegration} from '@cohort/merchants/apps';
import {getTriggerIntegrations} from '@cohort/merchants/apps';
import {useApps} from '@cohort/merchants/apps/useApps';
import type {AppId} from '@cohort/shared/apps';
import type {TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {camelCase} from 'lodash';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

export type Output<T extends TriggerIntegrationStruct> = {
  getTriggerIntegration: (triggerIntegrationId: T['Id']) => TriggerIntegration<T>;
  getTriggerIntegrationApp: (integration: TriggerIntegration<T>) => App;
  getTriggerIntegrationTitle: (integration: TriggerIntegration<T>) => string;
  triggerIntegrationDisabled: (integration: TriggerIntegration<T>) => boolean;
};

export const useTriggerIntegrations = <T extends TriggerIntegrationStruct>(): Output<T> => {
  const {t} = useTranslation();
  const {appDisabled, getApp} = useApps();
  const flags = useFlags();

  const getTriggerIntegration = useCallback(
    (triggerIntegrationId: T['Id']): TriggerIntegration<T> => {
      const triggersIntegrations = getTriggerIntegrations();
      const triggerIntegration = triggersIntegrations.find(
        integration => integration.spec.id === triggerIntegrationId
      );

      if (!triggerIntegration) {
        throw new Error(
          `No TriggerIntegration found for triggerIntegrationId: ${triggerIntegrationId}`
        );
      }
      return triggerIntegration as unknown as TriggerIntegration<T>;
    },
    []
  );

  const getTriggerIntegrationApp = (integration: TriggerIntegration<T>): App => {
    const appId = integration.spec.id.split('.')[0];
    const app = getApp(appId);
    if (!app) {
      throw new Error(`App not found for ${integration.spec.id}`);
    }
    return app;
  };

  const getTriggerIntegrationTitle = (integration: TriggerIntegration<T>): string => {
    return integration.getTitle(t);
  };

  const triggerIntegrationDisabled = (integration: TriggerIntegration<T>): boolean => {
    if (integration.spec.deprecated) {
      return true;
    }
    const [appId, triggerIntegrationId] = integration.spec.id.split('.') as [AppId, string];
    if (appDisabled(appId)) {
      return true;
    }
    const flag = flags[camelCase(`apps.${appId}.triggers.${triggerIntegrationId}`)];
    return flag === false;
  };

  return {
    getTriggerIntegration,
    getTriggerIntegrationTitle,
    triggerIntegrationDisabled,
    getTriggerIntegrationApp,
  };
};
