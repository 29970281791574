import FacebookLikePostTriggerIntegrationInstructionsComponent from '@cohort/components-xps/components/apps/facebook/triggers/likePost/InstructionsComponent';
import type {TriggerIntegration} from '@cohort/merchants/apps';
import useSocialStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useSocialStepCompletionColumns';
import FacebookLikePostTriggerActionCtaComponentPreview from '@cohort/merchants/apps/facebook/triggers/likePost/ActionCtaComponentPreview';
import FacebookLikePostTriggerConfigComponent from '@cohort/merchants/apps/facebook/triggers/likePost/ConfigComponent';
import FacebookLikePostTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/facebook/triggers/likePost/StepCompletionTitleComponent';
import FacebookLikePostTriggerIntegrationComponentPreview from '@cohort/merchants/apps/facebook/triggers/likePost/TriggerComponentPreview';
import type {FacebookLikePostTriggerStruct} from '@cohort/shared/apps/facebook/triggers/likePost';
import {FacebookLikePostTriggerSpec} from '@cohort/shared/apps/facebook/triggers/likePost';

export const FacebookLikePostTriggerIntegration: TriggerIntegration<FacebookLikePostTriggerStruct> =
  {
    spec: FacebookLikePostTriggerSpec,
    configComponent: FacebookLikePostTriggerConfigComponent,
    stepCompletionTitleComponent: FacebookLikePostTriggerIntegrationStepCompletionTitleComponent,
    useStepCompletionColumns: context =>
      useSocialStepCompletionColumns<FacebookLikePostTriggerStruct>({
        ...context,
        userNameKey: 'username',
      }),
    getTitle: t => t('triggers.like-post.title', {ns: 'app-facebook'}),
    preview: {
      InstructionsComponent: FacebookLikePostTriggerIntegrationInstructionsComponent,
      CtaComponent: FacebookLikePostTriggerActionCtaComponentPreview,
      TriggerComponent: FacebookLikePostTriggerIntegrationComponentPreview,
    },
  };
