import type {TriggerIntegration} from '@cohort/merchants/apps';
import CohortFormTriggerIntegrationActionCtaComponentPreview from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/ActionCtaComponentPreview';
import StepCompletionContextComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/StepCompletionContextComponent';
import StepCompletionsGridComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/StepCompletionsGridComponent';
import StepCompletionTitleComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/StepCompletionTitleComponent';
import useStepCompletionColumns from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/useStepCompletionColumns';
import CohortFormTextTriggerConfigComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/text/ConfigComponent';
import CohortFormTextTriggerIntegrationComponentPreview from '@cohort/merchants/apps/cohort-form/triggerIntegrations/text/TriggerComponentPreview';
import type {CohortFormTextTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/text';
import {CohortFormTextTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/text';

export const CohortFormTextTriggerIntegration: TriggerIntegration<CohortFormTextTriggerStruct> = {
  spec: CohortFormTextTriggerIntegrationSpec,
  configComponent: CohortFormTextTriggerConfigComponent,
  stepCompletionTitleComponent: StepCompletionTitleComponent,
  stepCompletionContextComponent: StepCompletionContextComponent,
  useStepCompletionColumns: useStepCompletionColumns,
  stepCompletionsGridComponent: StepCompletionsGridComponent,
  getTitle: t => t('triggerIntegrations.text.title', {ns: 'app-cohort-form'}),
  preview: {
    CtaComponent: CohortFormTriggerIntegrationActionCtaComponentPreview,
    TriggerComponent: CohortFormTextTriggerIntegrationComponentPreview,
  },
};
