import type {TriggerComponentPreviewProps} from '@cohort/merchants/apps';
import ScoreInputPreview from '@cohort/merchants/components/campaigns/preview/ScoreInputPreview';
import type {CohortFormScoreTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/score';

const CohortFormScoreTriggerIntegrationComponentPreview: React.FC<
  TriggerComponentPreviewProps<CohortFormScoreTriggerStruct>
> = () => {
  return (
    <div className="flex justify-center">
      <ScoreInputPreview />
    </div>
  );
};

export default CohortFormScoreTriggerIntegrationComponentPreview;
