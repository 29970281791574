import type {TriggerIntegrationId} from '@cohort/shared/apps';
import type {CohortFormPromptType} from '@cohort/shared/schema/common/cohortForm';
import {CohortFormAnswerSchema} from '@cohort/shared/schema/common/cohortForm';
import {match} from 'ts-pattern';
import {z} from 'zod';

export const promptTypeFromIntegrationId = (
  triggerIntegrationId: TriggerIntegrationId
): CohortFormPromptType | null => {
  const promptType = match(triggerIntegrationId)
    .with('cohort-form.checkbox', () => 'checkbox' as const)
    .with('cohort-form.date', () => 'date' as const)
    .with('cohort-form.email', () => 'email' as const)
    .with('cohort-form.long-text', () => 'long-text' as const)
    .with('cohort-form.number', () => 'number' as const)
    .with('cohort-form.media', () => 'media' as const)
    .with('cohort-form.picture-choice', () => 'picture-choice' as const)
    .with('cohort-form.score', () => 'score' as const)
    .with('cohort-form.select', () => 'select' as const)
    .with('cohort-form.text', () => 'text' as const)
    .otherwise(() => null);
  return promptType;
};

export const VerificationDataSchema = z.object({
  answer: CohortFormAnswerSchema,
});
export type VerificationData = z.infer<typeof VerificationDataSchema>;
