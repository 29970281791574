import TiktokEmbedPost from '@cohort/components-xps/components/apps/tiktok/TiktokEmbedPost';
import type {TriggerComponentPreviewProps} from '@cohort/merchants/apps';
import type {TikTokRepostTiktokTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/repostTiktok';

const TiktokRepostTiktokTriggerIntegrationComponentPreview: React.FC<
  TriggerComponentPreviewProps<TikTokRepostTiktokTriggerStruct>
> = ({config, isMobile}) => {
  if (config.videoId.length < 1) {
    return null;
  }
  return <TiktokEmbedPost videoId={config.videoId} width={isMobile ? 350 : 400} />;
};

export default TiktokRepostTiktokTriggerIntegrationComponentPreview;
