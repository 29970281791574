import type {
  ChallengeStepADto,
  ChallengeStepTriggerADto,
} from '@cohort/admin-schemas/challengeSteps';
import type {StepCompletionADto} from '@cohort/admin-schemas/stepCompletions';
import {CohortFormAnswer} from '@cohort/merchants/apps/cohort-form/components/CohortFormAnswer';
import {
  promptTypeFromIntegrationId,
  VerificationDataSchema,
} from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/utils';
import Separator from '@cohort/merchants/components/Separator';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {formatDate} from '@cohort/shared/utils/format';
import Masonry from 'react-responsive-masonry';

const StepCompletionsGridComponent: React.FC<{
  step: ChallengeStepADto;
  stepTrigger: ChallengeStepTriggerADto;
  stepCompletions: StepCompletionADto[];
}> = ({stepCompletions, stepTrigger, step}) => (
  <Masonry columnsCount={3} gutter="16px">
    {stepCompletions.map(triggerCompletion => (
      <CohortFillFormVerificationAttemptCard
        key={triggerCompletion.id}
        triggerCompletion={triggerCompletion}
        stepTrigger={stepTrigger}
        step={step}
      />
    ))}
  </Masonry>
);

type CohortFillFormVerificationAttemptCardProps = {
  triggerCompletion: StepCompletionADto;
  stepTrigger: ChallengeStepTriggerADto;
  step: ChallengeStepADto;
};

const CohortFillFormVerificationAttemptCard: React.FC<
  CohortFillFormVerificationAttemptCardProps
> = ({triggerCompletion, stepTrigger, step}) => {
  const merchant = useCurrentMerchant();
  const title = step.title[merchant.defaultLanguage] ?? '';
  const promptType = promptTypeFromIntegrationId(stepTrigger.triggerIntegrationId);
  const data = VerificationDataSchema.safeParse(triggerCompletion.data);
  if (!promptType || !data.success) {
    return null;
  }
  return (
    <div className="flex w-full flex-col gap-4 rounded-md border border-border p-4 shadow-sm">
      <div className="flex flex-col gap-2">
        <p className="text-sm font-medium">{triggerCompletion.user.email}</p>
        <p className="text-xs text-muted-foreground">{formatDate(triggerCompletion.createdAt)}</p>
      </div>
      <Separator />
      <div className="mb-1 mr-1 flex flex-col gap-1">
        <p className="text-sm font-medium text-muted-foreground">{title}</p>
        <CohortFormAnswer
          response={data.data.answer}
          promptType={promptType}
          mediaViewMode={'inline'}
        />
      </div>
    </div>
  );
};

export default StepCompletionsGridComponent;
