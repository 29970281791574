import {ApiKeyASchema} from '@cohort/admin-schemas/apiKeys';
import {
  EmailSchema,
  HexColorSchema,
  LanguageSchema,
  LocalizedRichTextSchema,
  LocalizedStringSchema,
  WebappLanguageSchema,
} from '@cohort/shared/schema/common';
import {FontStyleSchema, FontTypeSchema, FontWeightSchema} from '@cohort/shared/schema/common/font';
import {z} from 'zod';

export const MembershipPassConfigurationASchema = z.object({
  title: LocalizedStringSchema,
  bannerFileKey: z
    .string({required_error: 'Banner is required', invalid_type_error: 'Banner is required'})
    .nullable(),
  backgroundColorCode: HexColorSchema.nullable(),
  nftEnabled: z.boolean(),
  smartContractAddress: z.string().nullable(),
  blockExplorerUrl: z.string().nullable(),
});
export type MembershipPassConfigurationADto = z.infer<typeof MembershipPassConfigurationASchema>;

const BaseFontASchema = z.object({
  id: z.string().uuid(),
  src: z.string().url(),
  family: z.string(),
  type: FontTypeSchema,
  weight: FontWeightSchema.nullable(),
  style: FontStyleSchema.nullable(),
  default: z.boolean(),
});

const EmbeddedFontASchema = BaseFontASchema.extend({
  type: z.literal('embedded'),
  weight: z.null(),
  style: z.null(),
});

const ManualFontASchema = BaseFontASchema.extend({
  type: z.literal('manual'),
  weight: FontWeightSchema,
  style: FontStyleSchema,
});

export const MerchantFontASchema = z.union([EmbeddedFontASchema, ManualFontASchema]);
export const MerchantFontInputASchema = z.union([
  EmbeddedFontASchema.extend({
    id: z.string().uuid().nullable(),
  }),
  ManualFontASchema.extend({
    id: z.string().uuid().nullable(),
  }),
]);
export type MerchantFontADto = z.infer<typeof MerchantFontASchema>;
export type MerchantFontInputADto = z.infer<typeof MerchantFontInputASchema>;

export const MerchantASchema = z.object({
  id: z.string().uuid(),
  organizationId: z.string().uuid(),
  name: z.string().min(1),
  slug: z.string().min(1),
  logoFileKey: z.string().nullable(),
  description: LocalizedRichTextSchema,
  backgroundColorCode: HexColorSchema.nullable(),
  accentColorCode: HexColorSchema.nullable(),
  supportEmail: EmailSchema.nullable(),
  membershipPassConfiguration: MembershipPassConfigurationASchema,
  defaultLanguage: WebappLanguageSchema,
  supportedLanguages: z.array(LanguageSchema),
  emailSigninEnabled: z.boolean(),
  spaceUrl: z.string().url(),
  customStyleSheetUrl: z.string().url(),
  fonts: z.array(MerchantFontASchema),
});
export type MerchantADto = z.infer<typeof MerchantASchema>;

export const CreateMerchantASchema = z.object({
  name: z.string().min(1),
  customXpsHostname: z.string().nullable().optional(),
  defaultLanguage: WebappLanguageSchema,
  supportedLanguages: z.array(LanguageSchema).optional(),
  logoFileKey: z.string().nullable().optional(),
  description: LocalizedRichTextSchema.optional(),
  backgroundColorCode: HexColorSchema.nullable().optional(),
  accentColorCode: HexColorSchema.nullable().optional(),
  spaceEmbedUrl: z.string().url().nullable().optional(),
  campaignsEmbedUrl: z.string().url().nullable().optional(),
  supportEmail: EmailSchema.nullable().optional(),
  emailSigninEnabled: z.boolean().optional(),
});
export type CreateMerchantADto = z.infer<typeof CreateMerchantASchema>;

export const PatchMerchantASchema = CreateMerchantASchema.extend({
  membershipPassConfiguration: MembershipPassConfigurationASchema.partial().optional(),
  fonts: z.array(MerchantFontInputASchema).optional(),
  customStyleSheetContent: z.string().optional(),
})
  .partial({
    name: true,
  })
  .omit({
    defaultLanguage: true,
  });

export type PatchMerchantADto = z.infer<typeof PatchMerchantASchema>;

export const MerchantNameAvailabilityASchema = z.object({
  available: z.boolean(),
});
export type MerchantNameAvailabilityADto = z.infer<typeof MerchantNameAvailabilityASchema>;

export const MerchantApiSettingsASchema = z.object({
  spaceEmbedUrl: z.string().url().nullable(),
  campaignsEmbedUrl: z.string().url().nullable(),
  webhooksPortalLink: z.string().url(),
  apiKeys: z.array(ApiKeyASchema),
});
export type MerchantApiSettingsADto = z.infer<typeof MerchantApiSettingsASchema>;

export const GetUrlMetadataASchema = z.object({
  url: z.string(),
});
export type GetUrlPreviewADto = z.infer<typeof GetUrlMetadataASchema>;

export const UrlPreviewSchema = z.object({
  title: z.string().nullable(),
  image: z
    .object({
      url: z.string().url().nullable(),
      type: z.string().nullable(),
    })
    .nullable(),
  error: z.boolean(),
});
export type UrlPreviewADto = z.infer<typeof UrlPreviewSchema>;
