// i18nOwl-ignore [app-tiktok.medias.post.title]
import MediaContentTikTokPost from '@cohort/components-xps/components/contents/apps/tiktok/post/MediaContentTikTokPost';
import type {ContentMedia} from '@cohort/merchants/apps';
import TikTokPostMediaConfigComponent from '@cohort/merchants/apps/tiktok/medias/post/ConfigComponent';
import TikTokPostVisualPreview from '@cohort/merchants/apps/tiktok/medias/post/VisualPreview';
import type {TikTokPostMediaStruct} from '@cohort/shared/apps/tiktok/medias/post';
import {TikTokPostMediaSpec} from '@cohort/shared/apps/tiktok/medias/post';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';

export const TikTokPostMedia: ContentMedia<TikTokPostMediaStruct> = {
  spec: TikTokPostMediaSpec,
  configComponent: TikTokPostMediaConfigComponent,
  getTitle: t => t('medias.post.title', {ns: 'app-tiktok'}),
  mediaContent: MediaContentTikTokPost,
  icon: ({size}) => <SvgAppIcon name="tiktok" height={size} width={size} />,
  visualPreview: TikTokPostVisualPreview,
};
