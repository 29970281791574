import {getMedias} from '@cohort/merchants/apps';
import {useMedias} from '@cohort/merchants/apps/useMedias';
import type {AspectRatio} from '@cohort/merchants/components/form/input/fileInput/FileInput';
import {RadioCardIconBadge, RadioCards} from '@cohort/merchants/components/form/RadioCards';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {CreateEditStepModalType} from '@cohort/merchants/pages/campaigns/campaign/edit/settings/challenge/utils';
import {MediaKindSchema} from '@cohort/shared/apps';
import {CohortVideoMediaSpec} from '@cohort/shared/apps/cohort/medias/video';
import {ChallengeStepAllowedMediaKinds} from '@cohort/shared/schema/common/challengeStep';
import {getVideoUrl} from '@cohort/shared/utils/media';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {isLandscapeVideo} from '@cohort/shared-frontend/utils/isFile';
import {isFile} from '@cohort/shared-frontend/utils/isFile';
import {Prohibit} from '@phosphor-icons/react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {isDefined, isString} from 'remeda';

const StepContentInput: React.FC = () => {
  const [aspectRatio, setAspectRatio] = useState<AspectRatio | undefined>();
  const {t} = useTranslation('apps', {
    keyPrefix: 'common.stepContentInput',
  });
  const {register, control, watch, setValue, setFormAssets} =
    useCohortForm<CreateEditStepModalType>();

  useEffect(() => {
    setFormAssets(assets => [
      ...(assets ?? []),
      {
        name: 'media.config.imageFileKey',
        type: 'contentImage',
      },
      {
        name: 'media.config.videoFileKey',
        type: 'contentVideo',
      },
      {
        name: 'media.config.thumbnailFileKey',
        type: 'contentVideoThumbnail',
      },
    ]);
  }, [setFormAssets]);

  const {getMediaTitle, getMedia} = useMedias();

  const medias = getMedias();

  const media = watch('media');

  const mediaKind = MediaKindSchema.safeParse(media?.kind);

  const selectedMedia = mediaKind.success ? getMedia(mediaKind.data) : null;

  const radioCardsOptions = useMemo(() => {
    const noMedia = {
      prefix: <RadioCardIconBadge icon={<Prohibit size={24} className="text-slate-400" />} />,
      label: t('titleNoMedia'),
      value: 'no-media',
    };

    const allowed = medias
      .filter(media => ChallengeStepAllowedMediaKinds.includes(media.spec.kind))
      .map(media => ({
        prefix: <RadioCardIconBadge icon={<media.icon size={24} textColor="text-slate-400" />} />,
        label: getMediaTitle(media),
        value: media.spec.kind,
      }));

    return [noMedia, ...allowed];
  }, [getMediaTitle, medias, t]);

  const getCustomAspectRatio = useCallback(async (): Promise<AspectRatio | undefined> => {
    if (
      selectedMedia?.spec.kind === CohortVideoMediaSpec.kind &&
      (isString(media?.config.videoFileKey) || isFile(media?.config.videoFileKey))
    ) {
      const videoFileOrSrc = isString(media.config.videoFileKey)
        ? getVideoUrl(import.meta.env.COHORT_ENV, media.config.videoFileKey)
        : media.config.videoFileKey;
      const isLandscape = await isLandscapeVideo(videoFileOrSrc);
      return isLandscape ? 'aspect-video' : 'aspect-auto';
    }
  }, [media?.config.videoFileKey, selectedMedia?.spec.kind]);

  useEffect(() => {
    getCustomAspectRatio().then(setAspectRatio);
  }, [getCustomAspectRatio, media?.config.videoFileKey]);

  return (
    <div className="space-y-4">
      <RadioCards
        name="media.kind"
        direction="row"
        label={t('labelMediaKind')}
        className="grid grid-cols-4"
        register={register}
        control={control}
        onChange={() => setValue('media.config', {})}
        options={radioCardsOptions}
      />
      <div
        className={cn(
          isDefined(media?.config.videoFileKey) &&
            selectedMedia?.spec.kind === CohortVideoMediaSpec.kind &&
            aspectRatio !== 'aspect-video' &&
            'mx-auto w-1/4'
        )}
      >
        {selectedMedia && <selectedMedia.configComponent customAspectRatio={aspectRatio} />}
      </div>
    </div>
  );
};
export default StepContentInput;
