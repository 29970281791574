import TwitterPostVisualPreviewContent from '@cohort/components-xps/components/contents/apps/twitter/post/VisualPreviewContent';
import type {VisualPreviewProps} from '@cohort/merchants/apps';
import type {TwitterPostMediaStruct} from '@cohort/shared/apps/twitter/medias/post';

const TwitterPostVisualPreview: React.FC<VisualPreviewProps<TwitterPostMediaStruct>> = ({
  config,
  title,
  imageSize = 'XL',
  isMobile,
}) => (
  <TwitterPostVisualPreviewContent
    imageSize={imageSize}
    imageFileKey={config.imageFileKey}
    title={title}
    isMobile={isMobile}
  />
);

export default TwitterPostVisualPreview;
