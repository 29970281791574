import {PerkASchema} from '@cohort/admin-schemas/perk';
import {PerkIntegrationIdSchema} from '@cohort/shared/apps';
import {asArray, DatetimeSchema, EmailSchema} from '@cohort/shared/schema/common';
import {PaginationParamsSchema} from '@cohort/shared/schema/common/pagination';
import {
  PerkAccessObtentionTypeSchema,
  PerkAccessStatusSchema,
} from '@cohort/shared/schema/common/perkAccesses';
import {z} from 'zod';

export const PerkUsageASchema = z.object({
  id: z.string().uuid(),
  data: z.unknown().nullable(),
  perkIntegrationId: PerkIntegrationIdSchema.nullable(),
  integrationConfig: z.unknown().nullable(),
  usedAt: DatetimeSchema,
});
export type PerkUsageADto = z.infer<typeof PerkUsageASchema>;

export const PerkAccessASchema = z.object({
  id: z.string().uuid(),
  createdAt: DatetimeSchema,
  status: PerkAccessStatusSchema,
  lastUsageDate: DatetimeSchema.nullable(),
  user: z.object({
    id: z.string().uuid(),
    email: EmailSchema,
    isDeleted: z.boolean(),
  }),
  usages: z.array(PerkUsageASchema),
  obtentionType: PerkAccessObtentionTypeSchema,
  campaign: z
    .object({
      id: z.string().uuid(),
      internalName: z.string(),
    })
    .nullable(),
  removed: z.boolean(),
  perk: PerkASchema,
});
export type PerkAccessADto = z.infer<typeof PerkAccessASchema>;

export const PerkAccessOrderByASchema = z.enum([
  'createdAt',
  '-createdAt',
  '-lastUsageDate',
  'lastUsageDate',
]);
export type PerkAccessOrderByADto = z.infer<typeof PerkAccessOrderByASchema>;

export const GetPerkAccessesParamsASchema = PaginationParamsSchema.extend({
  userId: z.preprocess(asArray, z.array(z.string())).optional(),
  perkId: z.preprocess(asArray, z.array(z.string())).optional(),
  campaignId: z.preprocess(asArray, z.array(z.string())).optional(),
  status: z.preprocess(asArray, z.array(PerkAccessStatusSchema).optional()),
  orderBy: PerkAccessOrderByASchema.default('-createdAt'),
});
export type GetPerkAccessesParamsADto = z.infer<typeof GetPerkAccessesParamsASchema>;
export type GetPerkAccessesParamsInputADto = z.input<typeof GetPerkAccessesParamsASchema>;

export const ExportPerkAccessesParamsASchema = z.object({
  perkId: z.string().uuid(),
  userId: z.preprocess(asArray, z.array(z.string())).optional(),
  campaignId: z.preprocess(asArray, z.array(z.string())).optional(),
  status: z.preprocess(asArray, z.array(PerkAccessStatusSchema).optional()),
  format: z.enum(['csv']).default('csv'),
  orderBy: PerkAccessOrderByASchema.default('-createdAt'),
});
export type ExportPerkAccessesParamsADto = z.infer<typeof ExportPerkAccessesParamsASchema>;
