import FieldWrapper from '@cohort/merchants/components/campaigns/preview/FieldWrapper';
import React, {useState} from 'react';

type DefaultInput = Omit<JSX.IntrinsicElements['input'], 'type'>;

export type InputProps = {
  width?: string;
  label?: string;
  mandatory?: boolean;
  error?: JSX.Element;
};

export const CheckboxInputPreview: React.FC<DefaultInput & InputProps> = ({
  label,
  mandatory,
  error,
}) => {
  const [checked, setChecked] = useState(false);

  return (
    <FieldWrapper label={label} mandatory={mandatory} error={error} labelPosition="right">
      <input
        checked={checked}
        type="checkbox"
        onChange={() => setChecked(!checked)}
        className="h-4 w-4 cursor-pointer focus:outline-none focus:ring-0 focus:ring-offset-0"
        style={
          {
            borderWidth: checked ? '0' : '1px',
            '--xps-input-border-radius': '0.25rem',
            '--xps-input-background-color': checked
              ? 'var(--xps-accent-color)'
              : 'rgba(0 0 0 / 0.04)',
          } as Record<string, string>
        }
      />
    </FieldWrapper>
  );
};

export default CheckboxInputPreview;
