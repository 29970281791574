import CohortScanQrCodeTriggerIntegrationInstructionsComponent from '@cohort/components-xps/components/apps/cohort/triggerIntegrations/scanQrCode/InstructionsComponent';
import type {TriggerIntegration} from '@cohort/merchants/apps';
import CohortScanQrCodeTriggerIntegrationActionCtaComponentPreview from '@cohort/merchants/apps/cohort/triggerIntegrations/scanQrCode/ActionCtaComponentPreview';
import CohortScanQrCodeTriggerConfigComponent from '@cohort/merchants/apps/cohort/triggerIntegrations/scanQrCode/ConfigComponent';
import CohortScanQrCodeTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/cohort/triggerIntegrations/scanQrCode/StepCompletionTitleComponent';
import type {CohortScanQrCodeTriggerStruct} from '@cohort/shared/apps/cohort/triggers/scanQrCode';
import {CohortScanQrCodeTriggerIntegrationSpec} from '@cohort/shared/apps/cohort/triggers/scanQrCode';

export const CohortScanQrCodeTriggerIntegration: TriggerIntegration<CohortScanQrCodeTriggerStruct> =
  {
    spec: CohortScanQrCodeTriggerIntegrationSpec,
    configComponent: CohortScanQrCodeTriggerConfigComponent,
    stepCompletionTitleComponent: CohortScanQrCodeTriggerIntegrationStepCompletionTitleComponent,
    getTitle: t => t('triggerIntegrations.scan-qr-code.title', {ns: 'app-cohort'}),
    preview: {
      InstructionsComponent: CohortScanQrCodeTriggerIntegrationInstructionsComponent,
      CtaComponent: CohortScanQrCodeTriggerIntegrationActionCtaComponentPreview,
    },
  };
