import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import DescriptionModeSelect from '@cohort/merchants/apps/common/DescriptionModeSelect';
import StepTitleInput from '@cohort/merchants/apps/common/StepTitleInput';
import {
  SelectMediaConfig,
  SelectMediaConfigButton,
  SelectMediaConfigEmptyState,
} from '@cohort/merchants/apps/common/triggers/SelectMedia/SelectMediaConfig';
import {SelectMediaContextProvider} from '@cohort/merchants/apps/common/triggers/SelectMedia/SelectMediaContextProvider';
import type {Defaults} from '@cohort/merchants/apps/common/triggers/utils';
import {parseDefaults} from '@cohort/merchants/apps/common/triggers/utils';
import {getDescriptionMode} from '@cohort/merchants/apps/common/triggers/utils';
import {useSpotifyPlaylists} from '@cohort/merchants/apps/spotify/actions';
import SelectPlaylistSheet from '@cohort/merchants/apps/spotify/triggers/SelectPlaylistSheet';
import SpotifyPlaylistCard from '@cohort/merchants/apps/spotify/triggers/SpotifyPlaylistCard';
import ConnectionPicker from '@cohort/merchants/components/connections/ConnectionPicker';
import ErrorState from '@cohort/merchants/components/ErrorState';
import LanguageSelectorInput from '@cohort/merchants/components/form/LanguageSelectorInput';
import Loader from '@cohort/merchants/components/Loader';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {SpotifyFollowPlaylistTriggerStruct} from '@cohort/shared/apps/spotify/triggers/followPlaylist';
import type {Language} from '@cohort/shared/schema/common';
import {Fragment, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const getDefaults = (definedLanguages: Language[]): Defaults => {
  const defaults = {
    title: {
      en: `Add our Spotify playlist`,
      fr: `Ajoutez notre playlist Spotify`,
      es: `Agrega nuestra playlist de Spotify`,
    },
    description: {},
  };
  return parseDefaults(defaults, definedLanguages);
};

const SpotifyFollowPlaylistTriggerConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps<SpotifyFollowPlaylistTriggerStruct>
> = ({formReturn: {setValue, control, watch, reset, register}, trigger, step}) => {
  const init = useRef(false);
  const merchant = useCurrentMerchant();

  const [definedLanguages, connectionId, playlistId] = watch([
    'definedLanguages',
    'trigger.connectionId',
    'trigger.triggerIntegrationConfig.playlistId',
  ]);

  const {t} = useTranslation('app-spotify', {
    keyPrefix: 'triggerIntegrations.follow-playlist.configComponent',
  });

  const {
    data: playlistsResponse,
    isLoading: isPlaylistsLoading,
    isFetched: isPlaylistsFetched,
  } = useSpotifyPlaylists(merchant.id, connectionId ?? '', undefined, {
    enabled: !!connectionId && init.current,
  });

  const playlists = playlistsResponse?.output.playlists ?? [];

  const onSelectPlaylist = (playlistId: string): void => {
    const playlist = playlists.find(playlist => playlist.id === playlistId);
    if (playlist) {
      setValue('trigger.triggerIntegrationConfig.playlistId', playlist.id);
      setValue('trigger.triggerIntegrationConfig.playlistName', playlist.name);
      setValue('trigger.triggerIntegrationConfig.playlistThumbnailUrl', playlist.thumbnailUrl);
      setValue('trigger.triggerIntegrationConfig.playlistUrl', playlist.url);
      setValue('trigger.triggerIntegrationConfig.playlistTracksCount', playlist.tracksCount);
    }
  };

  useEffect(() => {
    if (init.current) {
      const defaults = getDefaults(definedLanguages);
      setValue('defaultTitle', defaults.title);
      setValue('defaultDescription', defaults.description);
    }
  }, [definedLanguages, setValue]);

  useEffect(() => {
    if (init.current) {
      return;
    }
    const defaults = getDefaults(definedLanguages);
    reset(formValues => ({
      ...formValues,
      defaultTitle: defaults.title,
      defaultDescription: defaults.description,
      descriptionMode: getDescriptionMode(definedLanguages, defaults, step),
      title: step?.title ?? defaults.title,
      description: step?.description ?? defaults.description,
      icon: step?.icon !== undefined ? step.icon : '🎵',
      media: null,
      trigger: {
        id: trigger?.id ?? null,
        connectionId: trigger?.connectionId ?? null,
        triggerIntegrationId: 'spotify.follow-playlist',
        triggerIntegrationConfig: {
          playlistId: trigger?.triggerIntegrationConfig.playlistId ?? '',
          playlistName: trigger?.triggerIntegrationConfig.playlistName ?? '',
          playlistThumbnailUrl: trigger?.triggerIntegrationConfig.playlistThumbnailUrl ?? '',
          playlistUrl: trigger?.triggerIntegrationConfig.playlistUrl ?? '',
          playlistTracksCount: trigger?.triggerIntegrationConfig.playlistTracksCount ?? '',
        },
      },
    }));
    init.current = true;
  }, [definedLanguages, reset, step, trigger]);

  if (!init.current) {
    return null;
  }

  const playlistSelector = match({
    connectionId,
    playlists,
    isPlaylistsLoading,
    isPlaylistsFetched,
  })
    .with({connectionId: null}, () => null)
    .with({isPlaylistsLoading: true}, () => <Loader size={30} color="gray" />)
    .with({playlists: []}, () => <p>{t('noPlaylistsMessage')}</p>)
    .with({isPlaylistsFetched: true}, () => {
      const selectedPlaylist = playlists.find(playlist => playlist.id === playlistId);

      return (
        <SelectMediaContextProvider defaultMediaId={playlistId}>
          <SelectMediaConfig
            title={t('titlePlaylistSelect')}
            description={t('descriptionPlaylistSelect')}
          >
            {selectedPlaylist === undefined ? (
              <Fragment>
                <SelectMediaConfigEmptyState />
                <SelectMediaConfigButton mode="add" />
              </Fragment>
            ) : (
              <Fragment>
                <SpotifyPlaylistCard media={selectedPlaylist} />
                <SelectMediaConfigButton mode="change" />
              </Fragment>
            )}
            <SelectPlaylistSheet onSelectPlaylist={onSelectPlaylist} playlists={playlists} />
          </SelectMediaConfig>
        </SelectMediaContextProvider>
      );
    })
    .otherwise(() => <ErrorState />);

  return (
    <Fragment>
      <div className="max-w-sm">
        <ConnectionPicker
          appId="spotify"
          control={control}
          register={register}
          connectionIdFieldName="trigger.connectionId"
        />
      </div>
      {playlistSelector}
      <DescriptionModeSelect />
      <LanguageSelectorInput />
      <StepTitleInput />
    </Fragment>
  );
};

export default SpotifyFollowPlaylistTriggerConfigComponent;
