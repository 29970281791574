import TiktokKeywordInDescriptionTriggerIntegrationInstructionsComponent from '@cohort/components-xps/components/apps/tiktok/triggers/keywordInDescription/InstructionsComponent';
import type {TriggerIntegration} from '@cohort/merchants/apps';
import useSocialStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useSocialStepCompletionColumns';
import TiktokKeywordInDescriptionTriggerActionCtaComponentPreview from '@cohort/merchants/apps/tiktok/triggers/keywordInDescription/ActionCtaComponentPreview';
import TikTokKeywordInDescriptionTriggerConfigComponent from '@cohort/merchants/apps/tiktok/triggers/keywordInDescription/ConfigComponent';
import TikTokKeywordInDescriptionTriggerStepCompletionContextComponent from '@cohort/merchants/apps/tiktok/triggers/keywordInDescription/StepCompletionContextComponent';
import TikTokKeywordInDescriptionTriggerIntegrationStepCompletionsGridComponent from '@cohort/merchants/apps/tiktok/triggers/keywordInDescription/StepCompletionsGridComponent';
import TikTokKeywordInDescriptionTriggerStepCompletionTitleComponent from '@cohort/merchants/apps/tiktok/triggers/keywordInDescription/StepCompletionTitleComponent';
import type {TikTokKeywordInDescriptionTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/keywordInDescription';
import {TikTokKeywordInDescriptionTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/keywordInDescription';

export const TikTokKeywordInDescriptionTriggerIntegration: TriggerIntegration<TikTokKeywordInDescriptionTriggerStruct> =
  {
    spec: TikTokKeywordInDescriptionTriggerSpec,
    configComponent: TikTokKeywordInDescriptionTriggerConfigComponent,
    stepCompletionTitleComponent: TikTokKeywordInDescriptionTriggerStepCompletionTitleComponent,
    stepCompletionContextComponent: TikTokKeywordInDescriptionTriggerStepCompletionContextComponent,
    stepCompletionsGridComponent:
      TikTokKeywordInDescriptionTriggerIntegrationStepCompletionsGridComponent,
    useStepCompletionColumns: context =>
      useSocialStepCompletionColumns<TikTokKeywordInDescriptionTriggerStruct>({
        ...context,
        userNameKey: 'username',
        getPostUrl: data => data.shareUrl ?? null,
      }),
    getTitle: t => t('triggers.keyword-in-description.title', {ns: 'app-tiktok'}),
    preview: {
      InstructionsComponent: TiktokKeywordInDescriptionTriggerIntegrationInstructionsComponent,
      CtaComponent: TiktokKeywordInDescriptionTriggerActionCtaComponentPreview,
    },
  };
