import type {PreviewCtaComponentProps} from '@cohort/merchants/apps';
import ActionCtaPreview from '@cohort/merchants/apps/common/ActionCtaPreview';
import type {FacebookLikePostTriggerStruct} from '@cohort/shared/apps/facebook/triggers/likePost';
import {useTranslation} from 'react-i18next';

const FacebookLikePostTriggerActionCtaComponentPreview: React.FC<
  PreviewCtaComponentProps<FacebookLikePostTriggerStruct>
> = ({localizationConfig}) => {
  const {t} = useTranslation('app-facebook', {
    keyPrefix: 'triggers.like-post',
    lng: localizationConfig.desiredLanguage,
  });
  return <ActionCtaPreview text={t('ctaTitle')} withRefresh />;
};

export default FacebookLikePostTriggerActionCtaComponentPreview;
