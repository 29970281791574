import Button from '@cohort/merchants/components/buttons/Button';
import DraggableList from '@cohort/merchants/components/form/DraggableList';
import DraggableListItem from '@cohort/merchants/components/form/DraggableListItem';
import SwitchInput from '@cohort/merchants/components/form/SwitchInput';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import {updateLocalizedString} from '@cohort/merchants/lib/Utils';
import type {CohortFormSelectTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/select';
import type {Language} from '@cohort/shared/schema/common';
import {PlusCircle} from '@phosphor-icons/react';
import React from 'react';
import {get, useController, useFieldArray} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type FieldOptionProps = {
  index: number;
  onRemove: () => void;
};

const FieldOption: React.FC<FieldOptionProps> = ({index, onRemove}) => {
  const merchant = useCurrentMerchant();
  const {control, watch} = useCohortForm<FormData>();
  const selectedLanguage = watch('selectedLanguage');

  const {field} = useController({
    control,
    name: `trigger.triggerIntegrationConfig.options.${index}`,
  });

  return (
    <DraggableListItem onRemove={onRemove} className="h-16 bg-slate-50">
      <div className="flex w-full items-center">
        <input
          className="w-full rounded-md border border-border bg-white p-2 text-sm focus:ring-primary"
          placeholder={field.value.label[merchant.defaultLanguage] ?? ''}
          value={field.value.label[selectedLanguage] ?? ''}
          onChange={e => {
            const updatedOption = {...field.value};
            updatedOption.label = updateLocalizedString(
              selectedLanguage,
              field.value.label,
              e.target.value
            );
            if (selectedLanguage === merchant.defaultLanguage) {
              updatedOption.value = e.target.value;
            }
            field.onChange(updatedOption);
          }}
        />
      </div>
    </DraggableListItem>
  );
};

type FormData = {
  selectedLanguage: Language;
  trigger: {
    triggerIntegrationConfig: CohortFormSelectTriggerStruct['Config'];
  };
};

const SelectFormSection: React.FC = () => {
  // i18nOwl-ignore [errorNoOptions]
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'components.promptSheet.selectFormSection',
  });

  const {register, control, setValue, formState} = useCohortForm<FormData>();

  const optionsError = get(formState, `errors.trigger.triggerIntegrationConfig.options`);
  const {fields, append, remove, replace} = useFieldArray({
    name: `trigger.triggerIntegrationConfig.options`,
    control,
  });

  return (
    <div className="relative flex flex-col space-y-2">
      <label className="block text-sm font-medium text-slate-700">{t('labelOptions')}</label>
      <DraggableList
        handleOnReorder={ids => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          replace(ids.map(id => fields[parseInt(id, 10)]!));
        }}
        items={fields.map((option, index) => {
          return {
            item: <FieldOption index={index} onRemove={() => remove(index)} />,
            id: String(index),
          };
        })}
      />
      {optionsError?.message !== undefined && (
        <span className="text-sm font-normal text-red-500">{t(optionsError.message)}</span>
      )}
      <div className="flex w-full justify-between">
        <Button onClick={() => append({label: {}, value: ''})} variant="ghost" className="w-fit">
          <PlusCircle className="-ml-1 mr-2 h-5 w-5" />
          {t('buttonAddOption')}
        </Button>
        <SwitchInput
          name="trigger.triggerIntegrationConfig.multipleChoice"
          register={register}
          control={control}
          label={t('labelMultipleChoice')}
          labelPosition="left"
          className="w-fit"
          onCheckedChange={() => {
            setValue('trigger.triggerIntegrationConfig.userPropertyId', null);
          }}
        />
      </div>
    </div>
  );
};

export default SelectFormSection;
