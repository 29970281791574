// i18nOwl-ignore [app-cohort.medias.link.title]
import MediaContentCohortLink from '@cohort/components-xps/components/contents/apps/cohort/link/MediaContentCohortLink';
import type {ContentMedia} from '@cohort/merchants/apps';
import CohortLinkMediaConfigComponent from '@cohort/merchants/apps/cohort/medias/link/ConfigComponent';
import CohortLinkVisualPreview from '@cohort/merchants/apps/cohort/medias/link/VisualPreview';
import type {CohortLinkMediaStruct} from '@cohort/shared/apps/cohort/medias/link';
import {CohortLinkMediaSpec} from '@cohort/shared/apps/cohort/medias/link';
import {Link} from '@phosphor-icons/react';

export const CohortMediaLink: ContentMedia<CohortLinkMediaStruct> = {
  spec: CohortLinkMediaSpec,
  configComponent: CohortLinkMediaConfigComponent,
  getTitle: t => t('medias.link.title', {ns: 'app-cohort'}),
  mediaContent: MediaContentCohortLink,
  icon: ({size, textColor}) => <Link size={size} className={textColor} />,
  visualPreview: CohortLinkVisualPreview,
};
