import type {TriggerComponentPreviewProps} from '@cohort/merchants/apps';
import {TextAreaInputPreview} from '@cohort/merchants/components/campaigns/preview/TextAreaInputPreview';
import type {CohortFormLongTextTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/longText';
import {getLocalizedContentOrThrow} from '@cohort/shared/utils/localization';

const placeholders = {
  en: 'Enter your answer here',
  fr: 'Entrez votre réponse ici',
  es: 'Escribe tu respuesta aquí',
};

const CohortFormLongTextTriggerIntegrationComponentPreview: React.FC<
  TriggerComponentPreviewProps<CohortFormLongTextTriggerStruct>
> = ({localizationConfig}) => {
  const placeholder = getLocalizedContentOrThrow(placeholders, localizationConfig);

  return <TextAreaInputPreview width="w-[350px]" placeholder={placeholder} />;
};

export default CohortFormLongTextTriggerIntegrationComponentPreview;
