import Input from '@cohort/merchants/components/form/input/Input';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {CreateEditStepModalType} from '@cohort/merchants/pages/campaigns/campaign/edit/settings/challenge/utils';
import {useTranslation} from 'react-i18next';

const TriggerReferenceIdInput: React.FC = () => {
  const {t} = useTranslation('apps', {
    keyPrefix: 'common.triggerReferenceIdInput',
  });
  const {register, control} = useCohortForm<CreateEditStepModalType>();

  return (
    <Input
      type="text"
      name="trigger.triggerIntegrationConfig.referenceId"
      label={t('label')}
      optional
      register={register}
      control={control}
    />
  );
};
export default TriggerReferenceIdInput;
