import type {ExecuteActionResponseADto} from '@cohort/admin-schemas/apps';
import {typeformAppKeys} from '@cohort/merchants/apps/typeform/api';
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {executeAction} from '@cohort/merchants/lib/api/Apps';
import type {ListVideosActionStruct} from '@cohort/shared/apps/tiktok/actions/listVideos';
import type {UseQueryResult} from '@tanstack/react-query';

export const tiktokAppKeys = {
  tiktokApp: ['tiktokApp'] as const,
  listVideos: (merchantId: string, merchantConnectionId: string) =>
    [...typeformAppKeys.typeformApp, merchantId, merchantConnectionId, 'list-videos'] as const,
};

export const useTikTokVideos = (
  merchantId: string,
  merchantConnectionId: string,
  options?: QueryOptions
): UseQueryResult<ExecuteActionResponseADto<ListVideosActionStruct>, unknown> => {
  return useCohortQuery({
    queryKey: tiktokAppKeys.listVideos(merchantId, merchantConnectionId),
    queryFn: async () => {
      return executeAction<ListVideosActionStruct>(merchantId, {
        appId: 'tiktok',
        actionId: 'list-videos',
        merchantConnectionId,
        input: {},
      });
    },
    ...options,
  });
};
