import type {TriggerComponentPreviewProps} from '@cohort/merchants/apps';
import {SelectPickerPreview} from '@cohort/merchants/components/campaigns/preview/SelectPickerPreview';
import type {CohortFormSelectTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/select';
import {getLocalizedContent} from '@cohort/shared/utils/localization';
import {isArray} from 'lodash';
import {useState} from 'react';
import type {SingleValue} from 'react-select';

const CohortFormSelectTriggerIntegrationComponentPreview: React.FC<
  TriggerComponentPreviewProps<CohortFormSelectTriggerStruct>
> = ({config, localizationConfig}) => {
  const [selected, setSelected] = useState<Array<string>>([]);

  const isMulti = config.multipleChoice;

  const localizedOptions = config.options.map(option => {
    return {
      value: option.value,
      label: getLocalizedContent(option.label, localizationConfig),
    };
  });

  const value = isMulti
    ? localizedOptions.filter(option => selected.includes(option.value))
    : localizedOptions.find(option => selected.includes(option.value));

  return (
    <div className="w-[350px]">
      <SelectPickerPreview
        value={value}
        options={localizedOptions}
        onChange={newValue => {
          if (!newValue) {
            return;
          }
          isArray(newValue)
            ? setSelected(newValue.map(option => option.value))
            : setSelected([
                (newValue as unknown as SingleValue<(typeof localizedOptions)[number]>)?.value ??
                  '',
              ]);
        }}
        isMulti={isMulti}
        isSearchable={false}
      />
    </div>
  );
};

export default CohortFormSelectTriggerIntegrationComponentPreview;
