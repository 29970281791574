import type {PreviewCtaComponentProps} from '@cohort/merchants/apps';
import ActionCtaPreview from '@cohort/merchants/apps/common/ActionCtaPreview';
import type {SpotifyFollowUserTriggerStruct} from '@cohort/shared/apps/spotify/triggers/followUser';
import {useTranslation} from 'react-i18next';

const SpotifyFollowUserTriggerActionCtaComponentPreview: React.FC<
  PreviewCtaComponentProps<SpotifyFollowUserTriggerStruct>
> = ({localizationConfig}) => {
  const {t} = useTranslation('app-spotify', {
    keyPrefix: 'triggerIntegrations.follow-user',
    lng: localizationConfig.desiredLanguage,
  });
  return <ActionCtaPreview text={t('ctaTitle')} withRefresh />;
};

export default SpotifyFollowUserTriggerActionCtaComponentPreview;
