import type {UpdateCohortADto} from '@cohort/admin-schemas/cohort';
import {
  Breadcrumb,
  BreadcrumbBadges,
  BreadcrumbContent,
  BreadcrumbCtas,
  BreadcrumbEditableTitle,
  BreadcrumbLink,
} from '@cohort/merchants/components/Breadcrumb';
import Button from '@cohort/merchants/components/buttons/Button';
import {CohortReferenceIdBadge} from '@cohort/merchants/components/cohorts/CohortReferenceIdBadge';
import {CohortTypeBadge} from '@cohort/merchants/components/cohorts/CohortTypeBadge';
import EditCohortReferenceIdModal from '@cohort/merchants/components/modals/EditCohortReferenceIdModal';
import {cohortsKeys} from '@cohort/merchants/hooks/api/Cohorts';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentCohort} from '@cohort/merchants/hooks/contexts/currentCohort';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {patchCohort} from '@cohort/merchants/lib/api/Cohorts';
import {getCohortsRoute} from '@cohort/merchants/lib/Pages';
import DeleteCohort from '@cohort/merchants/pages/users/cohort/DeleteCohort';
import {isList} from '@cohort/merchants/pages/users/cohort/utils';
import {useQueryClient} from '@tanstack/react-query';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

const PatchCohortFormSchema = z.object({
  name: z.string().min(1, {message: 'errorTooShort1'}),
});

const CohortPageBreadcrumb = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const queryClient = useQueryClient();
  const cohort = useCurrentCohort();
  const [editReferenceIdModalOpen, setEditReferenceIdModalOpen] = useState(false);

  const {t} = useTranslation('pages', {
    keyPrefix: 'users.cohort.cohortPageBreadcrumb',
  });

  const {mutate: updateCohort} = useCohortMutation({
    mutationFn: async (cohortData: UpdateCohortADto) =>
      patchCohort(merchantId, cohort.id, cohortData),
    notifyErrorMessage: isList(cohort)
      ? t('notificationErrorListUpdate')
      : t('notificationErrorSegmentUpdate'),
    notifySuccessMessage: isList(cohort)
      ? t('notificationUpdateListSuccess')
      : t('notificationUpdateSegmentSuccess'),
    onSuccess: () => {
      queryClient.invalidateQueries(cohortsKeys.getById(merchantId, cohort.id));
      queryClient.invalidateQueries(cohortsKeys.list(merchantId));
    },
  });

  return (
    <Breadcrumb>
      <BreadcrumbContent>
        <BreadcrumbLink name={t('name')} path={getCohortsRoute().path} />
        <BreadcrumbEditableTitle
          value={cohort.name}
          onChange={(name: string) => updateCohort({name})}
          validator={(name: string) => PatchCohortFormSchema.safeParse({name}).success}
        />
        <BreadcrumbBadges>
          <CohortReferenceIdBadge referenceId={cohort.referenceId} showCopyToClipboard />
          <CohortTypeBadge type={cohort.type} size="small" />
        </BreadcrumbBadges>
      </BreadcrumbContent>
      <BreadcrumbCtas>
        <Button variant="secondary" onClick={() => setEditReferenceIdModalOpen(true)}>
          {t('buttonEdit')}
        </Button>
        <DeleteCohort cohort={cohort} />
      </BreadcrumbCtas>
      {editReferenceIdModalOpen && (
        <EditCohortReferenceIdModal
          cohort={cohort}
          onClose={() => setEditReferenceIdModalOpen(false)}
        />
      )}
    </Breadcrumb>
  );
};

export default CohortPageBreadcrumb;
