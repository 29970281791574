import type {PreviewCtaComponentProps} from '@cohort/merchants/apps';
import ActionCtaPreview from '@cohort/merchants/apps/common/ActionCtaPreview';
import type {InstagramCommentMediaTriggerStruct} from '@cohort/shared/apps/instagram/triggers/commentMedia';
import {useTranslation} from 'react-i18next';

const InstagramCommentMediaTriggerActionCtaComponentPreview: React.FC<
  PreviewCtaComponentProps<InstagramCommentMediaTriggerStruct>
> = ({localizationConfig}) => {
  const {t} = useTranslation('app-instagram', {
    keyPrefix: 'triggerIntegrations.comment-media',
    lng: localizationConfig.desiredLanguage,
  });
  return <ActionCtaPreview text={t('ctaTitle')} withRefresh />;
};

export default InstagramCommentMediaTriggerActionCtaComponentPreview;
