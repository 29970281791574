import type {TriggerIntegration} from '@cohort/merchants/apps';
import CohortFormTriggerIntegrationActionCtaComponentPreview from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/ActionCtaComponentPreview';
import StepCompletionContextComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/StepCompletionContextComponent';
import StepCompletionsGridComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/StepCompletionsGridComponent';
import StepCompletionTitleComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/StepCompletionTitleComponent';
import useStepCompletionColumns from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/useStepCompletionColumns';
import CohortFormDateTriggerConfigComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/date/ConfigComponent';
import CohortFormDateTriggerIntegrationComponentPreview from '@cohort/merchants/apps/cohort-form/triggerIntegrations/date/TriggerComponentPreview';
import type {CohortFormDateTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/date';
import {CohortFormDateTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/date';

export const CohortFormDateTriggerIntegration: TriggerIntegration<CohortFormDateTriggerStruct> = {
  spec: CohortFormDateTriggerIntegrationSpec,
  configComponent: CohortFormDateTriggerConfigComponent,
  stepCompletionTitleComponent: StepCompletionTitleComponent,
  stepCompletionContextComponent: StepCompletionContextComponent,
  useStepCompletionColumns: useStepCompletionColumns,
  stepCompletionsGridComponent: StepCompletionsGridComponent,
  getTitle: t => t('triggerIntegrations.date.title', {ns: 'app-cohort-form'}),
  preview: {
    CtaComponent: CohortFormTriggerIntegrationActionCtaComponentPreview,
    TriggerComponent: CohortFormDateTriggerIntegrationComponentPreview,
  },
};
