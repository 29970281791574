import type {PreviewCtaComponentProps} from '@cohort/merchants/apps';
import ActionCtaPreview from '@cohort/merchants/apps/common/ActionCtaPreview';
import type {InstagramLikeMediaTriggerStruct} from '@cohort/shared/apps/instagram/triggers/likeMedia';
import {useTranslation} from 'react-i18next';

const InstagramLikeMediaTriggerActionCtaComponentPreview: React.FC<
  PreviewCtaComponentProps<InstagramLikeMediaTriggerStruct>
> = ({localizationConfig}) => {
  const {t} = useTranslation('app-instagram', {
    keyPrefix: 'triggerIntegrations.like-media',
    lng: localizationConfig.desiredLanguage,
  });
  return <ActionCtaPreview text={t('ctaTitle')} />;
};

export default InstagramLikeMediaTriggerActionCtaComponentPreview;
