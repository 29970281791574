import type {TriggerIntegration} from '@cohort/merchants/apps';
import CohortFormTriggerIntegrationActionCtaComponentPreview from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/ActionCtaComponentPreview';
import StepCompletionContextComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/StepCompletionContextComponent';
import StepCompletionsGridComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/StepCompletionsGridComponent';
import StepCompletionTitleComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/StepCompletionTitleComponent';
import useStepCompletionColumns from '@cohort/merchants/apps/cohort-form/triggerIntegrations/common/useStepCompletionColumns';
import CohortFormPictureChoiceTriggerConfigComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/pictureChoice/ConfigComponent';
import CohortFormPictureChoiceTriggerIntegrationComponentPreview from '@cohort/merchants/apps/cohort-form/triggerIntegrations/pictureChoice/TriggerComponentPreview';
import type {CohortFormPictureChoiceTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/pictureChoice';
import {CohortFormPictureChoiceTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/pictureChoice';

export const CohortFormPictureChoiceTriggerIntegration: TriggerIntegration<CohortFormPictureChoiceTriggerStruct> =
  {
    spec: CohortFormPictureChoiceTriggerIntegrationSpec,
    configComponent: CohortFormPictureChoiceTriggerConfigComponent,
    stepCompletionTitleComponent: StepCompletionTitleComponent,
    stepCompletionContextComponent: StepCompletionContextComponent,
    useStepCompletionColumns: useStepCompletionColumns,
    stepCompletionsGridComponent: StepCompletionsGridComponent,
    getTitle: t => t('triggerIntegrations.picture-choice.title', {ns: 'app-cohort-form'}),
    preview: {
      CtaComponent: CohortFormTriggerIntegrationActionCtaComponentPreview,
      TriggerComponent: CohortFormPictureChoiceTriggerIntegrationComponentPreview,
    },
  };
