// i18nOwl-ignore [app-cohort.medias.article.title]
import MediaContentCohortArticle from '@cohort/components-xps/components/contents/apps/cohort/article/MediaContentCohortArticle';
import type {ContentMedia} from '@cohort/merchants/apps';
import CohortArticleMediaConfigComponent from '@cohort/merchants/apps/cohort/medias/article/ConfigComponent';
import CohortArticleVisualPreview from '@cohort/merchants/apps/cohort/medias/article/VisualPreview';
import type {CohortArticleMediaStruct} from '@cohort/shared/apps/cohort/medias/article';
import {CohortArticleMediaSpec} from '@cohort/shared/apps/cohort/medias/article';
import {TextAa} from '@phosphor-icons/react';

export const CohortMediaArticle: ContentMedia<CohortArticleMediaStruct> = {
  spec: CohortArticleMediaSpec,
  configComponent: CohortArticleMediaConfigComponent,
  getTitle: t => t('medias.article.title', {ns: 'app-cohort'}),
  mediaContent: MediaContentCohortArticle,
  icon: ({size, textColor}) => <TextAa size={size} className={textColor} />,
  visualPreview: CohortArticleVisualPreview,
};
