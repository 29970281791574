import type {DropdownPanelAlign} from '@cohort/merchants/components/buttons/Dropdown';
import Dropdown, {
  DropdownButton,
  DropdownEntry,
  DropdownPanel,
} from '@cohort/merchants/components/buttons/Dropdown';
import {getCohortCreateRoute} from '@cohort/merchants/lib/Pages';
import {Lightning, ListBullets, Plus} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

type CreateCohortDropdownProps = {
  align: DropdownPanelAlign;
};

const CreateCohortDropdown: React.FC<CreateCohortDropdownProps> = ({align}) => {
  const {t} = useTranslation('pages', {keyPrefix: 'users.cohorts.createCohortDropdown'});
  const navigate = useNavigate();

  const handleCreateCohort = (type: 'list' | 'segment') => () => {
    navigate(getCohortCreateRoute(type).path);
  };

  return (
    <Dropdown>
      <DropdownButton variant="primary">
        <Plus size={20} />
        {t('buttonCreateCohort')}
      </DropdownButton>
      <DropdownPanel align={align}>
        <DropdownEntry
          label={t('labelCreateList')}
          icon={<ListBullets className="h-5 w-5 text-slate-400" />}
          subtitle={t('labelCreateListSubtitle')}
          handleClick={handleCreateCohort('list')}
        />
        <DropdownEntry
          label={t('labelCreateSegment')}
          icon={<Lightning className="h-5 w-5 text-slate-400" />}
          subtitle={t('labelCreateSegmentSubtitle')}
          handleClick={handleCreateCohort('segment')}
        />
      </DropdownPanel>
    </Dropdown>
  );
};

export default CreateCohortDropdown;
