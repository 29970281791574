import FacebookLikePageTriggerIntegrationInstructionsComponent from '@cohort/components-xps/components/apps/facebook/triggers/likePage/InstructionsComponent';
import type {TriggerIntegration} from '@cohort/merchants/apps';
import useSocialStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useSocialStepCompletionColumns';
import FacebookLikePageUnverifiedTriggerActionCtaComponentPreview from '@cohort/merchants/apps/facebook/triggers/likePageUnverified/ActionCtaComponentPreview';
import FacebookLikePageUnverifiedTriggerConfigComponent from '@cohort/merchants/apps/facebook/triggers/likePageUnverified/ConfigComponent';
import FacebookLikePageTriggerUnverifiedStepCompletionTitleComponent from '@cohort/merchants/apps/facebook/triggers/likePageUnverified/StepCompletionTitleComponent';
import FacebookLikePageUnverifiedTriggerIntegrationComponentPreview from '@cohort/merchants/apps/facebook/triggers/likePageUnverified/TriggerComponentPreview';
import type {FacebookLikePageUnverifiedTriggerStruct} from '@cohort/shared/apps/facebook/triggers/likePageUnverified';
import {FacebookLikePageUnverifiedTriggerSpec} from '@cohort/shared/apps/facebook/triggers/likePageUnverified';

export const FacebookLikePageUnverifiedTriggerIntegration: TriggerIntegration<FacebookLikePageUnverifiedTriggerStruct> =
  {
    spec: FacebookLikePageUnverifiedTriggerSpec,
    configComponent: FacebookLikePageUnverifiedTriggerConfigComponent,
    stepCompletionTitleComponent: FacebookLikePageTriggerUnverifiedStepCompletionTitleComponent,
    useStepCompletionColumns: context =>
      useSocialStepCompletionColumns<FacebookLikePageUnverifiedTriggerStruct>({
        ...context,
        userNameKey: 'username',
      }),
    getTitle: t => t('triggers.like-page-unverified.title', {ns: 'app-facebook'}),
    preview: {
      InstructionsComponent: FacebookLikePageTriggerIntegrationInstructionsComponent,
      CtaComponent: FacebookLikePageUnverifiedTriggerActionCtaComponentPreview,
      TriggerComponent: FacebookLikePageUnverifiedTriggerIntegrationComponentPreview,
    },
  };
