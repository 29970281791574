// i18nOwl-ignore [app-cohort.perkIntegrations.link.title, app-cohort.perkIntegrations.link.description]
import type {PerkIntegration} from '@cohort/merchants/apps';
import CohortLinkPerkIntegrationConfigComponent from '@cohort/merchants/apps/cohort/perkIntegrations/link/ConfigComponent';
import CohortLinkPerkIntegrationsUsageViewComponent from '@cohort/merchants/apps/cohort/perkIntegrations/link/UsageViewComponent';
import type {CohortLinkPerkIntegrationStruct} from '@cohort/shared/apps/cohort/perks/link';
import {CohortLinkPerkIntegrationSpec} from '@cohort/shared/apps/cohort/perks/link';

export const CohortLinkPerkIntegration: PerkIntegration<CohortLinkPerkIntegrationStruct> = {
  spec: CohortLinkPerkIntegrationSpec,
  configComponent: CohortLinkPerkIntegrationConfigComponent,
  usageViewComponent: CohortLinkPerkIntegrationsUsageViewComponent,
  iconColor: 'text-blue-500',
  backgroundColor: 'bg-blue-100',
};
