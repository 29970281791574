import type {TriggerIntegration} from '@cohort/merchants/apps';
import useBeActiveStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useBeActiveStepCompletionColumns';
import StravaBeActiveTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/strava/triggerIntegratons/beActive/StepCompletionTitleComponent';
import type {StravaBeActiveTriggerStruct} from '@cohort/shared/apps/strava/triggers/beActive';
import {StravaBeActiveTriggerIntegrationSpec} from '@cohort/shared/apps/strava/triggers/beActive';

export const StravaBeActiveTriggerIntegration: TriggerIntegration<StravaBeActiveTriggerStruct> = {
  spec: StravaBeActiveTriggerIntegrationSpec,
  stepCompletionTitleComponent: StravaBeActiveTriggerIntegrationStepCompletionTitleComponent,
  useStepCompletionColumns: context =>
    useBeActiveStepCompletionColumns<StravaBeActiveTriggerStruct>({
      ...context,
      userNameKey: 'stravaUsername',
      metricKey: 'metric',
      thresholdValueKey: 'thresholdValue',
      cumulativeValueKey: 'cumulativeValue',
    }),
  getTitle: t => t('triggerIntegrations.be-active.title', {ns: 'app-strava'}),
};
