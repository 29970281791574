import TwitterFollowTriggerIntegrationInstructionsComponent from '@cohort/components-xps/components/apps/twitter/triggerIntegrations/follow/InstructionsComponent';
import type {TriggerIntegration} from '@cohort/merchants/apps';
import useSocialStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useSocialStepCompletionColumns';
import TwitterFollowTriggerActionCtaComponentPreview from '@cohort/merchants/apps/twitter/triggerIntegrations/follow/ActionCtaComponentPreview';
import TwitterFollowTriggerIntegrationConfigComponent from '@cohort/merchants/apps/twitter/triggerIntegrations/follow/ConfigComponent';
import TwitterFollowTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/twitter/triggerIntegrations/follow/StepCompletionTitleComponent';
import TwitterFollowTriggerIntegrationComponentPreview from '@cohort/merchants/apps/twitter/triggerIntegrations/follow/TriggerComponentPreview';
import type {TwitterFollowTriggerStruct} from '@cohort/shared/apps/twitter/triggers/follow';
import {TwitterFollowTriggerIntegrationSpec} from '@cohort/shared/apps/twitter/triggers/follow';

export const TwitterFollowTriggerIntegration: TriggerIntegration<TwitterFollowTriggerStruct> = {
  spec: TwitterFollowTriggerIntegrationSpec,
  configComponent: TwitterFollowTriggerIntegrationConfigComponent,
  stepCompletionTitleComponent: TwitterFollowTriggerIntegrationStepCompletionTitleComponent,
  useStepCompletionColumns: context =>
    useSocialStepCompletionColumns<TwitterFollowTriggerStruct>({
      ...context,
      userNameKey: 'memberHandle',
    }),
  getTitle: t => t('triggerIntegrations.follow.title', {ns: 'app-twitter'}),
  preview: {
    InstructionsComponent: TwitterFollowTriggerIntegrationInstructionsComponent,
    CtaComponent: TwitterFollowTriggerActionCtaComponentPreview,
    TriggerComponent: TwitterFollowTriggerIntegrationComponentPreview,
  },
};
