import type {TriggerIntegration} from '@cohort/merchants/apps';
import useBeActiveStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useBeActiveStepCompletionColumns';
import GoogleFitBeActiveTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/google-fit/triggerIntegratons/beActive/StepCompletionTitleComponent';
import type {GoogleFitBeActiveTriggerStruct} from '@cohort/shared/apps/google-fit/triggers/beActive';
import {GoogleFitBeActiveTriggerIntegrationSpec} from '@cohort/shared/apps/google-fit/triggers/beActive';

export const GoogleFitConnectBeActiveTriggerIntegration: TriggerIntegration<GoogleFitBeActiveTriggerStruct> =
  {
    spec: GoogleFitBeActiveTriggerIntegrationSpec,
    stepCompletionTitleComponent: GoogleFitBeActiveTriggerIntegrationStepCompletionTitleComponent,
    useStepCompletionColumns: context =>
      useBeActiveStepCompletionColumns<GoogleFitBeActiveTriggerStruct>({
        ...context,
        userNameKey: 'googleUsername',
        metricKey: 'metric',
        thresholdValueKey: 'thresholdValue',
        cumulativeValueKey: 'cumulativeValue',
      }),
    getTitle: t => t('triggerIntegrations.be-active.title', {ns: 'app-google-fit'}),
  };
