import type {PreviewCtaComponentProps} from '@cohort/merchants/apps';
import ActionCtaPreview from '@cohort/merchants/apps/common/ActionCtaPreview';
import type {TikTokKeywordInBioTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/keywordInBio';
import {useTranslation} from 'react-i18next';

const TiktoKeywordInBioTriggerActionCtaComponentPreview: React.FC<
  PreviewCtaComponentProps<TikTokKeywordInBioTriggerStruct>
> = ({localizationConfig}) => {
  const {t} = useTranslation('app-tiktok', {
    keyPrefix: 'triggers.keyword-in-bio',
    lng: localizationConfig.desiredLanguage,
  });
  return <ActionCtaPreview text={t('ctaTitle')} withRefresh />;
};

export default TiktoKeywordInBioTriggerActionCtaComponentPreview;
