import InstagramEmbedPost from '@cohort/components-xps/components/apps/instagram/InstagramEmbedPost';
import type {TriggerComponentPreviewProps} from '@cohort/merchants/apps';
import type {InstagramFollowTriggerStruct} from '@cohort/shared/apps/instagram/triggers/follow';

const InstagramFollowTriggerIntegrationComponentPreview: React.FC<
  TriggerComponentPreviewProps<InstagramFollowTriggerStruct>
> = ({config}) => {
  if (config.url.length < 1) {
    return null;
  }
  return <InstagramEmbedPost postUrl={config.url} />;
};

export default InstagramFollowTriggerIntegrationComponentPreview;
