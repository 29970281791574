// i18nOwl-ignore [forms.validation.errorDuplicateKeys, forms.validation.errorEmail, forms.validation.errorEmailsList, forms.validation.errorFormat, forms.validation.errorEndDateBeforeStartDate]
// i18nOwl-ignore [forms.validation.errorHexColor, forms.validation.errorNameNotAvailable, forms.validation.errorPositivePrice, forms.validation.errorRequired, forms.validation.errorNoOptionsSelected]
// i18nOwl-ignore [forms.validation.errorTooShort1, forms.validation.errorUrl, forms.validation.errorValue, forms.validation.errorPriceTooLow, forms.validation.errorInvalidDecimal]
// i18nOwl-ignore [forms.validation.errorInvalidDate, forms.validation.errorNamesList, forms.validation.errorNotAvailable, forms.validation.errorInvalidSlug]
// i18nOwl-ignore [forms.validation.errorLiquidSyntax, forms.validation.errorDefinedKeyValue, forms.validation.errorReferenceId]
import type {AssetADto} from '@cohort/admin-schemas/asset';
import {createThumbnail, getSignedUrl} from '@cohort/merchants/lib/api/Assets';
import {LanguageSchema} from '@cohort/shared/schema/common';
import type {AdminAssetKind} from '@cohort/shared/schema/common/assets';
import type {AssetMinDimensions, SizedAssetKind} from '@cohort/shared/utils/fileUploads';
import {isImageFile, isVideoFile} from '@cohort/shared/utils/mimeTypes';
import {isEmailValid} from '@cohort/shared/utils/validation';
import type {UseMutateAsyncFunction} from '@tanstack/react-query';
import axios from 'axios';
import type {
  Control,
  FieldValues,
  Path,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
} from 'react-hook-form';
import type {ZodError} from 'zod';

export type FormField<T extends FieldValues> = {
  name: Path<T>;
  register: UseFormRegister<T>;
  control: Control<T>;
  rules?: Parameters<UseFormRegister<T>>[1];
};

/**
 * Checks if the given URL is a thumbnail URL.
 */
export const isThumbnailUrl = (url: string): boolean => {
  return /\/thumbnails\/.*\.(png|jpe?g)$/u.test(url);
};

export function handleFormErrors<T extends FieldValues>(
  errors: ZodError,
  clearErrors: UseFormClearErrors<T>,
  setError: UseFormSetError<T>,
  prefix?: string
): void {
  prefix = prefix ?? '';
  let idx = 0;

  clearErrors();
  for (const error of errors.issues) {
    // Remove language part of the path since it's not the real field path
    const path = error.path
      .filter(path => LanguageSchema.safeParse(path).success === false)
      .join('.');
    const fullPath = `${prefix}${path}` as Path<T>;

    setError(
      fullPath,
      {
        message: error.message,
      },
      idx === 0 ? {shouldFocus: true} : undefined
    );
    idx++;
  }
}

export async function uploadAsset(
  file: File,
  assetKind: AdminAssetKind,
  merchantId: string
): Promise<AssetADto> {
  const signedUrlRes = await getSignedUrl(assetKind, merchantId, file.type);
  await axios.put(signedUrlRes.signedUrl, file, {
    headers: {
      'content-type': 'application/octet-stream',
      'x-goog-content-length-range': `0,${signedUrlRes.maxFileSize}`,
    },
  });

  let previewFileKey = null;
  if (isVideoFile(file.type)) {
    const createThumbnailRes = await createThumbnail(assetKind, merchantId, signedUrlRes.fileKey);
    previewFileKey = createThumbnailRes.thumbnailFileKey;
  } else if (isImageFile(file.type)) {
    previewFileKey = signedUrlRes.fileKey;
  }

  return {
    fileKey: signedUrlRes.fileKey,
    previewFileKey,
    mimeType: file.type,
  };
}

export const isSquaredVideo = async (file: File): Promise<boolean> => {
  return new Promise(resolve => {
    const url = URL.createObjectURL(file);
    const video = document.createElement('video');
    let aspectRatio;
    video.onloadedmetadata = () => {
      URL.revokeObjectURL(url);
      aspectRatio = video.videoWidth / video.videoHeight - 1;
      resolve(aspectRatio === 0);
    };
    video.src = url;
    video.load();
  });
};

export const isImageTooSmall = async (
  imageSrc: string,
  minDimensions: AssetMinDimensions[SizedAssetKind]
): Promise<boolean> => {
  return new Promise(resolve => {
    const img = new Image();
    img.onload = function () {
      resolve(img.width < minDimensions.width || img.height < minDimensions.height);
    };
    img.src = imageSrc;
  });
};

export const splitByCommaOrNewLine = (value: string): string[] => {
  if (value === '') {
    return [];
  }
  return value.split(/[\n,]/u);
};
export const parseCsvFile = ({
  lines,
}: {
  lines: string[][];
  separator?: string;
}): {headers: string[]; items: Record<string, string | undefined>[]} => {
  if (lines[0] === undefined) {
    return {headers: [], items: []};
  }

  const headers = lines[0];

  const items = lines.slice(1).reduce(
    (acc, values) => {
      const obj = headers.reduce(
        (obj, header, index) => {
          obj[header] = values[index];
          return obj;
        },
        {} as Record<string, string | undefined>
      );

      acc.push(obj);

      return acc;
    },
    [] as Record<string, string | undefined>[]
  );

  return {headers, items};
};

export const findCsvEmailHeader = (
  headers: string[],
  rows: Record<string, string | undefined>[]
): string | null => {
  const directMatch = headers.find(header => header.toLowerCase() === 'email');
  if (directMatch) {
    return directMatch;
  }

  for (const header of headers) {
    const emailCount = rows.slice(0, 10).reduce((count, row) => {
      const value = row[header];
      return value !== undefined && isEmailValid(value) ? count + 1 : count;
    }, 0);

    if (emailCount > 0) {
      return header;
    }
  }

  return null;
};

export const filterValidAndUniqueEmailsFromCsv = (
  headers: string[],
  rows: Array<Record<string, string | undefined>>
): Array<Record<string, string | undefined>> => {
  const emailHeader = findCsvEmailHeader(headers, rows);
  if (!emailHeader) {
    return [];
  }

  const seenEmails = new Set();
  return rows.filter(row => {
    const email = row[emailHeader];
    if (email && isEmailValid(email) && !seenEmails.has(email)) {
      seenEmails.add(email);
      return true;
    }
    return false;
  });
};

export type FormStepProps<Resource, PatchDto> = {
  isProcessing: boolean;
  setStepDirty?: (dirty: boolean) => void;
  setStepSaveBtn?: (btn: JSX.Element) => void;
  onStepValidated: () => void;
  updateMutation: UseMutateAsyncFunction<Resource | null, unknown, PatchDto, unknown>;
};

export type UploadMutation = UseMutateAsyncFunction<
  AssetADto | null,
  unknown,
  {file: File; assetKind: AdminAssetKind},
  unknown
>;

export type FormStepPropsWithUpload = {
  uploadMutation: UploadMutation;
};
