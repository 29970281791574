import type {TriggerIntegration} from '@cohort/merchants/apps';
import CohortDigitalAssetOwnedTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/cohort/triggerIntegrations/digitalAssetOwned/StepCompletionTitleComponent';
import type {CohortDigitalAssetOwnedTriggerStruct} from '@cohort/shared/apps/cohort/triggers/digitalAssetOwned';
import {CohortDigitalAssetOwnedTriggerIntegrationSpec} from '@cohort/shared/apps/cohort/triggers/digitalAssetOwned';

export const CohortDigitalAssetOwnedTriggerIntegration: TriggerIntegration<CohortDigitalAssetOwnedTriggerStruct> =
  {
    spec: CohortDigitalAssetOwnedTriggerIntegrationSpec,
    stepCompletionTitleComponent:
      CohortDigitalAssetOwnedTriggerIntegrationStepCompletionTitleComponent,
    getTitle: t => t('triggerIntegrations.digital-asset-owned.title', {ns: 'app-cohort'}),
  };
