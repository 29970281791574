import type {PreviewCtaComponentProps} from '@cohort/merchants/apps';
import ActionCtaPreview from '@cohort/merchants/apps/common/ActionCtaPreview';
import type {TikTokRepostTiktokTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/repostTiktok';
import {useTranslation} from 'react-i18next';

const TikTokRepostTiktokTriggerActionCtaComponentPreview: React.FC<
  PreviewCtaComponentProps<TikTokRepostTiktokTriggerStruct>
> = ({localizationConfig}) => {
  const {t} = useTranslation('app-tiktok', {
    keyPrefix: 'triggers.repost-tiktok',
    lng: localizationConfig.desiredLanguage,
  });
  return <ActionCtaPreview text={t('ctaTitle')} />;
};

export default TikTokRepostTiktokTriggerActionCtaComponentPreview;
