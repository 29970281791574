import TwitterEmbedTimeline from '@cohort/components-xps/components/apps/twitter/TwitterEmbedTimeline';
import type {TriggerComponentPreviewProps} from '@cohort/merchants/apps';
import type {TwitterFollowTriggerStruct} from '@cohort/shared/apps/twitter/triggers/follow';
import {cn} from '@cohort/shared-frontend/utils/classNames';

const TwitterFollowTriggerIntegrationComponentPreview: React.FC<
  TriggerComponentPreviewProps<TwitterFollowTriggerStruct>
> = ({config, isMobile}) => {
  if (config.profileUrl.length < 1) {
    return null;
  }
  return (
    <div className={cn('no-scrollbar', isMobile ? 'w-[350px]' : 'w-full')}>
      <TwitterEmbedTimeline profileUrl={config.profileUrl} darkMode />
    </div>
  );
};

export default TwitterFollowTriggerIntegrationComponentPreview;
