import type {App} from '@cohort/merchants/apps';
import {Apps} from '@cohort/merchants/apps';
import type {AppId} from '@cohort/shared/apps';
import type {AppSpec, AppStruct} from '@cohort/shared/apps/app';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {camelCase} from 'lodash';
import {useTranslation} from 'react-i18next';

export type Output<T extends AppStruct> = {
  appDisabled: (appId: AppId) => boolean;
  getApp: (appId?: T['Id'] | null) => App<T> | null;
  getAppTranslation: (appSpec: AppSpec<T>, key: string) => string;
  getAppDescription: (appSpec: AppSpec<T>) => string;
  getSyncTitle: (appSpec: AppSpec<T>) => string;
  getSyncDescription: (appSpec: AppSpec<T>) => string;
};

export const useApps = <T extends AppStruct>(): Output<T> => {
  const {t} = useTranslation();
  const flags = useFlags();

  const getApp = (appId?: T['Id'] | null): App<T> | null => {
    const app = Apps.find(app => app.spec.id === appId);

    return app ? (app as unknown as App<T>) : null;
  };

  const getAppTranslation = (appSpec: AppSpec<T>, key: string): string => {
    return t(key, {
      ns: `app-${appSpec.id}`,
    });
  };

  const getSyncTitle = (appSpec: AppSpec<T>): string => {
    return getAppTranslation(appSpec, 'sync.title');
  };

  const getSyncDescription = (appSpec: AppSpec<T>): string => {
    return getAppTranslation(appSpec, 'sync.description');
  };

  const getAppDescription = (appSpec: AppSpec<T>): string => {
    return getAppTranslation(appSpec, 'description');
  };

  const appDisabled = (appId: AppId): boolean => {
    const app = getApp(appId);
    if (app?.spec.deprecated) {
      return true;
    }
    const flag = flags[camelCase(`apps.${appId}`)];
    return flag === false;
  };

  return {
    appDisabled,
    getApp,
    getSyncTitle,
    getSyncDescription,
    getAppDescription,
    getAppTranslation,
  };
};
