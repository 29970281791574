import TiktokRepostTriggerIntegrationInstructionsComponent from '@cohort/components-xps/components/apps/tiktok/triggers/repostTiktok/InstructionsComponent';
import type {TriggerIntegration} from '@cohort/merchants/apps';
import useSocialStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useSocialStepCompletionColumns';
import TikTokRepostTiktokTriggerActionCtaComponentPreview from '@cohort/merchants/apps/tiktok/triggers/repostTikTok/ActionCtaComponentPreview';
import TikTokRepostTiktokTriggerConfigComponent from '@cohort/merchants/apps/tiktok/triggers/repostTikTok/ConfigComponent';
import TikTokRepostTiktokTriggerStepCompletionTitleComponent from '@cohort/merchants/apps/tiktok/triggers/repostTikTok/StepCompletionTitleComponent';
import TiktokRepostTiktokTriggerIntegrationComponentPreview from '@cohort/merchants/apps/tiktok/triggers/repostTikTok/TriggerComponentPreview';
import type {TikTokRepostTiktokTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/repostTiktok';
import {TikTokRepostTiktokTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/repostTiktok';

export const TikTokRepostTiktokTriggerIntegration: TriggerIntegration<TikTokRepostTiktokTriggerStruct> =
  {
    spec: TikTokRepostTiktokTriggerSpec,
    configComponent: TikTokRepostTiktokTriggerConfigComponent,
    stepCompletionTitleComponent: TikTokRepostTiktokTriggerStepCompletionTitleComponent,
    useStepCompletionColumns: context =>
      useSocialStepCompletionColumns<TikTokRepostTiktokTriggerStruct>({
        ...context,
        userNameKey: 'username',
      }),
    getTitle: t => t('triggers.repost-tiktok.title', {ns: 'app-tiktok'}),
    preview: {
      InstructionsComponent: TiktokRepostTriggerIntegrationInstructionsComponent,
      CtaComponent: TikTokRepostTiktokTriggerActionCtaComponentPreview,
      TriggerComponent: TiktokRepostTiktokTriggerIntegrationComponentPreview,
    },
  };
