import type {TriggerIntegration} from '@cohort/merchants/apps';
import useCompleteActivityStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useCompleteActivityStepCompletionColumns';
import GoogleFitCompleteActivityTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/google-fit/triggerIntegratons/completeActivity/StepCompletionTitleComponent';
import type {GoogleFitCompleteActivityTriggerStruct} from '@cohort/shared/apps/google-fit/triggers/completeActivity';
import {GoogleFitCompleteActivityTriggerIntegrationSpec} from '@cohort/shared/apps/google-fit/triggers/completeActivity';

export const GoogleFitCompleteActivityTriggerIntegration: TriggerIntegration<GoogleFitCompleteActivityTriggerStruct> =
  {
    spec: GoogleFitCompleteActivityTriggerIntegrationSpec,
    stepCompletionTitleComponent:
      GoogleFitCompleteActivityTriggerIntegrationStepCompletionTitleComponent,
    useStepCompletionColumns: context =>
      useCompleteActivityStepCompletionColumns<GoogleFitCompleteActivityTriggerStruct>({
        ...context,
        userNameKey: 'googleUsername',
        activityTypeKey: 'activityType',
        durationSecondsKey: 'durationSeconds',
        distanceMetersKey: 'distanceMeters',
      }),
    getTitle: t => t('triggerIntegrations.complete-activity.title', {ns: 'app-google-fit'}),
  };
