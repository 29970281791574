import type {PreviewCtaComponentProps} from '@cohort/merchants/apps';
import ActionCtaPreview from '@cohort/merchants/apps/common/ActionCtaPreview';
import type {TwitterFollowTriggerStruct} from '@cohort/shared/apps/twitter/triggers/follow';
import {useTranslation} from 'react-i18next';

const TwitterFollowTriggerActionCtaComponentPreview: React.FC<
  PreviewCtaComponentProps<TwitterFollowTriggerStruct>
> = ({localizationConfig}) => {
  const {t} = useTranslation('app-twitter', {
    keyPrefix: 'triggerIntegrations.follow',
    lng: localizationConfig.desiredLanguage,
  });
  return <ActionCtaPreview text={t('ctaTitle')} withRefresh />;
};

export default TwitterFollowTriggerActionCtaComponentPreview;
